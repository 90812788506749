import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAllRoles } from "../thunks";

interface RoleTableState {
  data: any[];
  loading: boolean;
  error: string | null;
}

const initialState: RoleTableState = {
  data: [],
  loading: false,
  error: null,
};

export const RoleTableSlice = createSlice({
  name: "roleTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllRoles.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllRoles.rejected, (state, action) => {
        state.loading = false;
        console.error("Fetch failed:", action.error);
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export type RoleTableSliceType = ReturnType<typeof RoleTableSlice.reducer>;
export default RoleTableSlice.reducer;
