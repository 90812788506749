import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchTopicsByRoleId } from "../thunks";

interface Pagination {
    page: number;
    page_size: number;
    total_count: number;
}

interface FetchTopicsPayload {
    data: any[];
    pagination: Pagination;
}

interface UpdatePaginationPayload {
    currentPage: number;
    pageSize: number;
}

interface TopicTableState {
    data: any[];
    loading: boolean;
    error: string | null;
    currentPage: number;
    pageSize: number;
    total: number;
    indexCurrentPage: number;
    indexpageSize: number;
}

const initialState: TopicTableState = {
    data: [],
    loading: false,
    error: null,
    currentPage: 1,
    pageSize: 10,
    total: 0,
    indexCurrentPage: 1,
    indexpageSize: 50,
};

export const TopicTableSlice = createSlice({
    name: "topicTable",
    initialState,
    reducers: {
        updatePagination: (
            state,
            action: PayloadAction<UpdatePaginationPayload>
        ) => {
            state.indexCurrentPage = action.payload.currentPage;
            state.indexpageSize = action.payload.pageSize;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTopicsByRoleId.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                fetchTopicsByRoleId.fulfilled,
                (state, action: PayloadAction<FetchTopicsPayload>) => {
                    state.loading = false;
                    state.data = action.payload.data.map((topic) => ({
                        ...topic,
                        key: topic.topic_id,
                    }));
                    state.currentPage = action.payload.pagination.page;
                    state.pageSize = action.payload.pagination.page_size;
                    state.total = action.payload.pagination.total_count;
                    state.error = null;
                }
            )
            .addCase(fetchTopicsByRoleId.rejected, (state, action) => {
                state.loading = false;
                console.error("Fetch failed:", action.error);
                state.error = action.error.message || "Something went wrong";
            });
    },
});

export const { updatePagination } = TopicTableSlice.actions;

export type TopicTableSliceType = ReturnType<typeof TopicTableSlice.reducer>;
export default TopicTableSlice.reducer;
