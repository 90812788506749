import { Card, Table } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchRoleLabelIssueRreport } from "../redux/thunks";

interface DataType {
    id: number;
    role_name: string;
    theme_name: string;
    label_counts: Record<string, number>;
}

const ReportPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const data = useSelector(
        (state: RootState) => state.RoleLabelIssueReportSlice.data as DataType[]
    );

    const loading = useSelector(
        (state: RootState) => state.RoleLabelIssueReportSlice.loading
    );

    useEffect(() => {
        dispatch(fetchRoleLabelIssueRreport());
    }, [dispatch]);

    // 处理数据，将 label_counts 转换成可展示的格式，并按 theme_name 排序
    const processedData = data;
    // .map((item) => ({
    //     ...item,
    // }))
    // .sort((a, b) => a.theme_name.localeCompare(b.theme_name));

    // 定义表格列
    const columns = [
        {
            title: "序号",
            dataIndex: "index",
            key: "index",
            render: (_: any, __: any, index: number) => index + 1,
        },
        {
            title: "角色编号",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "主题名称",
            dataIndex: "theme_name",
            key: "theme_name",
        },
        {
            title: "角色名称",
            dataIndex: "role_name",
            key: "role_name",
        },
        {
            title: "标签统计",
            dataIndex: "label_counts",
            key: "label_counts",
            render: (label_counts: string) => {
                const parsedLabelCounts = JSON.parse(label_counts) as Record<
                    string,
                    number
                >;
                return (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {Object.entries(parsedLabelCounts).map(
                            ([key, value]) => (
                                <div
                                    key={key}
                                    style={{
                                        color:
                                            !key.includes("认知") &&
                                            !key.includes("闲聊") &&
                                            !key.includes("共情") &&
                                            !key.includes("心理") &&
                                            !key.includes("恋爱")
                                                ? "red"
                                                : "black",
                                        marginRight: "10px",
                                    }}
                                >
                                    {key}: {value}
                                </div>
                            )
                        )}
                    </div>
                );
            },
        },
        {
            title: "上次更新时间",
            dataIndex: "latest_updated_time",
            key: "latest_updated_time",
        },
    ];

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <h1>全角色标签报表</h1>
            <Card
                size="small"
                style={{ height: "75vh", overflowY: "scroll", width: "100%" }}
            >
                <Table
                    columns={columns}
                    dataSource={processedData}
                    loading={loading}
                    rowKey="id"
                    bordered
                    size="small"
                    pagination={false}
                    style={{ width: "100%" }}
                />
            </Card>
        </div>
    );
};

export default ReportPage;
