import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux"; // 引入 Provider
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";
import DemoTableTransfer from "./components/demo-table-transfer";
import "./index.css";
import AddLabelPage from "./pages/add-label-page";
import AutoChatPage from "./pages/auto-chat-page";
import CaiBatchPage from "./pages/cai-batch-page";
import CorpusEditor from "./pages/corpus-editor";
import EduReportPage from "./pages/edu-report-page";
import EduReport2Page from "./pages/edu-report2-page";
import ExportRolePage from "./pages/export-role-page";
import ExportTopicPage from "./pages/export-topic-page";
import FramePage from "./pages/frame-page";
import GptPage from "./pages/gpt-page";
import LabelPage from "./pages/label-page";
import LoginPage from "./pages/login-page";
import PromptPage from "./pages/prompt-page";
import PromptTestPage from "./pages/prompt-test-page";
import ReportPage from "./pages/report-page";
import ResourcePage from "./pages/resource-page";
import RoleListOfTheme from "./pages/role_list_of_theme";
import TopicListOfRole from "./pages/topic_list_of_role";
import TTSV1Page from "./pages/tts-v1-page";
import TTSV2Page from "./pages/tts-v2-page";
import UserAssetPage from "./pages/user-asset-page";
import UserManagementPage from "./pages/user-management-page";
import PrivateRoute from "./private-route";
import { store } from "./redux/store"; // 引入 store

const App = () => {
    useEffect(() => {
        document.title = "语料平台";
        const link = document.createElement("link");
        link.rel = "icon";
        link.href = "/circular_nlp_logo_favicon.ico";
        document.head.appendChild(link);
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/" element={<FramePage />}>
                            <Route
                                index
                                element={<Navigate to="resource" replace />}
                            />
                            <Route path="resource" element={<ResourcePage />}>
                                <Route
                                    path="report"
                                    element={<ReportPage />}
                                ></Route>
                                <Route
                                    path="role_list_of_theme/:theme_id"
                                    element={<RoleListOfTheme />}
                                />
                                <Route
                                    path="topic_list_of_role/:theme_id/:role_id"
                                    element={<TopicListOfRole />}
                                />
                            </Route>
                            <Route
                                path="editor/themes/:themeId/roles/:roleId/topics/:topicId"
                                element={<CorpusEditor />}
                            />
                            <Route
                                path="prompt-page"
                                element={<PromptPage />}
                            />
                            <Route
                                path="autochat-page"
                                element={<AutoChatPage />}
                            />
                            <Route
                                path="batchchat-page"
                                element={<CaiBatchPage />}
                            />
                            <Route path="label-page" element={<LabelPage />} />
                            <Route
                                path="add-label-page"
                                element={<AddLabelPage />}
                            />
                            <Route
                                path="export-topic-page"
                                element={<ExportTopicPage />}
                            />
                            <Route
                                path="export-role-page"
                                element={<ExportRolePage />}
                            />
                            <Route
                                path="user-management-page"
                                element={<UserManagementPage />}
                            />
                            <Route
                                path="user-asset-page"
                                element={<UserAssetPage />}
                            />
                            <Route
                                path="demo-page"
                                element={<DemoTableTransfer />}
                            />
                            <Route
                                path="prompt-test-page"
                                element={<PromptTestPage />}
                            />
                            <Route
                                    path="edu-report-page"
                                    element={<EduReportPage />}
                                ></Route>
                                                        <Route
                                    path="edu-report2-page"
                                    element={<EduReport2Page />}
                                ></Route>
                            <Route
                                path="gpt-page"
                                element={<GptPage />}
                            />
                            <Route
                                path="tts-v1-page"
                                element={<TTSV1Page />}
                            />
                            <Route
                                path="tts-v2-page"
                                element={<TTSV2Page />}
                            />
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </Provider>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(<App />);
