import type {
    GetProp,
    TableColumnsType,
    TableProps,
    TransferProps,
} from "antd";
import { Flex, Switch, Table, Tag, Transfer } from "antd";
import React, { useState } from "react";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchTopicsByRoleId } from "../redux/thunks";
import { statusEnum, StatusKey } from "./topics-of-role-table";

type TransferItem = GetProp<TransferProps, "dataSource">[number];
type TableRowSelection<T extends object> = TableProps<T>["rowSelection"];

interface DataType {
    key: string;
    theme_id: string;
    role_id: string;
    topic_id: string;
    topic_name: string;
    processing_status: string;
}

interface TableTransferProps extends TransferProps<TransferItem> {
    dataSource: DataType[];
    leftColumns: TableColumnsType<DataType>;
    rightColumns: TableColumnsType<DataType>;
}

// Customize Table Transfer
const TableTransfer: React.FC<TableTransferProps> = (props) => {
    const { leftColumns, rightColumns, ...restProps } = props;
    return (
        <Transfer style={{ width: "100%" }} {...restProps}>
            {({
                direction,
                filteredItems,
                onItemSelect,
                onItemSelectAll,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns =
                    direction === "left" ? leftColumns : rightColumns;
                const rowSelection: TableRowSelection<TransferItem> = {
                    getCheckboxProps: () => ({ disabled: listDisabled }),
                    onChange(selectedRowKeys) {
                        onItemSelectAll(selectedRowKeys, "replace");
                    },
                    selectedRowKeys: listSelectedKeys,
                    selections: [
                        Table.SELECTION_ALL,
                        Table.SELECTION_INVERT,
                        Table.SELECTION_NONE,
                    ],
                };

                return (
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        size="small"
                        style={{
                            pointerEvents: listDisabled ? "none" : undefined,
                        }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            onClick: () => {
                                if (itemDisabled || listDisabled) {
                                    return;
                                }
                                onItemSelect(
                                    key,
                                    !listSelectedKeys.includes(key)
                                );
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );
};

const columns: TableColumnsType<DataType> = [
    {
        key: "topic_id",
        dataIndex: "topic_id",
        title: "话题编号",
        onCell: () => ({
            style: { display: "none" },
        }),
        onHeaderCell: () => ({
            style: { display: "none" },
        }),
    },
    {
        dataIndex: "topic_name",
        title: "话题名称",
    },
    {
        title: "Status",
        dataIndex: "processing_status",
        key: "processing_status",
        render: (status: StatusKey) => {
            const { tag, color } = statusEnum[status] || {};
            return tag ? <Tag color={color}>{tag}</Tag> : null;
        },
        filters: Object.keys(statusEnum).map((key) => ({
            text: statusEnum[key as StatusKey].tag,
            value: key,
        })),
        onFilter: (
            value: string | number | boolean | bigint,
            record: DataType
        ) => {
            return value
                .toString()
                .includes(record.processing_status.toString());
        },
    },
];

const filterOption = (input: string, item: DataType) =>
    item.topic_name?.includes(input) || item.processing_status?.includes(input);

const DemoTableTransfer: React.FC = () => {
    const dispatch = useAppDispatch();
    const topics = useSelector((state: RootState) => state.topicTable.data);
    const [targetKeys, setTargetKeys] = useState<TransferProps["targetKeys"]>(
        []
    );

    useEffect(() => {
        dispatch(
            fetchTopicsByRoleId({
                themeId: "1",
                roleId: "9",
                page: 1,
                pageSize: 1000,
            })
        );
    }, [dispatch]);

    const [disabled, setDisabled] = useState(false);

    const onChange: TableTransferProps["onChange"] = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const toggleDisabled = (checked: boolean) => {
        setDisabled(checked);
    };

    return (
        <Flex align="start" gap="middle" vertical>
            <TableTransfer
                dataSource={topics}
                targetKeys={targetKeys}
                disabled={disabled}
                showSearch
                showSelectAll={false}
                onChange={onChange}
                filterOption={filterOption}
                leftColumns={columns}
                rightColumns={columns}
            />
            <Switch
                unCheckedChildren="disabled"
                checkedChildren="disabled"
                checked={disabled}
                onChange={toggleDisabled}
            />
        </Flex>
    );
};

export default DemoTableTransfer;
