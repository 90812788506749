import {
    Card,
    Form,
    Input,
    Modal,
    Progress,
    Radio,
    Select,
    Slider,
    Tabs,
    TransferProps,
    Button,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { slimTopic } from "../pages/topic_list_of_role";
import { cleanTask, peekTask } from "../redux/reducers/generate-task-reducer";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchTopicsByRoleId } from "../redux/thunks";
import {
    fetchAllAISources,
    peekGenerateTask,
    stopGenerateTask,
} from "../services/api";
import TopicTableTransfer, { TableTransferProps } from "./topic-table-transfer";

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const DEFAULT_MODEL = "GPT-4o";
const DEFAULT_TEMPERATURE = 0.7;

interface GenerateCorpusModalProps {
    themeId: string;
    roleId: string;
    visible: boolean;
    onCancel: () => void;
    onOk: (values: {
        topics: string[];
        temperature: number;
        model_id: string;
        number_of_rounds: number;
        sys_prompt: string;
        forward_prompt: string;
    }) => void;
    topics: {
        key: string;
        theme_id: string;
        role_id: string;
        topic_id: string;
        topic_name: string;
        processing_status: string;
    }[];
    setTopics: React.Dispatch<React.SetStateAction<slimTopic[] | null>>;
}

interface Model {
    id: string;
    name: string;
}

const GenerateCorpusModal: React.FC<GenerateCorpusModalProps> = ({
    themeId,
    roleId,
    visible,
    onCancel,
    onOk,
    topics,
    setTopics,
}) => {
    const { taskId, status, progress, errorMessage } = useSelector(
        (state: RootState) => state.generateTaskState
    );
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const [models, setModels] = useState<Model[]>([]);
    const [loading, setLoading] = useState(true);

    const [targetKeys, setTargetKeys] = useState<TransferProps["targetKeys"]>(
        []
    );
    const HandleTransferChange: TableTransferProps["onChange"] = (
        nextTargetKeys
    ) => {
        setTargetKeys(nextTargetKeys);
    };

    const [isGenerating, setIsGenerating] = useState(false);

    const [prompt, setPrompt] = useState("");
    const [forwardPrompt, setForwardPrompt] = useState("");

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        const peek = async () => {
            if (status === "in_progress" && !interval) {
                interval = setInterval(async () => {
                    if (taskId) {
                        const res = await peekGenerateTask(taskId);
                        dispatch(peekTask(res.data));
                    }
                }, 2000);
            }
        };

        peek();

        if (status === "success" || status === "failure") {
            setIsGenerating(false);
            if (interval) {
                clearInterval(interval);
            }
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [dispatch, status, taskId]);

    const handleCancel = async () => {
        dispatch(cleanTask());
        if (taskId) {
            stopGenerateTask(taskId);
        }
        onCancel();
        setIsGenerating(false);
        dispatch(
            fetchTopicsByRoleId({
                themeId: themeId,
                roleId: roleId,
                page: 1,
                pageSize: 10,
            })
        );
    };

    const handleOk = () => {
        form.validateFields().then((values) => {
            setIsGenerating(true);
            onOk({
                topics: targetKeys as string[],
                temperature: values.temperature,
                model_id: values.model,
                number_of_rounds: values.number_of_rounds,
                sys_prompt: values.prompt,
                forward_prompt: values.forward_prompt,
            });
        });
    };

    const handleStopGenerating = async () => {
        if (taskId) {
            await stopGenerateTask(taskId);
            dispatch(cleanTask());
            setIsGenerating(false);
        }
    };

    useEffect(() => {
        const fetchModels = async () => {
            try {
                const response = await fetchAllAISources();
                const modelData = response.data.map((source: Model) => ({
                    id: source.id,
                    name: source.name,
                }));
                setModels(modelData);
            } catch (error) {
                console.error("Failed to fetch models:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchModels();
    }, []);

    useEffect(() => {
        if (visible) {
            form.resetFields();
            setTargetKeys([]);
            form.setFieldsValue({
                temperature: DEFAULT_TEMPERATURE,
                model: DEFAULT_MODEL,
            });
        }
    }, [visible, form]);

    return visible ? (
        <Modal
            open={visible}
            title="生成语料"
            onCancel={handleCancel}
            onOk={handleOk}
            okText="生成"
            cancelText="关闭"
            width={"80%"}
            centered
            okButtonProps={{ disabled: isGenerating }}
            closable={true}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    关闭
                </Button>,
                <Button
                    key="stop"
                    onClick={handleStopGenerating}
                    disabled={!isGenerating}
                >
                    停止生成
                </Button>,
                <Button
                    key="ok"
                    type="primary"
                    onClick={handleOk}
                    disabled={isGenerating}
                >
                    生成
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={onOk}>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "10px",
                        alignItems: "center",
                    }}
                >
                    <Card style={{ height: "100%" }}>
                        <Form.Item
                            name="topics"
                            label="选择话题"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择要导出的话题",
                                },
                            ]}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <TopicTableTransfer
                                    dataSource={topics}
                                    targetKeys={targetKeys}
                                    onChange={HandleTransferChange}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <Form.Item
                                    name="model"
                                    label="选择模型"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择模型",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="选择模型"
                                        style={{ width: 180 }}
                                        loading={loading}
                                    >
                                        {models.map((model) => (
                                            <Option
                                                key={model.id}
                                                value={model.id}
                                            >
                                                {model.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Temprature"
                                    name="temperature"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择temperature",
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={0.1}>0.1</Radio>
                                        <Radio value={0.3}>0.3</Radio>
                                        <Radio value={0.5}>0.5</Radio>
                                        <Radio value={0.7}>0.7</Radio>
                                        <Radio value={1.0}>1.0</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Form.Item>
                    </Card>
                    <Card style={{ height: "100%" }}>
                        <Form.Item
                            label="期望对话轮数"
                            name="number_of_rounds"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择对话轮数",
                                },
                            ]}
                        >
                            <Form.Item
                                name="number_of_rounds"
                                noStyle
                                initialValue={20}
                            >
                                <Slider
                                    min={10}
                                    max={50}
                                    step={5}
                                    marks={{
                                        10: "10",
                                        20: "20",
                                        30: "30",
                                        40: "40",
                                        50: "50",
                                    }}
                                    style={{ width: 150 }}
                                />
                            </Form.Item>
                        </Form.Item>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="提示词" key="1">
                                <Form.Item
                                    name="prompt"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入Prompt",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={12}
                                        placeholder="输入Prompt"
                                        onChange={(e) =>
                                            setPrompt(e.target.value)
                                        }
                                        style={{ height: 200 }}
                                    />
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="Markdown" key="2">
                                <div style={{ height: 200, overflowY: "auto" }}>
                                    <ReactMarkdown>{prompt}</ReactMarkdown>
                                </div>
                            </TabPane>
                        </Tabs>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="续写提示词" key="1">
                                <Form.Item
                                    name="forward_prompt"
                                    rules={[
                                        {
                                            required: false,
                                            message: "请输入续写Prompt",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={12}
                                        placeholder="请输入续写Prompt"
                                        onChange={(e) =>
                                            setForwardPrompt(e.target.value)
                                        }
                                        style={{ height: 200 }}
                                    />
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="Markdown" key="2">
                                <div style={{ height: 200, overflowY: "auto" }}>
                                    <ReactMarkdown>
                                        {forwardPrompt}
                                    </ReactMarkdown>
                                </div>
                            </TabPane>
                        </Tabs>
                        {taskId && (
                            <Progress
                                percent={parseFloat(progress.toFixed(2))}
                            />
                        )}
                    </Card>
                </div>
            </Form>
        </Modal>
    ) : null;
};

export default GenerateCorpusModal;
