import { Button, Card, Input, Select, Space, Table } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import getColumns from "../components/edu-report-column";
import ProvinceSelector from "../components/selects/province-selector";
import SubjectSelector from "../components/selects/subject-selector";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchEduReport } from "../redux/thunks";
import './edu-report-page.css'; // Import the CSS file


const { Option } = Select;

const EduReportPage: React.FC = () => {
    const [year, setYear] = useState('2024'); // 默认值可以设置为某个年份
    const [province, setProvince] = useState('');
    const [score, setScore] = useState('');
    const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
    const handleSubjectChange = (newSelectedSubjects: string[]) => {
        setSelectedSubjects(newSelectedSubjects); // 更新父组件中的选中科目
      };
    const dispatch = useAppDispatch();

    const data = useSelector(
        (state: RootState) => state.EduReportSlice.data as DataType[]
    );

    const loading = useSelector(
        (state: RootState) => state.EduReportSlice.loading
    );

    // 处理查询的函数
    const handleSearch = () => {
        dispatch(fetchEduReport({ year, province, score, subjects:selectedSubjects }))
    };

    const handleExport2 = () => {
        const ws = XLSX.utils.aoa_to_sheet([
            ['Name', 'Age', 'City'],
            ['Alice', 25, 'New York'],
            ['Bob', 30, 'San Francisco']
        ]);
    
        // 设置单元格样式（居中对齐和边框）
        const headerStyle = {
            alignment: { horizontal: "center", vertical: "center" },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };
    
        const dataStyle = {
            alignment: { horizontal: "center", vertical: "center" },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };
    
        // 设置样式到每个单元格
        const range = XLSX.utils.decode_range(ws['!ref'] || 'A1'); // 获取范围
        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
    
                // 为标题行设置样式
                if (row === 0) {
                    ws[cellAddress].s = headerStyle;
                } else {
                    ws[cellAddress].s = dataStyle;
                }
            }
        }
    
        // 创建工作簿并导出
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'styled_table.xlsx',{cellStyles: true});
    }

    const handleExport = () => {
        // 获取列定义
        const columns = getColumns(Number(year));
        
        // 构建 Excel 表头
        const headerRow1: string[] = [];
        const headerRow2: string[] = [];
        
        columns.forEach(colGroup => {
            headerRow1.push(colGroup.title);
            for (let i = 0; i < colGroup.children.length - 1; i++) {
                headerRow1.push('');  // 填充空单元格
            }
            
            colGroup.children.forEach(col => {
                headerRow2.push(col.title); // 二级表头
            });
        });
        
        const titleRow = [`${year}志愿推荐表 - ${province} - [${selectedSubjects.join('/')}] - 分数: ${score}`, ...new Array(headerRow2.length - 1).fill('')];

        const headers = [titleRow, headerRow1, headerRow2];
        
        const dataRows = data.map((item, index) => {
            const row: string[] = [];
            row.push(`${index + 1}`); // 序号列
            columns.forEach(colGroup => {
                colGroup.children.forEach(col => {
                    if (col.dataIndex !== 'index') {
                        // 检查该列是否定义了 render 函数
                        const cellValue = item[col.dataIndex as keyof DataType];
                        let valueToRender = cellValue;

                        // 如果列有 render 函数，则调用该函数
                        if (col.render) {
                            valueToRender = col.render(cellValue, item, index);
                        }

                        // 将渲染后的值添加到当前行
                        row.push(String(valueToRender || ''));                    }
                });
            });
            return row;
        });
        
        const ws = XLSX.utils.aoa_to_sheet([...headers, ...dataRows]);
        
        // 合并单元格
        const mergeInfo: { [key: number]: number } = {};
        for (let i = 0; i < headers[1].length; i++) {
            if (headers[1][i] !== '') {
                let childrenCount = 0;
                for (let j = i + 1; j < headers[1].length; j++) {
                    if (headers[1][j] === '') {
                        childrenCount++;
                    } else {
                        break;
                    }
                }
                mergeInfo[i] = childrenCount;
            }
        }
        
        ws['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: headerRow2.length - 1 } }
        ];
        
        Object.entries(mergeInfo).forEach(([index, count]) => {
            ws['!merges']?.push({
                s: { r: 1, c: parseInt(index) },
                e: { r: 1, c: parseInt(index) + count }
            });
        });
        
        // 创建默认样式
        const defaultStyle = {
            border: {
                top: { style: 'thick' },
                bottom: { style: 'thick' },
                left: { style: 'thick' },
                right: { style: 'thick' }
            }
        };
    
        // 标题行和表头行的样式
        const headerStyle = {
            ...defaultStyle,
            alignment: { 
                horizontal: "center", 
                vertical: "center" 
            }
        };
    
        // 设置所有单元格的边框和对齐方式
        const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
        for (let row = 0; row <= range.e.r; row++) {
            for (let col = 0; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                
                // 确保单元格存在
                ws[cellAddress] = ws[cellAddress] || { v: '' };
                
                // 设置样式
                if (row <= 1) {
                    // 前两行使用标题样式
                    ws[cellAddress].s = headerStyle;
                } else {
                    // 其他行使用默认样式
                    ws[cellAddress].s = defaultStyle;
                }
            }
        }
        
        // 创建工作簿并将工作表添加到工作簿
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        
        // 导出文件
        XLSX.writeFile(wb, `${year}志愿推荐表-${province}-[${selectedSubjects.join('/')}]-分数${score}.xlsx`, { cellStyles: true });
    };
    
    
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Card>
                <div style={{ display: "grid", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>年份选择：</span>
                        <Select
                            placeholder="选择年份"
                            defaultValue="2024"
                            onChange={value => setYear(value)}
                            size="small"
                            style={{ width: 120, marginRight: 8 }}
                        >
                            <Option value="2024">2024</Option>
                            <Option value="2023">2023</Option>
                            <Option value="2022">2022</Option>
                        </Select>
                    </div>

                    <ProvinceSelector value={province} onChange={setProvince} />
                    <SubjectSelector value={selectedSubjects} onChange={handleSubjectChange} />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>分数选择：</span>
                        <Input
                            placeholder="分数"
                            size="small"
                            style={{ width: 120, marginRight: 8 }}
                            onChange={e => setScore(e.target.value)}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Button type="primary" onClick={handleSearch} loading={loading}>
                            查询
                        </Button>
                        <Button type="default" onClick={handleExport} style={{ marginLeft: 8 }}>
                            导出Excel
                        </Button>
                    </div>
                </div>
            </Card>
            <Space style={{ marginBottom: 20 }}></Space>
            <Card style={{ height: '100%' }}>
                <h2 style={{ textAlign: 'center' }}>
                {year}志愿推荐表 - {province} - [{selectedSubjects.join('/')}] - 分数: {score}
                </h2>
                <Table 
                    className="no-wrap-table" 
                    columns={getColumns(Number(year))} 
                    dataSource={data} 
                    size="small" 
                    loading={loading} 
                    bordered // Add this line to add borders to the table
                    style={{ height: '60vh' }}
                />
            </Card>
        </div>
    );
};

interface DataType {
    college_code: string;
    college_location: string;
    college_name: string;
    college_type: string;
    duration: number;
    edu_level: string;
    profession_evaluation_level: string;
    enter_num_current: number | null;
    enter_num_last_1_year: number | null;
    enter_num_last_2_year: number | null;
    max_score_current: number | null;
    max_score_last_1_year: number | null;
    max_score_last_2_year: number | null;
    min_rank_current: number | null;
    min_rank_last_1_year: number | null;
    min_rank_last_2_year: number | null;
    min_score_current: number | null;
    min_score_last_1_year: number | null;
    min_score_last_2_year: number | null;
    nature_type: string;
    plan_num: number;
    profession_enroll_code: string;
    profession_name: string;
    profession_full_name: string;
    subject_requirement: string[];
    tuition: string;
}

export default EduReportPage;
