import { Button, Card, Divider, message, Space, Table } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { ColumnsType } from "antd/es/table";
import { RowSelectMethod } from "antd/es/table/interface";
import { Key, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelSelector from "../components/selects/label-selector";
import RoleSelector from "../components/selects/role-selector";
import { AppDispatch, RootState } from "../redux/store";
import { fetchTopicsForLabelByRoleId } from "../redux/thunks";

import {
    addTopicLabel,
    deleteTopicLabel,
    getLabelTypes,
} from "../services/api";

const AddLabelPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const data = useSelector(
        (state: RootState) => state.topicTableForLabel.data as any[]
    );
    const loading = useSelector(
        (state: RootState) => state.topicTableForLabel.loading
    );
    const [themeId, setThemeId] = useState<string>("");
    const [roleId, setRoleId] = useState<string>("");
    const [labelTypes, setLabelTypes] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // 每页显示的条数
    const [filteredData, setFilteredData] = useState(data); // 使用 filteredData 作为筛选后的数据

    useEffect(() => {
        if (!themeId && !roleId) {
            return;
        }
        dispatch(
            fetchTopicsForLabelByRoleId({
                themeId,
                roleId,
            })
        );
    }, [dispatch, themeId, roleId]);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        const fetchLabelTypes = async () => {
            const res = await getLabelTypes();
            const labelTypes = res.data.reduce(
                (acc: string[], item: { label_type: string }) => {
                    if (!acc.includes(item.label_type)) {
                        acc.push(item.label_type);
                    }
                    return acc;
                },
                []
            );
            setLabelTypes(labelTypes);
        };
        fetchLabelTypes();
    }, []);

    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

    const handleFilter = (
        filters: Record<string, (string | number | boolean)[] | null>
    ) => {
        let filtered = [...data];
        Object.keys(filters).forEach((filterKey) => {
            const selectedValues = filters[filterKey];
            if (selectedValues) {
                filtered = filtered.filter((item) =>
                    selectedValues.some((value) =>
                        item[filterKey]?.includes(value)
                    )
                );
            }
        });
        setFilteredData(filtered);
    };

    const baseColumns: ColumnsType<any> = [
        {
            title: "主题名",
            dataIndex: "theme_name",
            key: "themeName",
        },
        {
            title: "角色名",
            dataIndex: "role_name",
            key: "roleName",
        },
        {
            title: "话题名",
            dataIndex: "topic_name",
            key: "topicName",
        },
        {
            title: "话题编号",
            dataIndex: "topic_id",
            key: "key",
        },
        {
            title: "标签",
            dataIndex: "label",
            key: "label",
            filters: Array.from(
                new Set(data.map((item: { label: string }) => item.label)) // 提取唯一的标签值
            )
                .filter(Boolean) // 过滤掉空值
                .map((label: string) => ({
                    text: label, // 显示在下拉菜单中的文本
                    value: label, // 用于过滤的实际值
                })),
            onFilter: (value: boolean | Key, record: any) => {
                return record.label.includes(value); // 返回布尔值以确定是否匹配
            },
        },
    ];

    const dynamicLabelColumns = labelTypes.map((labelType) => {
        const filterValues = [
            ...new Set(data.map((item) => item[labelType]).filter(Boolean)),
        ];
        return {
            title: labelType,
            dataIndex: labelType,
            key: labelType,
            filters: filterValues.map((item) => ({
                text: item,
                value: item,
            })),
            onFilter: (value: any, record: any) => {
                // 确保返回布尔值
                return record[labelType]?.includes(String(value));
            },
        };
    });

    const columns = [...baseColumns, ...dynamicLabelColumns];

    // Handle table row selection
    const onSelectChange = (
        newSelectedRowKeys: Key[],
        selectedRows: any[],
        info: { type: RowSelectMethod }
    ) => {
        console.log(newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // Handle label selection changes from LabelSelector
    const handleLabelSelectorChange = (values: string[]) => {
        setSelectedLabels(values);
    };

    // Handle role selection changes from RoleSelector
    const handleRoleSelectorChange = (
        values: (string | number | null)[],
        selectedOption: DefaultOptionType[]
    ) => {
        if (values.length > 0) {
            const pair = values[1] as string;
            const [themeId, roleId] = pair.split(",");
            setThemeId(themeId);
            setRoleId(roleId);
            dispatch(fetchTopicsForLabelByRoleId({ themeId, roleId }));
        }
    };

    // 处理分页变化
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
        handleFilter(filters); // 调用 handleFilter 函数进行筛选
    };

    const handleAddLabelClick = async () => {
        const topicIds = selectedRowKeys; // 获取选中的行
        if (selectedLabels.length === 0) {
            message.warning("请选择至少一个标签");
            return;
        }
        if (topicIds.length === 0) {
            message.warning("请至少选择一个topic");
            return;
        }
        const labelIds = selectedLabels;
        const hide = message.loading("正在添加标签...", 0); // 显示加载中提示
        try {
            const result = await addTopicLabel({
                label_ids: labelIds,
                topic_ids: topicIds,
            });

            if (result.error) {
                throw new Error(result.error);
            }
            dispatch(fetchTopicsForLabelByRoleId({ themeId, roleId }));
            message.success("标签添加成功");
        } catch (error: any) {
            message.error("添加标签失败: " + (error || "未知错误"));
        } finally {
            hide(); // 隐藏加载中提示
        }
    };

    const handleDeleteLabelClick = async () => {
        const topicIds = selectedRowKeys; // 获取选中的行
        if (selectedLabels.length === 0) {
            message.warning("请选择至少一个标签");
            return;
        }
        const label_ids = selectedLabels;
        const hide = message.loading("正在删除标签...", 0); // 显示加载中提示
        try {
            await deleteTopicLabel(label_ids, topicIds);
            message.success("标签删除成功");
            dispatch(fetchTopicsForLabelByRoleId({ themeId, roleId }));
        } catch (error) {
            console.error("Error deleting label:", error);
            message.error("删除标签失败");
        } finally {
            hide(); // 隐藏加载中提示
        }
    };

    const handleSelectAll = () => {
        setSelectedRowKeys(filteredData.map((item) => item.topic_id));
    };

    const handleDeselectAll = () => {
        setSelectedRowKeys([]);
    };

    const handleInvertSelection = () => {
        const currentPageData = filteredData.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
        );
        const currentPageKeys = currentPageData.map((item) => item.topic_id);
        const newSelectedRowKeys = selectedRowKeys.filter(
            (key) => !currentPageKeys.includes(key)
        );
        currentPageKeys.forEach((key) => {
            if (!selectedRowKeys.includes(key)) {
                newSelectedRowKeys.push(key);
            }
        });
        setSelectedRowKeys(newSelectedRowKeys);
    };

    return (
        <div>
            <Card>
                <Space direction="vertical">
                    <RoleSelector onChange={handleRoleSelectorChange} />
                    <LabelSelector
                        value={selectedLabels}
                        onChange={handleLabelSelectorChange}
                    />
                    <div>
                        <Space>
                            <Button
                                type="primary"
                                onClick={handleAddLabelClick}
                            >
                                加上标签
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleDeleteLabelClick}
                                danger
                            >
                                删除标签
                            </Button>
                        </Space>
                    </div>
                </Space>
            </Card>
            <div style={{ height: "2vh" }}></div>
            <Card>
                <Space>
                    <Button onClick={handleSelectAll}>全选所有</Button>
                    <Button onClick={handleDeselectAll}>取消全选</Button>
                    <Button onClick={handleInvertSelection}>反选单页</Button>
                </Space>
                <Divider></Divider>
                <Table
                    rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange,
                    }}
                    dataSource={filteredData}
                    columns={columns}
                    loading={loading}
                    rowKey="topic_id"
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: filteredData.length,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20", "50"],
                    }}
                    onChange={handleTableChange}
                    size="small"
                />
            </Card>
        </div>
    );
};

export default AddLabelPage;
