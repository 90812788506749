// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-text {
    background-color: yellow; /* 可选，增加背景色以更明显 */
}

.monaco-editor {
    font-size: 50px; /* 调整字体大小 */
}

/* 还可以调整行高等 */
.monaco-editor .view-line {
    line-height: 2.0; /* 调整行高 */
}
.mark-highlight {
    background-color: rgb(123, 207, 123); /* 高亮背景颜色 */
    font-weight: bold; /* 使文本加粗 */
    color: green !important;  /* 强制设置文本颜色，避免继承 */
    font-weight: normal !important; /* 强制取消字体加粗 */
    font-style: normal !important; /* 强制取消斜体 */
    text-decoration: none !important; /* 强制取消下划线 */
}`, "",{"version":3,"sources":["webpack://./src/components/highlight-text.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,EAAE,iBAAiB;AAC/C;;AAEA;IACI,eAAe,EAAE,WAAW;AAChC;;AAEA,aAAa;AACb;IACI,gBAAgB,EAAE,SAAS;AAC/B;AACA;IACI,oCAAoC,EAAE,WAAW;IACjD,iBAAiB,EAAE,UAAU;IAC7B,uBAAuB,GAAG,kBAAkB;IAC5C,8BAA8B,EAAE,aAAa;IAC7C,6BAA6B,EAAE,WAAW;IAC1C,gCAAgC,EAAE,YAAY;AAClD","sourcesContent":[".highlight-text {\n    background-color: yellow; /* 可选，增加背景色以更明显 */\n}\n\n.monaco-editor {\n    font-size: 50px; /* 调整字体大小 */\n}\n\n/* 还可以调整行高等 */\n.monaco-editor .view-line {\n    line-height: 2.0; /* 调整行高 */\n}\n.mark-highlight {\n    background-color: rgb(123, 207, 123); /* 高亮背景颜色 */\n    font-weight: bold; /* 使文本加粗 */\n    color: green !important;  /* 强制设置文本颜色，避免继承 */\n    font-weight: normal !important; /* 强制取消字体加粗 */\n    font-style: normal !important; /* 强制取消斜体 */\n    text-decoration: none !important; /* 强制取消下划线 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
