// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 自定义样式覆盖 Ant Design Menu 的选中项背景颜色 */
.ant-menu-horizontal>.ant-menu-item-selected {
    background-color: transparent !important;
    /* 取消选中项的背景色 */
}

/* 去掉 SubMenu 的圆角和空隙 */
.ant-menu-submenu-popup {
    margin: 0 !important;
    /* 去掉 SubMenu 与主菜单之间的空隙 */
    padding: 0 !important;
    /* 去掉 SubMenu 的内边距 */
    border-radius: 0 !important;
    /* 去掉圆角 */
}

.ant-menu-item {
    margin: 0 !important;
    /* 确保主菜单项之间也没有空隙 */
    padding: 0 16px !important;
    /* 调整主菜单项的内边距 */
}

/* 去掉悬停或选中状态的圆角 */
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-item-selected {
    border-radius: 0 !important;
    /* 去掉悬停或选中时的圆角 */
}`, "",{"version":3,"sources":["webpack://./src/components/frame-header.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,wCAAwC;IACxC,cAAc;AAClB;;AAEA,sBAAsB;AACtB;IACI,oBAAoB;IACpB,yBAAyB;IACzB,qBAAqB;IACrB,oBAAoB;IACpB,2BAA2B;IAC3B,SAAS;AACb;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,0BAA0B;IAC1B,eAAe;AACnB;;AAEA,iBAAiB;AACjB;;;IAGI,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":["/* 自定义样式覆盖 Ant Design Menu 的选中项背景颜色 */\n.ant-menu-horizontal>.ant-menu-item-selected {\n    background-color: transparent !important;\n    /* 取消选中项的背景色 */\n}\n\n/* 去掉 SubMenu 的圆角和空隙 */\n.ant-menu-submenu-popup {\n    margin: 0 !important;\n    /* 去掉 SubMenu 与主菜单之间的空隙 */\n    padding: 0 !important;\n    /* 去掉 SubMenu 的内边距 */\n    border-radius: 0 !important;\n    /* 去掉圆角 */\n}\n\n.ant-menu-item {\n    margin: 0 !important;\n    /* 确保主菜单项之间也没有空隙 */\n    padding: 0 16px !important;\n    /* 调整主菜单项的内边距 */\n}\n\n/* 去掉悬停或选中状态的圆角 */\n.ant-menu-item:hover,\n.ant-menu-item-active,\n.ant-menu-item-selected {\n    border-radius: 0 !important;\n    /* 去掉悬停或选中时的圆角 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
