import type {
    GetProp,
    TableColumnsType,
    TableProps,
    TransferProps,
} from "antd";
import { Table, Tag, Transfer } from "antd";
import React, { Key } from "react";
import { statusEnum, StatusKey } from "./topics-of-role-table";

const TableTransfer: React.FC<TableTransferProps> = (props) => {
    const { leftColumns, rightColumns, ...restProps } = props;
    return (
        <Transfer style={{ width: "100%" }} {...restProps}>
            {({
                direction,
                filteredItems,
                onItemSelect,
                onItemSelectAll,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns =
                    direction === "left" ? leftColumns : rightColumns;
                const rowSelection: TableRowSelection<TransferItem> = {
                    getCheckboxProps: () => ({ disabled: listDisabled }),
                    onChange(selectedRowKeys) {
                        onItemSelectAll(selectedRowKeys, "replace");
                    },
                    selectedRowKeys: listSelectedKeys,
                    selections: [
                        Table.SELECTION_ALL,
                        Table.SELECTION_INVERT,
                        Table.SELECTION_NONE,
                    ],
                };

                return (
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        size="small"
                        rowKey={(record) => record.topic_id}
                        style={{
                            pointerEvents: listDisabled ? "none" : undefined,
                        }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            onClick: () => {
                                if (itemDisabled || listDisabled) {
                                    return;
                                }
                                onItemSelect(
                                    key,
                                    !listSelectedKeys.includes(key)
                                );
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );
};

const TopicTableTransfer: React.FC<{
    dataSource: DataType[];
    onChange: TableTransferProps["onChange"];
    targetKeys: Key[] | undefined;
}> = ({ dataSource, onChange, targetKeys }) => {
    const filterOption = (input: string, item: DataType) =>
        item.topic_name?.includes(input) ||
        item.processing_status?.includes(input);

    return (
        <TableTransfer
            style={{ width: "100%" }}
            dataSource={dataSource}
            targetKeys={targetKeys}
            showSelectAll={true}
            onChange={onChange}
            filterOption={filterOption}
            leftColumns={columns}
            rightColumns={columns}
        />
    );
};

type TransferItem = GetProp<TransferProps, "dataSource">[number];
type TableRowSelection<T extends object> = TableProps<T>["rowSelection"];

interface DataType {
    theme_id: string;
    role_id: string;
    topic_id: string;
    topic_name: string;
    processing_status: string;
}

export interface TableTransferProps extends TransferProps<TransferItem> {
    dataSource: DataType[];
    leftColumns: TableColumnsType<DataType>;
    rightColumns: TableColumnsType<DataType>;
}

const columns: TableColumnsType<DataType> = [
    {
        dataIndex: "topic_id",
        title: "话题编号",
        onCell: () => ({
            style: { display: "none" },
        }),
        onHeaderCell: () => ({
            style: { display: "none" },
        }),
    },
    {
        dataIndex: "topic_name",
        title: "话题名称",
    },
    {
        title: "Status",
        dataIndex: "processing_status",
        key: "processing_status",
        render: (status: StatusKey) => {
            const { tag, color } = statusEnum[status] || {};
            return tag ? <Tag color={color}>{tag}</Tag> : null;
        },
        filters: Object.keys(statusEnum).map((key) => ({
            text: statusEnum[key as StatusKey].tag,
            value: key,
        })),
        onFilter: (
            value: string | number | boolean | bigint,
            record: DataType
        ) => {
            return value
                .toString()
                .includes(record.processing_status.toString());
        },
    },
];

export default TopicTableTransfer;
