import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ThemeRoleTree from '../components/theme-role-tree';
import './resource-page.css';

const ResourcePage: React.FC = () => {
    const [selectedNode, setSelectedNode] = useState<{ type: 'role' | 'theme'; key: string | null; title: string | null }>({ type: 'theme', key: null, title: null });
    const navigate = useNavigate();

    const handleSelectNode = (node: any) => {
        setSelectedNode({ type: node.type, key: node.key, title: node.title });
    };

    useEffect(() => {
        if (selectedNode.key) {  // 确保节点已被选择
            const title = selectedNode.title;

            if (selectedNode.type === 'theme') {
                const themeId = selectedNode.key;
                navigate(`role_list_of_theme/${themeId}`, { state: { title } });
            } else if (selectedNode.type === 'role') {
                const [themeId, roleId] = selectedNode.key.split(',');
                navigate(`topic_list_of_role/${themeId}/${roleId}`, { state: { title } });
            }
        }
    }, [selectedNode]);

    return (
        <div className='grid-container'>
            <div className='tree-container'>
                <ThemeRoleTree onSelectNode={handleSelectNode}></ThemeRoleTree>
            </div>
            <div className='table-container'>
                <Outlet></Outlet>
            </div>
        </div>
    )
}

export default ResourcePage;