import {
    Button,
    Card,
    Form,
    Input,
    message,
    Modal,
    Select,
    Space,
    Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchUsers } from "../redux/thunks";
import {
    createUser,
    deleteUser,
    getUserTypes,
    updateUser,
} from "../services/api";

const UserManagementPage: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { data, loading } = useSelector(
        (state: RootState) => state.userTable
    );
    const [userTypes, setUserTypes] = useState<any[]>([]);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    useEffect(() => {
        const fetchUserTypes = async () => {
            try {
                const res = await getUserTypes();
                setUserTypes(res.data);

                if (res.data.length > 0) {
                    form.setFieldsValue({
                        type: res.data[2].desc,
                    });
                }
            } catch (error) {
                console.error("Failed to fetch user types:", error);
            }
        };

        fetchUserTypes();
    }, [form]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                console.log("Form values:", values);
                try {
                    // 调用 createUser API 创建用户
                    await createUser({
                        name: values.name,
                        nickname: values.nickname,
                        type: userTypes.find(
                            (item) => item.desc === values.type
                        ).code,
                        password: values.password,
                    });
                    setIsModalVisible(false);
                    form.resetFields();
                    message.success(
                        `用户创建成功！用户名: ${values.name} 密码: ${values.password}`,
                        5 // 设置消息框停留时间为 5 秒
                    );
                    dispatch(fetchUsers()); // 刷新用户列表
                } catch (error) {
                    console.error("Failed to create user:", error);
                    message.error("用户创建失败，请重试！");
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const columns = [
        {
            title: "序号",
            dataIndex: "index",
            key: "index",
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: "用户名",
            dataIndex: "name",
            key: "name",
            sorter: (a: any, b: any) =>
                a.name.localeCompare(b.name, "zh-Hans-CN"),
        },
        {
            title: "用户昵称",
            dataIndex: "nickname",
            key: "nickname",
        },
        {
            title: "用户角色",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "用户编号",
            dataIndex: "id",
            key: "id",
            render: (text: any) => String(text),
        },
        {
            title: "创建时间",
            dataIndex: "create_time",
            key: "create_time",
        },
        {
            title: "修改时间",
            dataIndex: "update_time",
            key: "update_time",
        },
        {
            title: "操作",
            key: "action",
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Select
                        defaultValue={record.type}
                        onChange={(value) => handleTypeChange(value, record)}
                        style={{ width: 200 }}
                    >
                        {userTypes.map((item) => (
                            <Select.Option key={item.code} value={item.code}>
                                {item.title} - {item.desc}
                            </Select.Option>
                        ))}
                    </Select>
                    <Button
                        type="text"
                        style={{ color: "blue" }}
                        onClick={() =>
                            Modal.confirm({
                                title: "确认删除",
                                content: "你确定要删除这个用户吗？",
                                okText: "确认",
                                cancelText: "取消",
                                onOk: () => handleDelete(record.id),
                            })
                        }
                    >
                        删除
                    </Button>
                </Space>
            ),
        },
    ];

    const handleDelete = async (id: string) => {
        try {
            console.log(`User with id ${id} deleted`);
            await deleteUser(id);
        } catch (error) {
            console.error("Failed to delete user:", error);
        }
        dispatch(fetchUsers());
    };

    const handleTypeChange = async (value: string, record: any) => {
        try {
            await updateUser(String(record.id), { type: Number(value) });
            console.log(`User ${record.name}'s role updated to: ${value}`);
            message.success("success");
            dispatch(fetchUsers());
        } catch (error) {
            console.error("Failed to change user:", error);
            message.error("failed");
        }
    };

    return (
        <div>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Card>
                    <Button type="primary" onClick={showModal}>
                        新增用户
                    </Button>
                    <Modal
                        title="新增用户"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <Form layout="vertical" form={form}>
                            <Form.Item
                                label="用户名"
                                name="name"
                                rules={[
                                    { required: true, message: "请输入用户名" },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="用户昵称"
                                name="nickname"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入用户昵称",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="用户密码"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入用户密码",
                                    },
                                ]}
                            >
                                <Input.Password
                                    addonAfter={
                                        <Button
                                            onClick={() => {
                                                const randomPassword =
                                                    Math.random()
                                                        .toString(36)
                                                        .slice(-8);
                                                form.setFieldsValue({
                                                    password: randomPassword,
                                                });
                                            }}
                                        >
                                            随机生成
                                        </Button>
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="用户角色"
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择用户角色",
                                    },
                                ]}
                            >
                                <Select>
                                    {userTypes.map((item) => (
                                        <Select.Option
                                            key={item.code}
                                            value={item.desc}
                                        >
                                            {item.title} - {item.desc}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Card>
                <Card>
                    <Table
                        columns={columns}
                        dataSource={data || []}
                        rowKey="id"
                        size="small"
                        pagination={{
                            pageSizeOptions: ["50", "100", "1000"],
                            defaultPageSize: 50,
                            showSizeChanger: true,
                        }}
                    />
                </Card>
            </Space>
        </div>
    );
};

export default UserManagementPage;
