import { Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';

interface ThemeFormModalProps {
    visible: boolean;
    mode: 'create' | 'edit';
    onSubmit: (values: { name: string }) => void;
    onCancel: () => void;
    initialValues?: { name: string};
}

const ThemeFormModal: React.FC<ThemeFormModalProps> = ({ visible, mode, onSubmit, onCancel, initialValues }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(initialValues || { name: ''});
        }
    }, [visible, initialValues, form]);

    const handleSubmit = () => {
        form.validateFields().then(values => {
            onSubmit(values);
        });
    };

    return (
        <Modal
            visible={visible}
            title={mode === 'create' ? '创建主题' : '编辑主题'}
            onCancel={onCancel}
            onOk={handleSubmit}
        >
            <Form form={form} layout="horizontal">
                <Form.Item
                    name="name"
                    label="主题名称"
                    rules={[{ required: true, message: '请输入主题名称' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ThemeFormModal;
