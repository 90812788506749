import { Select } from "antd";
import { useEffect, useState } from "react";
import { getUsersDataForSelect } from "../../services/api";

interface UserSelectorProps {
    value: string | undefined; // 选中的用户
    onChange: (value: string) => void; // 处理选择变化的函数
}

const UserSelector: React.FC<UserSelectorProps> = ({ value, onChange }) => {
    // 定义状态存储用户数据
    const [users, setUsers] = useState<{ label: JSX.Element; value: string }[]>(
        []
    );

    // 加载用户数据
    useEffect(() => {
        const fetchData = async () => {
            const res = await getUsersDataForSelect();
            const mappedUsers = res.data
                .map(
                    (item: { id: string; name: string; nickname: string }) => ({
                        label: (
                            <span>
                                {item.name}({item.nickname})
                            </span>
                        ),
                        value: item.id,
                        name: item.name,
                    })
                )
                .sort(
                    (
                        a: { label: JSX.Element; value: string; name: string },
                        b: { label: JSX.Element; value: string; name: string }
                    ) => a.name.localeCompare(b.name)
                ); // 使用 localeCompare 进行字符串排序

            setUsers(mappedUsers);
        };

        fetchData();
    }, []);

    return (
        <div>
            <span>用户选择：</span>
            <Select
                showSearch
                placeholder="请选择用户"
                onChange={(value) => onChange(value)}
                style={{ width: "300px" }}
                options={users}
                value={value}
                filterOption={(input, option) =>
                    option?.label.props.children
                        .join("")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
            />
        </div>
    );
};

export default UserSelector;
