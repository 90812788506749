import {
    FileOutlined,
    MessageOutlined,
    UserOutlined
} from "@ant-design/icons";
import { Dropdown, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/image1.png";
import userAvatar from "../assets/user.png";
import { getCurrentUser, getUserTypes, logoutUser } from "../services/api";
import "./frame-header.css";

const { Header } = Layout;

const items = [
    {
        key: "8",
        icon: <FileOutlined />,
        label: "语音合成",
        auth: ["admin", "corpus_manager", "corpus_editor", "algo", "tester"],
        children: [
            { 
                key: "81", 
                label: "语音合成 V1", 
                auth: ["admin", "corpus_manager", "corpus_editor", "algo", "tester"] 
            },
            { 
                key: "82", 
                label: "语音合成 V2", 
                auth: ["admin", "corpus_manager", "corpus_editor", "algo", "tester"] 
            },
        ]
    },
    {
        key: "4",
        label: "权限管理",
        icon: <UserOutlined />,
        auth: ["admin", "corpus_manager"],
        children: [
            { key: "41", label: "用户管理", auth: ["admin", "corpus_manager"] },
            {
                key: "42",
                label: "角色授权",
                auth: ["admin", "corpus_manager"],
            },
        ],
    },
    {
        key: "5",
        icon: <MessageOutlined />,
        label: "调试工具",
        auth: ["admin", "corpus_manager", "corpus_editor", "algo", "tester"],
    }
];

const FrameHeader: React.FC = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<{
        name: string;
        nickname: string;
        type: number;
    } | null>(null);

    const [userTypes, setUserTypes] = useState<{
        [key: number]: { title: string; desc: string };
    }>({});

    useEffect(() => {
        const fetchUserTypes = async () => {
            try {
                const res = await getUserTypes();
                const userTypeDict = res.data.reduce(
                    (
                        acc: { [key: number]: { title: string; desc: string } },
                        userType: { code: number; title: string; desc: string }
                    ) => {
                        acc[userType.code] = {
                            title: userType.title,
                            desc: userType.desc,
                        };
                        return acc;
                    },
                    {}
                );
                setUserTypes(userTypeDict);
            } catch (error) {
                console.error("Failed to fetch user types:", error);
            }
        };
        fetchUserTypes();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            const userData = await getCurrentUser();
            setUser(userData.data);
        };
        fetchUser();
    }, []);

    const userMenu = (
        <Menu>
            <Menu.Item
                key="logout"
                onClick={() => {
                    logoutUser();
                    navigate("/login");
                }}
            >
                退出登录
            </Menu.Item>
        </Menu>
    );

    const onMenuClick = ({ key }: { key: string }) => {
        switch (key) {
            case "41":
                navigate("user-management-page");
                break;
            case "42":
                navigate("user-asset-page");
                break;
            case "5":
                navigate("prompt-test-page");
                break;
            case "81":
                navigate("tts-v1-page");
                break;
            case "82":
                navigate("tts-v2-page");
                break;
            default:
                break;
        }
    };

    const filterMenuItems = (items: any[], userTypeTitle: string): any[] => {
        console.log(userTypeTitle);
        console.log(items);
        return items
            .filter((item) => item.auth.includes(userTypeTitle))
            .map((item) => {
                if (item.children) {
                    return {
                        ...item,
                        children: filterMenuItems(item.children, userTypeTitle),
                    };
                }
                return item;
            });
    };

    const userTypeTitle = user ? userTypes[user.type]?.title : "";
    const filteredItems = filterMenuItems(items, userTypeTitle);

    return (
        <Header style={{ background: "#001529", padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* 左侧：Logo和标题 */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={logo}
                        alt="Logo"
                        style={{
                            height: "32px",
                            alignItems: "center",
                            marginRight: "16px",
                        }}
                    />
                    <span
                        style={{
                            color: "#fff",
                            fontSize: "20px",
                            fontWeight: "bold",
                            marginTop: "6px",
                        }}
                    >
                        Qihang AILab
                    </span>
                </div>

                {/* 中间：菜单 */}
                <Menu
                    mode="horizontal"
                    theme="dark"
                    items={filteredItems}
                    onClick={onMenuClick}
                    style={{
                        flex: 1,
                        justifyContent: "left",
                        paddingLeft: "50px",
                        borderBottom: "none",
                    }}
                    className="custom-menu"
                />

                {/* 右侧：用户信息和头像 */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    {user && (
                        <span
                            style={{
                                color: "#fff",
                                marginRight: "16px",
                            }}
                        >
                            {user.nickname} ({userTypes[user.type]?.desc})
                        </span>
                    )}
                    <Dropdown overlay={userMenu} trigger={["click"]}>
                        <img
                            src={userAvatar}
                            alt="User Icon"
                            style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                cursor: "pointer",
                            }}
                        />
                    </Dropdown>
                </div>
            </div>
        </Header>
    );
};

export default FrameHeader;
