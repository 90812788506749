import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVICE_URL_PREFIX from "../constants/global";
// 异步 thunk 用于获取文件树数据
export const fetchRolesTree = createAsyncThunk(
    "role_tree/fetchFileTree",
    async (id, { rejectWithValue }) => {
        const accessToken = localStorage.getItem("access_token");

        try {
            const response = await fetch(
                `${SERVICE_URL_PREFIX}/themes/roles/tree`,
                {
                    credentials: "include",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            if (!response.ok) {
                if (response.status === 401) {
                    window.location.href = "/login";
                }
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回表格数据
        } catch (error) {
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchRolesSummaryByThemeId = createAsyncThunk(
    "role_table/fetchRolesSummaryByThemeId", // thunk 的前缀
    async (themeId: string, { rejectWithValue }) => {
        try {
            // 发起网络请求
            const response = await fetch(
                `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/summary`,
                { credentials: "include" }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchRolesByThemeId = createAsyncThunk(
    "role_table/fetchRolesByThemeId",
    async (themeId: string, { rejectWithValue }) => {
        try {
            const response = await fetch(
                `${SERVICE_URL_PREFIX}/themes/${themeId}/roles`,
                { credentials: "include" }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data;
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchAllRoles = createAsyncThunk(
    "role_table/fetchAllRoles",
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(`${SERVICE_URL_PREFIX}/themes/roles`, {
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data;
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchTopicsByRoleId = createAsyncThunk(
    "topic_table/fetchTopicsByRoleId",
    async (
        {
            themeId,
            roleId,
            page,
            pageSize,
            label = "",
            processingStatus = "",
            names = "",
        }: {
            themeId: string;
            roleId: string;
            page: number;
            pageSize: number;
            label?: string;
            processingStatus?: string;
            names?: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const queryParams = new URLSearchParams({
                page: page.toString(),
                page_size: pageSize.toString(),
            });
            // 只有在参数存在时才添加它们到查询参数
            if (label) queryParams.append("label", label);
            if (processingStatus)
                queryParams.append("processing_status", processingStatus);
            if (names) queryParams.append("names", names);

            // 发起网络请求
            const response = await fetch(
                `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics?${queryParams.toString()}`,
                { credentials: "include" }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchTopicInfoByTopicId = createAsyncThunk(
    "topic_info/fetchTopicInfoByTopicId",
    async (
        {
            themeId,
            roleId,
            topicId,
        }: { themeId: string; roleId: string; topicId: string },
        { rejectWithValue }
    ) => {
        try {
            // 发起网络请求
            const response = await fetch(
                `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/${topicId}`,
                { credentials: "include" }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchFormalKeywordById = createAsyncThunk(
    "formal_keywords/fetchKeywordById",
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(`${SERVICE_URL_PREFIX}/keywords/1`, {
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchLabels = createAsyncThunk(
    "labels/fetchLabels",
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(`${SERVICE_URL_PREFIX}/labels`, {
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchTopicsForLabelByRoleId = createAsyncThunk(
    "topic_table/fetchTopicsForLabelByRoleId",
    async (
        {
            themeId,
            roleId,
            labels,
        }: { themeId: string; roleId: string; labels?: string[] },
        { rejectWithValue }
    ) => {
        try {
            // 构建请求URL，包含可选的labels参数
            let url = `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/label`;
            if (labels && labels.length > 0) {
                const labelsParam = labels.join(",");
                url += `?labels=${encodeURIComponent(labelsParam)}`;
            }

            // 发起网络请求
            const response = await fetch(url, { credentials: "include" });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchTopicsForExport = createAsyncThunk(
    "topic_table/fetchTopicsForExport",
    async (
        {
            themeId,
            roleId,
            labels,
        }: { themeId?: string; roleId?: string; labels?: string[] },
        { rejectWithValue }
    ) => {
        try {
            // 构建请求URL
            let url = `${SERVICE_URL_PREFIX}/export/topics`;

            // 构建查询参数
            const queryParams: string[] = [];
            if (themeId) {
                queryParams.push(`theme_id=${encodeURIComponent(themeId)}`);
            }
            if (roleId) {
                queryParams.push(`role_id=${encodeURIComponent(roleId)}`);
            }
            if (labels && labels.length > 0) {
                const labelsParam = labels.join(",");
                queryParams.push(`labels=${encodeURIComponent(labelsParam)}`);
            }

            // 如果有查询参数，拼接到URL
            if (queryParams.length > 0) {
                url += `?${queryParams.join("&")}`;
            }

            // 发起网络请求
            const response = await fetch(url, { credentials: "include" });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchUsers = createAsyncThunk(
    "users/fetchUsers",
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(`${SERVICE_URL_PREFIX}/users`, {
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchRoleLabelIssueRreport = createAsyncThunk(
    "reports/fetchRoleLabelIssueRreport",
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(
                `${SERVICE_URL_PREFIX}/themes/roles/label_issue_report`,
                {
                    credentials: "include",
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);


export const fetchEduReport = createAsyncThunk(
    "reports/fetchEduReport",
    async ({ year, province, subjects, score }: { year: string; province: string; subjects: string[],score: string}, { rejectWithValue }) => {
        try {
            const queryString = new URLSearchParams({
                year,
                province,
                subjects: subjects.join(','),
                score,
            }).toString();
            console.log(subjects)
            const response = await fetch(
                `${SERVICE_URL_PREFIX}/edu/report?${queryString}`,
                {
                    credentials: "include",
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);

export const fetchEduReport2 = createAsyncThunk(
    "reports/fetchEduReport",
    async ({ year, province, score }: { year: string; province: string,score: string}, { rejectWithValue }) => {
        try {
            const queryString = new URLSearchParams({
                year,
                province,
                score,
            }).toString();
            const response = await fetch(
                `${SERVICE_URL_PREFIX}/edu/report2?${queryString}`,
                {
                    credentials: "include",
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            if (data.status !== 200) {
                return rejectWithValue(
                    data.message || "Unknown error occurred"
                );
            }
            return data.data; // 返回成功的数据
        } catch (error) {
            // 捕获并返回错误
            return rejectWithValue(
                (error as Error).message || "Unknown error occurred"
            );
        }
    }
);