import { message, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom for navigation
import { cleanDrawerContent } from "../redux/reducers/editor-content-reducer";
import { updatePagination } from "../redux/reducers/topic-table-reducer";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchTopicsByRoleId } from "../redux/thunks";
import { RowType, statusEnum, StatusKey } from "./topics-of-role-table";

interface CodeEditorTopicIndexProps {
    roleId: string;
    themeId: string;
}

const CodeEditorTopicIndex: React.FC<CodeEditorTopicIndexProps> = ({
    roleId,
    themeId,
}) => {
    const dispatch = useAppDispatch();
    const data = useSelector(
        (state: RootState) => state.topicTable.data as RowType[]
    );
    const loading = useSelector((state: RootState) => state.topicTable.loading);
    const total = useSelector((state: RootState) => state.topicTable.total);

    const currentPage = useSelector(
        (state: RootState) => state.topicTable.indexCurrentPage
    );
    const pageSize = useSelector(
        (state: RootState) => state.topicTable.indexpageSize
    );
    const columns = [
        {
            title: "No.",
            dataIndex: "index",
            key: "index",
            render: (_: any, __: any, index: number) => `${index + 1}`,
        },
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            onCell: () => ({
                style: { display: "none" }, // 通过样式隐藏
            }),
            onHeaderCell: () => ({
                style: { display: "none" }, // 隐藏表头
            }),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text: string, record: RowType) => (
                <Link
                    to={`/editor/themes/${themeId}/roles/${roleId}/topics/${record.id}`}
                    onClick={() => {
                        message.success(`跳转到${record.name}`);
                        dispatch(cleanDrawerContent());
                    }}
                >
                    {text}
                </Link>
            ),
        },
        {
            title: "Status",
            dataIndex: "processing_status",
            key: "processing_status",
            render: (status: StatusKey) => {
                const { tag, color } = statusEnum[status] || {};
                return tag ? <Tag color={color}>{tag}</Tag> : null;
            },
            filters: Object.keys(statusEnum).map((key) => ({
                text: statusEnum[key as StatusKey].tag,
                value: key,
            })),
            onFilter: (
                value: string | number | boolean | bigint,
                record: RowType
            ) => {
                return value
                    .toString()
                    .includes(record.processing_status.toString());
            },
        },
    ];

    useEffect(() => {
        dispatch(
            fetchTopicsByRoleId({
                themeId: themeId,
                roleId: roleId,
                page: currentPage,
                pageSize: pageSize,
            })
        );
    }, [dispatch, themeId, roleId, currentPage, pageSize]);

    const handleTableChange = (pagination: any) => {
        dispatch(
            updatePagination({
                currentPage: pagination.current,
                pageSize: pagination.pageSize,
            })
        );
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                }}
                style={{ width: "100%", overflowY: "auto", height: "90vh" }}
                size="small"
                onChange={handleTableChange}
            />
        </div>
    );
};

export default CodeEditorTopicIndex;
