import type { CascaderProps } from "antd";
import { Cascader } from "antd";
import { useEffect, useState } from "react";
import { getRolesDataForSelect } from "../../services/api";
const { SHOW_CHILD } = Cascader;

const MultipleRoleSelector: React.FC<CascaderProps<any[]>> = ({
    value,
    onChange,
}) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getRolesDataForSelect();
                setOptions(res.data);
            } catch (error) {
                console.error("Failed to fetch roles data", error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            <span>角色选择：</span>
            <Cascader
                value={value}
                options={options}
                onChange={onChange}
                placeholder="请选择角色"
                showSearch
                multiple
                showCheckedStrategy={SHOW_CHILD}
                style={{
                    width: "50vw", // 增加宽度
                }}
                size="large"
                dropdownRender={(menu) => (
                    <div
                        style={{
                            width: "50vw",
                            maxHeight: "400px",
                            overflowY: "auto",
                        }}
                    >
                        {menu}
                    </div>
                )}
            />
        </div>
    );
};

export default MultipleRoleSelector;
