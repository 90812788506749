import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchRolesSummaryByThemeId } from "../thunks";

interface RoleSummaryTableState {
  data: any[];
  loading: boolean;
  error: string | null;
}

const initialState: RoleSummaryTableState = {
  data: [],
  loading: false,
  error: null,
};

export const RoleSummaryTableSlice = createSlice({
  name: "roleSummaryTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRolesSummaryByThemeId.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchRolesSummaryByThemeId.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
        }
      )
      .addCase(fetchRolesSummaryByThemeId.rejected, (state, action) => {
        state.loading = false;
        console.error("Fetch failed:", action.error);
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export type RoleSummaryTableSliceType = ReturnType<
  typeof RoleSummaryTableSlice.reducer
>;
export default RoleSummaryTableSlice.reducer;
