import { Card, Divider, Table } from "antd";
import React, { useState } from "react";
import ChatBot from "../components/chatbot";
import ModelSelector from "../components/selects/model-selector";

const GptPage: React.FC = () => {
    const [selectedValue, setSelectedValue] = useState<string>("CLAUDE-3.5-SONNET"); // 模型选择
    const temperature = 0; // 温度
    const systemPrompt = "你是一名人工智能助手，请回答用户提出的问题。"; // 系统提示
    const [conversationHistory, setConversationHistory] = useState<string[]>([]);

    // 处理模型选择
    const handleSelect = (value: any) => {
        setSelectedValue(value);
    };

    // 更新对话历史
    const updateConversationHistory = (newMessage: string) => {
        setConversationHistory((prev) => [...prev, newMessage]);
    };

    // 定义表格列
    const columns = [
        {
            title: "序号",
            dataIndex: "index",
            key: "index",
            width: 80,
        },
        {
            title: "内容",
            dataIndex: "message",
            key: "message",
        },
    ];

    // 根据 conversationHistory 构造表格数据
    const dataSource = conversationHistory.map((item, index) => ({
        key: index,
        index: index + 1,
        message: item,
    }));

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* 左侧卡片区域，占比约 4/5 */}
            <Card style={{ flex: 4, marginLeft: "2px" }}>
                <div>
                    <div>
                        <div>选择模型</div>
                        <ModelSelector onSelect={handleSelect} />
                    </div>
                </div>
                <Divider />
                <ChatBot
                    source={selectedValue}
                    temperature={temperature}
                    systemPrompt={systemPrompt}
                />
            </Card>

            {/* 右侧卡片区域，占比约 1/5 */}
            <Card style={{ flex: 1, marginLeft: "2px" }}>
                <div>对话历史</div>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    style={{ marginTop: 8 }}
                />
            </Card>
        </div>
    );
};

export default GptPage;
