import { createSlice } from '@reduxjs/toolkit';
import { fetchLabels } from '../thunks';

interface LabelRowType {
    created_by:number|null;
    created_time:string;
    label_id:number;
    label_name:string;
    label_type:string;
    status:number;
    updated_by:number|null;
    updated_time:string;
}

interface LabelsState {
    data: LabelRowType[] | null;
    loading: boolean;
    error: string | null;
}

const initialState: LabelsState = {
    data: null,
    loading: false,
    error: null,
};

export const LabelsSlice = createSlice({
    name: 'labels',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLabels.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLabels.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchLabels.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
})

export type LabelsSlice = ReturnType<typeof LabelsSlice.reducer>;
export type { LabelRowType };
export default LabelsSlice.reducer