import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
    const token = localStorage.getItem('access_token');
    const location = useLocation();

    // 检查 token 是否存在，实际应用中可能还需要检查 token 的有效性
    if (!token) {
        return <Navigate to="/login" state={{ from: location }
        } replace />;
    }

    // 如果 token 存在且有效，则渲染目标组件
    return <Outlet />;
};

export default PrivateRoute;
