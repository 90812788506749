import Editor, {
    loader,
    Monaco,
    OnChange,
    OnMount,
} from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { setEditorContent } from "../redux/reducers/editor-content-reducer";
import { RootState, useAppDispatch } from "../redux/store";
import {
    fetchFormalKeywordById,
    fetchTopicInfoByTopicId,
} from "../redux/thunks";
import { fetchTopicVersionInfo } from "../services/api";
import CheckWindow from "./check-window";
import "./highlight-text.css";
loader.config({ monaco });

interface CodeEditorProps {
    themeId: string | undefined;
    roleId: string | undefined;
    topicId: string | undefined;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
    themeId,
    roleId,
    topicId,
}) => {
    const dispatch = useAppDispatch();
    const showAIEditor = useSelector(
        (state: RootState) => state.editorContent.isAIEditorShow
    );
    const showHistoryEditor = useSelector(
        (state: RootState) => state.editorContent.isHistoryEditorShow
    );
    const { drawerContent } = useSelector(
        (state: RootState) => state.editorContent
    );
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const monacoRef = useRef<any>(null);
    const decorationIdsRef = useRef<string[]>([]);
    const isUpdatingDecorationsRef = useRef<boolean>(false);

    // 从 Redux 中获取编辑器内容
    const editorContent = useSelector(
        (state: RootState) => state.editorContent.editorContent
    );
    const { data } = useSelector((state: RootState) => state.topicInfo);
    const aiGeneratedContent = data?.ai_generated_content || "";

    const [topicVersion, setTopicVersion] = useState<string>("无历史版本");

    const [showReferenceWindow, setShowReferenceWindow] =
        useState<boolean>(false);
    const [referenceContent, setReferenceContent] = useState<string>("");
    const [referenceTitle, setReferenceTitle] = useState<string>("");

    useEffect(() => {
        setShowReferenceWindow(showHistoryEditor || showAIEditor);
        setReferenceContent(showAIEditor ? aiGeneratedContent : topicVersion);
    }, [showHistoryEditor, showAIEditor, aiGeneratedContent, topicVersion]);

    useEffect(() => {
        const fetchData = async () => {
            if (showHistoryEditor) {
                if (themeId && roleId && topicId) {
                    const response = await fetchTopicVersionInfo(
                        themeId,
                        roleId,
                        topicId
                    );
                    if (response.data && response.data.content) {
                        setTopicVersion(response.data.content);
                    } else {
                        setTopicVersion("无历史版本");
                    }
                }
                setShowReferenceWindow(showHistoryEditor);
                setReferenceContent(topicVersion);
                setReferenceTitle("历史编辑版本");
            } else {
                setShowReferenceWindow(false);
                setReferenceContent("");
                setReferenceTitle("");
            }
        };
        fetchData();
    }, [showHistoryEditor, roleId, themeId, topicId, topicVersion]);

    useEffect(() => {
        if (showAIEditor) {
            setShowReferenceWindow(showAIEditor);
            setReferenceContent(aiGeneratedContent);
            setReferenceTitle("AI生成版本");
        } else {
            setShowReferenceWindow(false);
            setReferenceContent("");
            setReferenceTitle("");
        }
    }, [
        showAIEditor,
        roleId,
        themeId,
        topicId,
        topicVersion,
        aiGeneratedContent,
    ]);

    const { data: keywordsData } = useSelector(
        (state: RootState) => state.formalKeywords
    );
    const isHighlightEnabled = useSelector(
        (state: RootState) => state.highlightTaggle.isHighlightEnabled
    );

    const handleEditorChange: OnChange = (value, event) => {
        if (value !== undefined) {
            dispatch(setEditorContent(value)); // 更新 Redux 中的 editorContent 状态
        }
    };

    const handleEditorDidMount: OnMount = (editor, monaco) => {
        editor.focus();
        editorRef.current = editor;
        monacoRef.current = monaco;

        // 设置字体大小
        editor.updateOptions({
            fontSize: 18,
            formatOnType: false,
            formatOnPaste: false,
            quickSuggestions: false,
            unicodeHighlight: {
                ambiguousCharacters: false, // 关闭对模棱两可字符的高亮
                invisibleCharacters: false, // 关闭对不可见字符的高亮
                nonBasicASCII: false,
            },
        });
    };

    const highlightTextInEditor = (
        editor: monaco.editor.IStandaloneCodeEditor,
        monaco: Monaco,
        searchQuery: string[] | null
    ) => {
        if (searchQuery == null || searchQuery.length === 0) {
            editor.deltaDecorations(decorationIdsRef.current, []);
            return;
        }

        const model = editor.getModel();
        if (!model) {
            return;
        }

        const text = model.getValue();
        const decorations: monaco.editor.IModelDeltaDecoration[] = [];
        const BATCH_SIZE = 100;

        // 分批处理查询项
        for (let i = 0; i < searchQuery.length; i += BATCH_SIZE) {
            // 取出当前批次的查询项
            const batch = searchQuery
                .filter((query) => query) // 过滤空查询
                .slice(i, i + BATCH_SIZE) // 每批取1000个查询
                .map((query) => `${query}`)
                .join("|");

            // 如果当前批次为空，跳过
            if (!batch) continue;

            // 创建正则表达式
            const regex = new RegExp(batch, "g");
            let match: RegExpExecArray | null;

            // 执行正则表达式匹配
            while ((match = regex.exec(text)) !== null) {
                const startOffset = match.index;
                const endOffset = startOffset + match[0].length;

                // 获取开始和结束位置
                const startPosition = model.getPositionAt(startOffset);
                const endPosition = model.getPositionAt(endOffset);

                decorations.push({
                    range: new monaco.Range(
                        startPosition.lineNumber,
                        startPosition.column,
                        endPosition.lineNumber,
                        endPosition.column
                    ),
                    options: {
                        inlineClassName: "mark-highlight", // 应用高亮样式
                        stickiness:
                            monaco.editor.TrackedRangeStickiness
                                .NeverGrowsWhenTypingAtEdges, // 防止格式继承
                    },
                });
            }
        }

        isUpdatingDecorationsRef.current = true; // 设置标志，防止递归调用
        decorationIdsRef.current = editor.deltaDecorations(
            decorationIdsRef.current,
            decorations
        );
        isUpdatingDecorationsRef.current = false; // 复位标志
    };

    useEffect(() => {
        if (themeId && roleId && topicId) {
            dispatch(fetchTopicInfoByTopicId({ themeId, roleId, topicId }));
        }
    }, [dispatch, themeId, roleId, topicId]);

    useEffect(() => {
        dispatch(fetchFormalKeywordById());
    }, [dispatch]);

    useEffect(() => {
        if (
            data &&
            data.human_editing_content !== undefined &&
            data.human_editing_content !== null
        ) {
            const content = data.human_editing_content || "";
            dispatch(setEditorContent(content));
        } else {
            dispatch(setEditorContent(""));
        }
    }, [data, dispatch]);

    useEffect(() => {
        if (!editorRef.current || !monacoRef.current) {
            return;
        }

        const editor = editorRef.current;
        const monaco = monacoRef.current;

        const handleContentChange = () => {
            if (isUpdatingDecorationsRef.current) {
                return;
            }
            if (isHighlightEnabled && keywordsData) {
                highlightTextInEditor(editor, monaco, keywordsData);
            }
        };
        // 首次变化的时候标注
        if (isHighlightEnabled && keywordsData) {
            highlightTextInEditor(editor, monaco, keywordsData);
        } else {
            editor.deltaDecorations(decorationIdsRef.current, []);
        }

        // 监听编辑器内容变化
        const disposable = editor.onDidChangeModelContent(handleContentChange);

        // 在组件卸载时移除事件监听器
        return () => {
            disposable.dispose();
        };
    }, [isHighlightEnabled, keywordsData]);

    if (!themeId || !roleId) {
        return <div>Theme ID and Role ID are required.</div>;
    }
    return (
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <div
                style={{
                    width: "80%",
                    display: "grid",
                    gridTemplateColumns: showReferenceWindow
                        ? "1fr 1fr"
                        : "1fr",
                }}
            >
                {showReferenceWindow ? (
                    <>
                        <div style={{ width: "100%" }}>
                            <div
                                style={{
                                    textAlign: "center",
                                    border: "1px solid black",
                                }}
                            >
                                {referenceTitle}
                            </div>
                            <Editor
                                height="90vh"
                                width="100%"
                                defaultLanguage="plaintext"
                                value={referenceContent}
                                options={{
                                    readOnly: true, // 设置为只读
                                    fontSize: 18,
                                    minimap: { enabled: false },
                                    wordWrap: "on",
                                    theme: "vs-dark",
                                }}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <div
                                style={{
                                    textAlign: "center",
                                    border: "1px solid black",
                                }}
                            >
                                当前正文
                            </div>
                            <Editor
                                height="90vh"
                                width="100%"
                                defaultLanguage="plaintext"
                                value={editorContent}
                                onChange={handleEditorChange}
                                onMount={handleEditorDidMount}
                                theme="vs-dark"
                                options={{
                                    formatOnType: false,
                                    formatOnPaste: false,
                                    wordWrap: "on",
                                    minimap: { enabled: false },
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <div style={{ width: "100%", display: "flex" }}>
                        <Editor
                            height="90vh"
                            width="100%"
                            defaultLanguage="plaintext"
                            value={editorContent}
                            onChange={handleEditorChange}
                            onMount={handleEditorDidMount}
                            theme="vs-dark"
                            options={{
                                formatOnType: false,
                                formatOnPaste: false,
                                wordWrap: "on",
                                minimap: { enabled: false },
                            }}
                        />
                    </div>
                )}
            </div>
            <CheckWindow
                style={{ width: "20%" }}
                drawerContent={drawerContent}
            />
        </div>
    );
};

export default CodeEditor;
