import { Select } from 'antd';
import { useEffect, useState } from 'react';

const { Option } = Select;

interface SubjectSelectorProps {
  value: string[]; // 选中的科目数组
  onChange: (value: string[]) => void; // 处理选择变化的函数，接收字符串数组
}

const subjects = ["物理", "化学", "生物", "历史", "地理", "政治"];

const SubjectSelector: React.FC<SubjectSelectorProps> = ({ value, onChange }) => {
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);

  // 更新 selectedSubjects 当父组件传递的值变化时
  useEffect(() => {
    setSelectedSubjects(value);
  }, [value]);

  const handleChange = (selectedSubjects: string[]) => {
    setSelectedSubjects(selectedSubjects); // 更新本地选中的科目
    onChange(selectedSubjects); // 传递字符串数组给父组件
  };

  return (
    <div>
      <span>选科选择：</span>
      <Select
        mode="multiple"
        placeholder="选择选科"
        size="small"
        style={{ width: 240, marginRight: 8 }}
        onChange={handleChange}
        value={selectedSubjects}
      >
        {subjects.map(subject => (
          <Option key={subject} value={subject}>
            {subject}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SubjectSelector;
