// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card-title {
    padding: 0px 0;
    /* 减小标题的内边距，减小高度 */
    display: inline-block;
    /* 确保样式应用到内容块 */
}
.custom-menu-item:hover {
    color: #333333;
    background-color: #E5E5E5 !important; /* 取消 hover 时的背景高亮 */
    color: inherit !important; /* 保持文本颜色不变 */
}

.custom-menu-item,
.ant-menu-item-selected {
    background-color: transparent !important; /* 取消背景颜色 */
    color: inherit !important; /* 保持文本颜色不变 */
    box-shadow: none !important; /* 取消阴影效果 */
}`, "",{"version":3,"sources":["webpack://./src/components/theme-role-tree.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,cAAc;IACd,oCAAoC,EAAE,oBAAoB;IAC1D,yBAAyB,EAAE,aAAa;AAC5C;;AAEA;;IAEI,wCAAwC,EAAE,WAAW;IACrD,yBAAyB,EAAE,aAAa;IACxC,2BAA2B,EAAE,WAAW;AAC5C","sourcesContent":[".custom-card-title {\n    padding: 0px 0;\n    /* 减小标题的内边距，减小高度 */\n    display: inline-block;\n    /* 确保样式应用到内容块 */\n}\n.custom-menu-item:hover {\n    color: #333333;\n    background-color: #E5E5E5 !important; /* 取消 hover 时的背景高亮 */\n    color: inherit !important; /* 保持文本颜色不变 */\n}\n\n.custom-menu-item,\n.ant-menu-item-selected {\n    background-color: transparent !important; /* 取消背景颜色 */\n    color: inherit !important; /* 保持文本颜色不变 */\n    box-shadow: none !important; /* 取消阴影效果 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
