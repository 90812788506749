import { createSlice } from "@reduxjs/toolkit";
import { fetchUsers } from "../thunks";

interface UsersState {
    data: any[] | null;
    loading: boolean;
    error: string | null;
}

const initialState: UsersState = {
    data: null,
    loading: false,
    error: null,
};

export const UsersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Something went wrong";
            });
    },
});

export type UsersStateType = ReturnType<typeof UsersSlice.reducer>;
export default UsersSlice.reducer;
