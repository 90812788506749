import React, { useEffect, useRef, useState } from 'react';
import { getCurrentUser } from '../services/api';

const TTSV2Page: React.FC = () => {
    const [iframeUrl, setIframeUrl] = useState('https://tts.globaledux.com/v2');
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const fetchUserAndUpdateUrl = async () => {
            try {
                const userData = await getCurrentUser();
                const user = userData.data;
                // 将用户信息作为URL参数传递给iframe
                const params = new URLSearchParams({
                    username: user.nickname || '',
                    userId: user.id?.toString() || '',
                    userType: user.type?.toString() || ''
                });
                const newUrl = `https://tts.globaledux.com/v2?${params.toString()}`;
                setIframeUrl(newUrl);
                
                // 强制刷新iframe
                if (iframeRef.current) {
                    iframeRef.current.src = 'about:blank';
                    setTimeout(() => {
                        if (iframeRef.current) {
                            iframeRef.current.src = newUrl;
                        }
                    }, 100);
                }
            } catch (error) {
                console.error('获取用户信息失败:', error);
            }
        };

        fetchUserAndUpdateUrl();
    }, []);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        }}>
            <iframe 
                ref={iframeRef}
                src={iframeUrl}
                style={{
                    width: '100%',
                    height: '100vh',
                    border: 'none',
                    overflow: 'hidden'
                }}
                title="External Content"
            />
        </div>
    );
};

export default TTSV2Page; 