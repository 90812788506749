import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser } from "../services/api";

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await loginUser(username, password);
            const from = location.state?.from?.pathname || "/resource";
            navigate(from, { replace: true });
        } catch (error: any) {
            setMessage(error.message);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "#f0f2f5",
            }}
        >
            <div
                style={{
                    padding: "2rem",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    width: "100%",
                    maxWidth: "400px",
                }}
            >
                <h2 style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                    Login
                </h2>
                <form onSubmit={handleLogin}>
                    <div style={{ marginBottom: "1rem" }}>
                        <label
                            style={{ display: "block", marginBottom: "0.5rem" }}
                        >
                            Username:
                        </label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            style={{
                                display: "block",
                                width: "100%",
                                padding: "0.5rem",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: "1rem" }}>
                        <label
                            style={{ display: "block", marginBottom: "0.5rem" }}
                        >
                            Password:
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={{
                                display: "block",
                                width: "100%",
                                padding: "0.5rem",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                            }}
                        />
                        <label
                            style={{ display: "block", marginTop: "0.5rem" }}
                        >
                            <input
                                type="checkbox"
                                checked={showPassword}
                                onChange={() => setShowPassword(!showPassword)}
                            />{" "}
                            Show Password
                        </label>
                    </div>
                    <button
                        type="submit"
                        style={{
                            display: "block",
                            width: "100%",
                            padding: "0.75rem",
                            borderRadius: "4px",
                            border: "none",
                            backgroundColor: "#1890ff",
                            color: "#fff",
                            fontSize: "1rem",
                            cursor: "pointer",
                        }}
                    >
                        Login
                    </button>
                </form>
                {message && (
                    <p
                        style={{
                            color: "red",
                            marginTop: "1rem",
                            textAlign: "center",
                        }}
                    >
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
}

export default LoginPage;
