import {
  Button,
  Card,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Table,
  TableColumnType,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { LabelRowType } from "../redux/reducers/labels-reducer";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchLabels } from "../redux/thunks";
import { addLabel, deleteLabel } from "../services/api";

const { confirm } = Modal;

const LabelTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    data: labels,
    loading,
    error,
  } = useSelector((state: RootState) => state.labelTable);
  const [newLabelName, setNewLabelName] = useState<string>("");
  const [newLabelType, setNewLabelType] = useState<string>("");

  useEffect(() => {
    dispatch(fetchLabels());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  const handleAddLabel = async () => {
    if (!newLabelName || !newLabelType) {
      message.error("标签名称和类型不能为空");
      return;
    }

    try {
      await addLabel({ labelName: newLabelName, labelType: newLabelType });
      message.success("标签添加成功");
      setNewLabelName("");
      setNewLabelType("");
      dispatch(fetchLabels());
    } catch (err) {
      message.error("标签添加失败，请重试");
    }
  };

  const showDeleteConfirm = (labelId: string) => {
    confirm({
      title: "确认删除标签?",
      content: "删除操作将不可恢复，确定要删除此标签吗？",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        handleDeleteLabel(labelId);
      },
      onCancel() {
        console.log("取消删除");
      },
    });
  };

  const handleDeleteLabel = async (labelId: string) => {
    try {
      await deleteLabel(labelId);
      message.success("标签删除成功");
      dispatch(fetchLabels());
    } catch (err) {
      message.error("标签删除失败，请重试");
    }
  };

  // 获取所有唯一的label类型用于筛选器
  const labelTypes = Array.from(
    new Set(labels?.map((label) => label.label_type))
  );

  const columns: TableColumnType<LabelRowType>[] = [
    {
      title: "标签编号",
      dataIndex: "label_id",
      key: "label_id",
    },
    {
      title: "标签类型",
      dataIndex: "label_type",
      key: "label_type",
      sorter: (a, b) => a.label_type.localeCompare(b.label_type), // 按label_type排序
      filters: labelTypes.map((type) => ({ text: type, value: type })), // 筛选按钮
      onFilter: (value, record) => record.label_type === value,
    },
    {
      title: "标签名称",
      dataIndex: "label_name",
      key: "label_name",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => showDeleteConfirm(record.label_id.toString())}
        >
          删除
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Space direction="vertical">
        <Card>
          <Space style={{ marginBottom: 16 }}>
            <Input
              placeholder="标签名称"
              value={newLabelName}
              onChange={(e) => setNewLabelName(e.target.value)}
            />
            <Input
              placeholder="标签类型"
              value={newLabelType}
              onChange={(e) => setNewLabelType(e.target.value)}
            />
            <Button type="primary" onClick={handleAddLabel}>
              新增标签
            </Button>
          </Space>
        </Card>
        {loading ? (
          <Spin tip="Loading..." />
        ) : (
          <Card>
            <Table<LabelRowType>
              dataSource={labels || []}
              columns={columns}
              rowKey="label_id"
              size="small"
              pagination={{ pageSize: 100 }}
            />
          </Card>
        )}
      </Space>
    </div>
  );
};

export default LabelTable;
