import { Button, Card, message, Select } from "antd";
import { useEffect, useState } from "react";
import MultipleRoleSelector from "../components/selects/multiple-roles-selector";
import UserSelector from "../components/selects/user-selector";
import { getUserRoles, updateUserRoles } from "../services/api";

const { Option } = Select;

const UserAssetPage = () => {
    const [selectedUser, setSelectedUser] = useState<string | undefined>(
        undefined
    );
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const handleRoleSelectorChange = (selectedRoles: string[]) => {
        setUserRoles(selectedRoles); // 更新选中的角色
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedUser) {
                return;
            }
            const res = await getUserRoles(selectedUser);
            console.log(res);
            const role_ids_str = res.data.role_ids;
            if (!role_ids_str) {
                setUserRoles([]);
                return;
            }
            //role_ids_str e.g. 1,2|2,3
            const roles = role_ids_str
                .split("|")
                .map((role: string) => [parseInt(role.split(",")[0]), role]);
            setUserRoles(roles);
        };
        fetchData();
    }, [selectedUser]);

    const handleUserChange = async (value: string) => {
        setSelectedUser(value);
        const res = await getUserRoles(value);
        const role_ids_str = res.data?.role_ids;
        if (!role_ids_str) {
            setUserRoles([]);
            return;
        }
        const roles = role_ids_str
            .split("|")
            .map((role: string) => [parseInt(role.split(",")[0]), role]);
        setUserRoles(roles);
    };

    const handleConfirmAuthorization = async () => {
        if (!selectedUser) {
            return;
        }
        try {
            await updateUserRoles(selectedUser, userRoles);
            console.log(userRoles);
            console.log("Authorization confirmed for user:", selectedUser);
            message.success("success");
        } catch (error) {
            message.error("failure");
            console.error("Failed to update user roles:", error);
        }
    };

    return (
        <div>
            <Card>
                <UserSelector
                    value={selectedUser}
                    onChange={handleUserChange}
                />
            </Card>
            <Card>
                <MultipleRoleSelector
                    value={userRoles}
                    onChange={handleRoleSelectorChange}
                ></MultipleRoleSelector>
            </Card>
            <Card>
                <Button type="primary" onClick={handleConfirmAuthorization}>
                    确认授权
                </Button>
            </Card>
        </div>
    );
};

export default UserAssetPage;
