// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
    display: flex;
    flex-direction: column;
    height: 83vh; /* 占满整个视口 */
  }
  
  .chat-container .Chat {
    flex-grow: 1; /* 消息区域占据剩余空间 */
    display: flex;
    flex-direction: column;
  }
  
  .Chat .MessageContainer {
    flex-grow: 1;
    overflow-y: auto; /* 让消息区域可以滚动 */
  }
  
  .Chat .Composer {
    flex-shrink: 0; /* 输入区域固定在底部 */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/chatbot.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY,EAAE,WAAW;EAC3B;;EAEA;IACE,YAAY,EAAE,eAAe;IAC7B,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,gBAAgB,EAAE,cAAc;EAClC;;EAEA;IACE,cAAc,EAAE,cAAc;EAChC","sourcesContent":[".chat-container {\n    display: flex;\n    flex-direction: column;\n    height: 83vh; /* 占满整个视口 */\n  }\n  \n  .chat-container .Chat {\n    flex-grow: 1; /* 消息区域占据剩余空间 */\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .Chat .MessageContainer {\n    flex-grow: 1;\n    overflow-y: auto; /* 让消息区域可以滚动 */\n  }\n  \n  .Chat .Composer {\n    flex-shrink: 0; /* 输入区域固定在底部 */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
