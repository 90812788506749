import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchEduReport  } from "../thunks";

interface EduReportState {
    data: any[];
    loading: boolean;
    error: string | null;
}

const initialState: EduReportState = {
    data: [],
    loading: false,
    error: null,
};

export const EduReportSlice = createSlice({
    name: "eduReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEduReport.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                fetchEduReport.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loading = false;
                    state.data = action.payload;
                    state.error = null;
                }
            )
            .addCase(fetchEduReport.rejected, (state, action) => {
                state.loading = false;
                console.error("Fetch failed:", action.error);
                state.error = action.error.message || "Something went wrong";
            });
    },
});

export type EduReportSliceType = ReturnType<
    typeof EduReportSlice.reducer
>;
export default EduReportSlice.reducer;
