import type { CascaderProps } from "antd";
import { Cascader } from "antd";
import { useEffect, useState } from "react";
import { getRolesDataForSelect } from "../../services/api";

// 定义 RoleOption 类型
interface RoleOption {
    title: string;
    value: string | number;
    has_access: boolean;
    children?: RoleOption[];
    disabled?: boolean;
}

const RoleSelector: React.FC<CascaderProps> = ({ value, onChange }) => {
    const [options, setOptions] = useState<RoleOption[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getRolesDataForSelect();
                // 对数据进行处理，禁用 has_access 为 false 的节点
                const processedOptions = processOptions(res.data);
                setOptions(processedOptions);
            } catch (error) {
                console.error("Failed to fetch roles data", error);
            }
        };
        fetchData();
    }, []);

    // 递归处理 options，设置 has_access 为 false 的节点为 disabled
    const processOptions = (data: RoleOption[]): RoleOption[] => {
        return data.map((item) => ({
            ...item,
            disabled: item.has_access === false, // 如果 has_access 为 false，设置为不可选
            children: item.children ? processOptions(item.children) : [], // 递归处理子节点
        }));
    };

    return (
        <div>
            <span>角色选择：</span>
            <Cascader
                value={value}
                options={options}
                onChange={onChange}
                placeholder="请选择角色"
                showSearch
                style={{ width: "300px" }}
            />
        </div>
    );
};

export default RoleSelector;
