import { Button, Card, Form, Input, Modal, Spin, Tree } from "antd";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { exportForAlgo } from "../../services/api";
import { TreeNode } from "../theme-role-tree";

interface ExportModalProps {
    visible: boolean;
    onClose: () => void;
}

const ExportModal: React.FC<ExportModalProps> = ({ visible, onClose }) => {
    const treeData = useSelector(
        (state: RootState) => state.roleTree.data as TreeNode[]
    );
    const loading = useSelector((state: RootState) => state.roleTree.loading);
    const [searchValue, setSearchValue] = useState<string>("");
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [roleNamesStr, setRoleNamesStr] = useState<string>("");
    const [exporting, setExporting] = useState<boolean>(false);

    // 点击导出时触发的函数
    const handleExport = async () => {
        setExporting(true);
        // 在这里实现导出的逻辑
        const role_name_list =
            roleNamesStr
                .split("\n")
                .map((name) => name.trim())
                .filter((name) => name) || [];
        if (checkedKeys.length === 0 && role_name_list.length === 0) {
            Modal.warning({
                title: "警告",
                content: "请选择主题和角色，并输入要导出的角色列表。",
            });
            setExporting(false);
            return;
        }

        const roleNameSet = new Set(role_name_list);
        if (roleNameSet.size !== role_name_list.length) {
            Modal.error({
                title: "错误",
                content: "角色列表中存在重复项，请检查并重新输入。",
            });
            setExporting(false);
            return;
        }

        const exportData = {
            theme_roles: checkedKeys,
            role_names: role_name_list,
        };
        const res = await exportForAlgo(exportData);
        if (res.error) {
            console.error("导出失败:", res.error);
            Modal.error({
                title: "错误",
                content: `导出失败，请重试。错误信息: ${res.error}`,
            });
        } else {
            onClose();
        }
        setExporting(false);
    };

    // 更新选中的主题和角色
    const onCheck = (checkedKeysValue: any) => {
        setCheckedKeys(checkedKeysValue);
    };

    // 更新展开的树节点
    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
    };

    // 处理输入框变化
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRoleNamesStr(e.target.value);
        console.log(roleNamesStr);
    };

    // 处理搜索框变化
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    // 过滤树节点数据
    const filteredData = useMemo(() => {
        const filterTree = (nodes: TreeNode[], search: string): TreeNode[] => {
            if (!Array.isArray(nodes)) {
                return [];
            }
            return nodes
                .map((node) => {
                    const children = node.children
                        ? filterTree(node.children, search)
                        : [];
                    if (
                        node.title
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                        children.length
                    ) {
                        return {
                            ...node,
                            children,
                        };
                    }
                    return null;
                })
                .filter((node) => node !== null) as TreeNode[];
        };
        return filterTree(treeData, searchValue);
    }, [treeData, searchValue]);

    return (
        <Modal
            title="导出语料"
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose} disabled={exporting}>
                    取消
                </Button>,
                <Button
                    key="export"
                    type="primary"
                    onClick={handleExport}
                    disabled={exporting}
                >
                    {exporting ? <Spin /> : "导出"}
                </Button>,
            ]}
            width={800} // 设置Modal的宽度
        >
            <Form layout="vertical">
                <div>
                    <label>输入要导出的角色列表</label>
                    <Form.Item>
                        <Input.TextArea
                            value={roleNamesStr} // 绑定文本域的值到 roles 状态
                            onChange={handleInputChange} // 更新 roles 状态
                            rows={4}
                            disabled={exporting}
                        />
                    </Form.Item>
                </div>
            </Form>
            <div>
                <label>选择要导出的主题和角色</label>
                <Form.Item>
                    <Card style={{ backgroundColor: "#f5f5f5" }}>
                        {loading ? (
                            <Spin />
                        ) : (
                            <div>
                                <Input
                                    placeholder="搜索..."
                                    value={searchValue} // 搜索框的值
                                    onChange={(e) =>
                                        setSearchValue(e.target.value)
                                    }
                                    style={{ width: 200 }} // 设置搜索框的宽度
                                    disabled={exporting}
                                />
                                <Tree
                                    checkable
                                    onCheck={onCheck} // 选中树节点时的回调
                                    checkedKeys={checkedKeys} // 当前选中的节点
                                    treeData={filteredData} // 过滤后的树数据
                                    expandedKeys={expandedKeys} // 当前展开的树节点
                                    onExpand={onExpand} // 展开节点时的回调
                                    style={{ backgroundColor: "#f5f5f5" }}
                                    disabled={exporting}
                                    titleRender={(nodeData) => (
                                        <span style={{ userSelect: "text" }}>
                                            {nodeData.title}
                                        </span>
                                    )}
                                />
                            </div>
                        )}
                    </Card>
                </Form.Item>
            </div>
        </Modal>
    );
};

export default ExportModal;
