import { createSlice } from '@reduxjs/toolkit';
import { fetchRolesTree } from '../thunks';

interface RoleTreeState {
    data: Record<string, any>;
    loading: boolean;
    error: string | null;
}

const initialState: RoleTreeState = {
    data: {},
    loading: false,
    error: null,
};

export const RoleTreeSlice = createSlice({
    name: 'roleTree',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRolesTree.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchRolesTree.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                state.error = null;
            })
            .addCase(fetchRolesTree.rejected, (state, action) => {
                state.loading = false;
                console.error('Fetch failed:', action.error);
                state.error = action.error.message || 'Something went wrong';
            })
    }
})

export type RoleTreeStateType = ReturnType<typeof RoleTreeSlice.reducer>;
export default RoleTreeSlice.reducer