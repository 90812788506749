import { createSlice } from '@reduxjs/toolkit';
import { fetchTopicInfoByTopicId } from '../thunks';

interface TopicInfo {
    id: bigint;
    name: string | null;
    topic_type: number | null;
    theme_id: bigint;
    role_id: bigint | null;
    prompt: string | null;
    label: string | null;
    is_valid: number | null;
    ai_generated_content: string | null;
    human_editing_content: string | null;
    published_content: string | null;
    processing_status: number | null;
    status: number;
}

interface TableInfoState {
    data: TopicInfo | null;
    loading: boolean;
    error: string | null;
}

const initialState: TableInfoState = {
    data: null,
    loading: false,
    error: null,
};

export const TopicInfoSlice = createSlice({
    name: 'topicInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTopicInfoByTopicId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTopicInfoByTopicId.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchTopicInfoByTopicId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
})

export type TopicInfoSliceType = ReturnType<typeof TopicInfoSlice.reducer>;
export default TopicInfoSlice.reducer