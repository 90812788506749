import SERVICE_URL_PREFIX from "../constants/global";

interface FetchOptions {
    method: "GET" | "POST" | "PUT" | "DELETE";
    body?: any;
    headers?: Record<string, string>;
    credentials?: RequestCredentials;
}

const fetchWrapper = async (url: string, options: FetchOptions) => {
    try {
        const response = await fetch(url, {
            method: options.method,
            headers: {
                "Content-Type": "application/json",
                ...options.headers,
            },
            credentials: options.credentials || "include",
            body: options.body ? JSON.stringify(options.body) : undefined,
        });

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(errorDetails.message || "Unknown error occurred");
        }

        const details = await response.json();
        return { data: details.data }; // 确保返回的结构一致
    } catch (error) {
        if (error instanceof Error) {
            console.error(
                `Failed to ${options.method} request to ${url}:`,
                error.message
            );
            return { error: error.message };
        } else {
            console.error(
                `An unexpected error occurred with ${options.method} request to ${url}:`,
                error
            );
            return { error: "An unexpected error occurred" };
        }
    }
};

const fetchWrapperWithToken = async (url: string, options: FetchOptions) => {
    const accessToken = localStorage.getItem("access_token");
    try {
        const response = await fetch(url, {
            method: options.method,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                ...options.headers,
            },
            credentials: options.credentials || "include",
            body: options.body ? JSON.stringify(options.body) : undefined,
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.href = "/login";
                return { error: "Unauthorized" };
            }
            const errorDetails = await response.json();
            throw new Error(errorDetails.message || "Unknown error occurred");
        }

        const details = await response.json();
        return { data: details.data }; // 确保返回的结构一致
    } catch (error) {
        if (error instanceof Error) {
            console.error(
                `Failed to ${options.method} request to ${url}:`,
                error.message
            );
            return { error: error.message };
        } else {
            console.error(
                `An unexpected error occurred with ${options.method} request to ${url}:`,
                error
            );
            return { error: "An unexpected error occurred" };
        }
    }
};

const downloadWrapper = async (
    url: string,
    options: FetchOptions,
    filename?: string // 默认不提供文件名
) => {
    try {
        const response = await fetch(url, {
            method: options.method,
            headers: {
                "Content-Type": "application/json",
                ...options.headers,
            },
            credentials: options.credentials || "include",
            body: options.body ? JSON.stringify(options.body) : undefined,
        });

        if (!response.ok) {
            // 如果响应状态码不是 200，尝试获取错误信息
            const errorDetails = await response.json();
            throw new Error(errorDetails.message || "Unknown error occurred");
        }

        // 获取响应的 Content-Type，用于判断文件类型
        const contentType = response.headers.get("Content-Type");
        let suggestedFilename = filename;

        // 根据 Content-Type 设置默认文件名
        if (!suggestedFilename) {
            if (contentType === "application/zip") {
                suggestedFilename = "download.zip";
            } else if (contentType === "text/csv") {
                suggestedFilename = "download.csv";
            } else {
                suggestedFilename = "download.file"; // 默认文件名，如果类型未知
            }
        }

        // 将响应转换为 Blob 对象
        const blob = await response.blob();

        // 创建一个临时 URL 对象用于文件下载
        const urlObject = window.URL.createObjectURL(blob);

        // 创建一个 <a> 标签，并设置 href 属性为 Blob URL
        const link = document.createElement("a");
        link.href = urlObject;

        // 设置文件的下载名称
        link.setAttribute("download", suggestedFilename);

        // 将标签添加到文档中，并触发点击下载文件
        document.body.appendChild(link);
        link.click();

        // 移除 <a> 标签，清理 DOM
        document.body.removeChild(link);

        // 释放 Blob URL
        window.URL.revokeObjectURL(urlObject);

        return { success: true };
    } catch (error) {
        if (error instanceof Error) {
            console.error(
                `Failed to ${options.method} request to ${url}:`,
                error.message
            );
            return { error: error.message };
        } else {
            console.error(
                `An unexpected error occurred with ${options.method} request to ${url}:`,
                error
            );
            return { error: "An unexpected error occurred" };
        }
    }
};

// 登录用户
export const loginUser = async (username: string, password: string) => {
    const response = await fetch(`${SERVICE_URL_PREFIX}/login`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const body = await response.json();
    if (body.access_token) {
        // 保存JWT到localStorage或其他存储
        localStorage.setItem("access_token", body.access_token);
        return body;
    } else {
        throw new Error("Login failed: " + body.message);
    }
};

// 登出用户
export const logoutUser = async () => {
    const accessToken = localStorage.getItem("access_token");
    const response = await fetch(`${SERVICE_URL_PREFIX}/logout`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // 清除localStorage中的JWT
    localStorage.removeItem("access_token");
    return await response.json();
};

// 获取当前用户
export const getCurrentUser = async () => {
    const accessToken = localStorage.getItem("access_token");
    const response = await fetch(`${SERVICE_URL_PREFIX}/current_user`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    if (!response.ok) {
        if (response.status === 401) {
            window.location.href = "/login";
        }
        throw new Error("Network response was not ok");
    }

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const body = await response.json();
    return body;
};

// 获取主题信息
export const fetchThemeInfoByThemeId = async (themeId: string) => {
    try {
        const response = await fetch(
            `${SERVICE_URL_PREFIX}/themes/${themeId}`,
            {
                credentials: "include",
            }
        );
        const details = await response.json();
        return details.data;
    } catch (error) {
        if (error instanceof Error) {
            // 如果 error 是 Error 类型，可以安全地访问其 message 属性
            console.error("Failed to fetch theme info:", error.message);
            return { error: error.message };
        } else {
            // 其他类型的错误
            console.error("An unexpected error occurred:", error);
            return { error: "An unexpected error occurred" };
        }
    }
};

// 创建主题信息
export const createTheme = async (themeData: { name: string }) => {
    return await fetchWrapperWithToken(`${SERVICE_URL_PREFIX}/themes`, {
        method: "POST",
        body: themeData,
    });
};

// 修改主题信息
export const updateTheme = async (
    themeId: string,
    themeData: { name: string }
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}`,
        {
            method: "PUT",
            body: themeData,
        }
    );
};

// 删除主题信息
export const deleteTheme = async (themeId: string) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}`,
        {
            method: "DELETE",
        }
    );
};

// 创建角色信息
export const createRole = async (
    theme_id: string,
    roleData: { name: string }
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${theme_id}/roles`,
        {
            method: "POST",
            body: roleData,
        }
    );
};

// 修改角色信息
export const updateRole = async (
    themeId: string,
    roleId: string,
    roleData: { name: string }
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}`,
        {
            method: "PUT",
            body: roleData,
        }
    );
};
//获取
export const fetchRoleInfo = async (themeId: string, roleId: string) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}`,
        {
            method: "GET",
        }
    );
};

// 删除角色信息
export const deleteRole = async (themeId: string, roleId: string) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}`,
        {
            method: "DELETE",
        }
    );
};

// 创建话题信息
export const createTopic = async (
    theme_id: string,
    role_id: string,
    topicData: { name: string; prompt: string; label: string }
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${theme_id}/roles/${role_id}/topics`,
        {
            method: "POST",
            body: topicData,
        }
    );
};

// 批量创建话题信息
export const createBatchTopics = async (
    theme_id: string,
    role_id: string,
    topicsData: { topics: string; label: string }
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${theme_id}/roles/${role_id}/topics/batch`,
        {
            method: "POST",
            body: topicsData,
        }
    );
};

// 修改角色信息
export const updateTopic = async (
    themeId: string,
    roleId: string,
    topicId: string,
    topicData: { name: string; prompt: string; label: string }
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/${topicId}`,
        {
            method: "PUT",
            body: topicData,
        }
    );
};

// 删除角色信息
export const deleteTopic = async (
    themeId: string,
    roleId: string,
    topicId: string
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/${topicId}`,
        {
            method: "DELETE",
        }
    );
};

// 保存人类编辑话题内容
export const saveTopicHumanEditingContent = async (
    themeId: string,
    roleId: string,
    topicId: string,
    topicData: { human_editing_content: string }
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/${topicId}/save`,
        {
            method: "PUT",
            body: topicData,
        }
    );
};

// 检查人类编辑话题内容
export const checkTopicHumanEditingContent = async (
    themeId: string,
    roleId: string,
    topicId: string,
    topicData: { human_editing_content: string }
) => {
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/${topicId}/check`,
        {
            method: "PUT",
            body: topicData,
        }
    );
};

// 发布人类编辑话题内容
export const publishTopicHumanEditingContent = async (
    themeId: string,
    roleId: string,
    topicId: string,
    topicData: { human_editing_content: string }
) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/${topicId}/publish`,
        {
            method: "PUT",
            body: topicData,
        }
    );
};

export const fetchAllSlimTopicInfoByRole = async (
    themeId: string,
    roleId: string
) => {
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/slim`,
        {
            method: "GET",
        }
    );
};

export const fetchAllAISources = async () => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/ai/sources`, {
        method: "GET",
    });
};

// 创建生成任务信息
export const createGenerateTask = async (taskData: {
    topics: string[];
    model_id: string;
    temperature: number;
    number_of_rounds:number;
    sys_prompt: string;
    forward_prompt: string;
}) => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/ai/task/start`, {
        method: "POST",
        body: taskData,
    });
};

// 创建生成任务信息
export const peekGenerateTask = async (taskId: string) => {
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/ai/task/${taskId}/status`,
        {
            method: "GET",
        }
    );
};

// 停止任务信息
export const stopGenerateTask = async (taskId: string) => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/ai/task/${taskId}/stop`, {
        method: "POST",
    });
};

export const fetchTopicTableFilterTopicName = async (
    themeId: string,
    roleId: string
) => {
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/filters/topic_name`,
        {
            method: "GET",
        }
    );
};

export const fetchTopicTableFilterLabel = async (
    themeId: string,
    roleId: string
) => {
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/filters/label`,
        {
            method: "GET",
        }
    );
};

export const fetchTopicTableFilterProcessStatus = async (
    themeId: string,
    roleId: string
) => {
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/themes/${themeId}/roles/${roleId}/topics/filters/process_status`,
        {
            method: "GET",
        }
    );
};

export const addLabel = async (labelData: {
    labelName: string;
    labelType: string;
}) => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/labels`, {
        method: "POST",
        body: labelData,
    });
};

export const deleteLabel = async (labelId: string) => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/labels/${labelId}`, {
        method: "DELETE",
    });
};

export const getLabelsDataForSelect = async () => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/labels`, {
        method: "GET",
    });
};

export const getRolesDataForSelect = async () => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/roles/tree/label-selector`,
        {
            method: "GET",
        }
    );
};

export const getLabelTypes = async () => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/labels`, {
        method: "GET",
    });
};

export const addTopicLabel = async (topicLabelData: {
    topic_ids: string[];
    label_ids: string[];
}) => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/labels/topics`, {
        method: "POST",
        body: topicLabelData,
    });
};

export const deleteTopicLabel = async (
    labelIds: string[],
    topicIds: string[]
) => {
    console.log(topicIds);
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/labels/topics`, {
        method: "DELETE",
        body: { label_ids: labelIds, topic_ids: topicIds },
    });
};

export const exportDataByTopics = async (requestData: {
    topic_ids: string[];
    groupby_role: boolean;
}) => {
    return await downloadWrapper(
        `${SERVICE_URL_PREFIX}/export/topics/by_topic`,
        {
            method: "POST",
            body: requestData,
        }
    );
};

export const exportRoles = async (requestData: { role_ids: string[] }) => {
    return await downloadWrapper(`${SERVICE_URL_PREFIX}/export/roles`, {
        method: "POST",
        body: requestData,
    });
};

export const exportForAlgo = async (requestData: {
    theme_roles: string[];
    role_names: string[];
}) => {
    return await downloadWrapper(`${SERVICE_URL_PREFIX}/export/topics/algo`, {
        method: "POST",
        body: requestData,
    });
};

export const getUserTypes = async () => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/users/user_types`, {
        method: "GET",
    });
};

export const createUser = async (userData: {
    name: string;
    nickname: string;
    type: number;
    password: string;
}) => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/users`, {
        method: "POST",
        body: userData,
    });
};

export const deleteUser = async (userId: string) => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/users/${userId}`, {
        method: "DELETE",
    });
};

export const updateUser = async (
    userId: string,
    userData: {
        type: number;
    }
) => {
    return await fetchWrapper(`${SERVICE_URL_PREFIX}/users/${userId}`, {
        method: "PUT",
        body: userData,
    });
};

export const getUsersDataForSelect = async (type?: number) => {
    const url = new URL(`${SERVICE_URL_PREFIX}/users`);
    if (type !== undefined) {
        url.searchParams.append("type", type.toString());
    }
    return await fetchWrapper(url.toString(), {
        method: "GET",
    });
};

export const getUserRoles = async (userId: string) => {
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/users/${userId}/user_roles`,
        {
            method: "GET",
        }
    );
};

export const fetchTopicVersionInfo = async (
    theme_id: string,
    role_id: string,
    topic_id: string
) => {
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/themes/${theme_id}/roles/${role_id}/topics/${topic_id}/version`,
        {
            method: "GET",
        }
    );
};

export const fetchRoleAccess = async (theme_id: string, role_id: string) => {
    return await fetchWrapperWithToken(
        `${SERVICE_URL_PREFIX}/themes/${theme_id}/roles/${role_id}/has_access`,
        {
            method: "GET",
        }
    );
};

export const updateUserRoles = async (userId: string, roleIds: string[]) => {
    // 取出子列表第二个元素重组列表
    const newRoleIds = roleIds.map((roleId) => roleId[1]);
    console.log(newRoleIds);
    return await fetchWrapper(
        `${SERVICE_URL_PREFIX}/users/${userId}/user_roles`,
        {
            method: "PUT",
            body: { role_ids: newRoleIds.join("|") },
        }
    );
};

export const streamingChat = async (chatData: {
    chat_history: string[];
    source: string;
    sys_prompt: string;
    temperature: number;
}) => {
    try {
        const response = await fetch(`${SERVICE_URL_PREFIX}/streaming-chat`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(chatData),
        });

        if (!response.ok) {
            throw new Error("Failed to fetch GPT response");
        }

        return response.body; // 返回流式数据的主体
    } catch (error) {
        console.error("Error during streaming request:", error);
        throw error; // 抛出错误，以便前端捕获
    }
};
