// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.embossed-column {
    background-color: #f7f8fa;
}

.embossed-column .ant-table-cell {
    background-color: #f7f8fa;
}

.hidden-column {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/roles-of-theme-table.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".embossed-column {\n    background-color: #f7f8fa;\n}\n\n.embossed-column .ant-table-cell {\n    background-color: #f7f8fa;\n}\n\n.hidden-column {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
