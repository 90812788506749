// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
    display: grid;
    grid-template-columns: 1fr 4fr;
    /* 左侧1/3宽度，右侧2/3宽度 */
    height: 90vh;
    /* 占满整个视口高度 */
    gap: 10px;
    /* 左右内容之间的间距 */
    background-color: #f0f2f5;
    /* 背景颜色 */
}

.tree-container,
.table-container {
    background-color: #fff;
    /* 内容背景颜色 */
    border-radius: 8px;
    /* 圆角边框 */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* 阴影效果 */
}`, "",{"version":3,"sources":["webpack://./src/pages/resource-page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,YAAY;IACZ,aAAa;IACb,SAAS;IACT,cAAc;IACd,yBAAyB;IACzB,SAAS;AACb;;AAEA;;IAEI,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,wCAAwC;IACxC,SAAS;AACb","sourcesContent":[".grid-container {\n    display: grid;\n    grid-template-columns: 1fr 4fr;\n    /* 左侧1/3宽度，右侧2/3宽度 */\n    height: 90vh;\n    /* 占满整个视口高度 */\n    gap: 10px;\n    /* 左右内容之间的间距 */\n    background-color: #f0f2f5;\n    /* 背景颜色 */\n}\n\n.tree-container,\n.table-container {\n    background-color: #fff;\n    /* 内容背景颜色 */\n    border-radius: 8px;\n    /* 圆角边框 */\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    /* 阴影效果 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
