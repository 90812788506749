import LabelTable from "../components/label-table";

const LabelPage = () => {

    return (
        <div>
            <LabelTable></LabelTable>
        </div>
    )
}

export default LabelPage;