import devConfig from './config.dev';
import prodConfig from './config.prod';
import testConfig from './config.test';

const env = process.env.REACT_APP_ENV || 'dev';

const getConfig = () => {
    console.log("当前环境:" + env)
    switch (env) {
        case 'production':
            return prodConfig;
        case 'test':
            return testConfig;
        case 'dev':
            return devConfig;
        default:
            return devConfig;
    }
};

export default getConfig;