import { OpenAIOutlined, PlusOutlined } from "@ant-design/icons"; // 导入加号图标
import {
    Button,
    Card,
    Form,
    Input,
    message,
    Modal,
    Space,
    Tooltip,
} from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import GenerateCorpusModal from "../components/generate-corpus-modal";
import TopicsOfRoleTable from "../components/topics-of-role-table";
import { cleanTask, createTask } from "../redux/reducers/generate-task-reducer";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchTopicsByRoleId } from "../redux/thunks";
import {
    createBatchTopics,
    createGenerateTask,
    createTopic,
    deleteTopic,
    fetchAllSlimTopicInfoByRole,
    updateTopic,
} from "../services/api";

export interface slimTopic {
    key: string;
    topic_id: string;
    topic_name: string;
    role_id: string;
    theme_id: string;
    processing_status: string;
}

const TopicListOfRole: React.FC = () => {
    const dispatch = useAppDispatch();
    const { theme_id, role_id } = useParams<{
        theme_id: string;
        role_id: string;
    }>();
    const location = useLocation();
    const title = location.state?.title;
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [batchModalVisible, setBatchModalVisible] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<"create" | "edit">("create");
    const [currentTopic, setCurrentTopic] = useState<{
        topic_id?: string;
        topic_name: string;
    } | null>(null);
    const [form] = Form.useForm(); // 创建一个Form实例

    const [generationModalVisible, setGenerateModalVisible] =
        useState<boolean>(false);
    const [batchForm] = Form.useForm();

    const [slimTopics, setSlimTopics] = useState<slimTopic[] | null>(null);

    //获取当前分页信息
    const currentPage = useSelector(
        (state: RootState) => state.topicTable.currentPage
    );
    const pageSize = useSelector(
        (state: RootState) => state.topicTable.pageSize
    );
    if (!theme_id || !role_id) {
        return <div>Loading...</div>;
    }
    const handleAddTopic = () => {
        setModalMode("create");
        setCurrentTopic(null);
        form.resetFields(); // 重置表单字段
        setModalVisible(true);
    };

    const handleBatchAddTopic = () => {
        batchForm.resetFields(); // 重置表单字段
        setBatchModalVisible(true);
    };

    const handleGenerateModal = () => {
        setGenerateModalVisible(false);
    };

    const handleEditTopic = (record: {
        topic_id: string;
        topic_name: string;
        prompt: string;
        label: string;
    }) => {
        setModalMode("edit");
        setCurrentTopic(record); // 直接使用表格的记录
        form.setFieldsValue({
            id: record.topic_id,
            name: record.topic_name,
            prompt: record.prompt,
            label: record.label,
        }); // 设置表单字段的值
        setModalVisible(true);
    };

    const handleDeleteTopic = (record: {
        theme_id: string;
        role_id: string;
        topic_id: string;
        topic_name: string;
    }) => {
        if (!record) {
            return;
        }
        if (!theme_id) {
            return;
        }

        Modal.confirm({
            title: "确认删除",
            content: `你确定要删除话题 "${record.topic_name}" 吗？`,
            okText: "确认",
            okType: "danger",
            cancelText: "取消",
            async onOk() {
                try {
                    const result = await deleteTopic(
                        record.theme_id,
                        record.role_id,
                        record.topic_id
                    ); // 调用删除角色的 API
                    if (result.error) {
                        message.error(`删除话题失败: ${result.error}`);
                    } else {
                        message.success("删除话题成功");
                        dispatch(
                            fetchTopicsByRoleId({
                                themeId: record.theme_id,
                                roleId: record.role_id,
                                page: currentPage,
                                pageSize: pageSize,
                            })
                        ); // 重新获取主题下的角色
                    }
                } catch (error) {
                    message.error(
                        `发生错误: ${
                            error instanceof Error
                                ? error.message
                                : String(error)
                        }`
                    );
                }
            },
        });
    };

    const handleTopicFormSubmit = async (values: {
        name: string;
        prompt: string;
        label: string;
    }) => {
        if (!theme_id || !role_id) {
            return;
        }
        if (modalMode === "create") {
            try {
                const result = await createTopic(theme_id, role_id, values);
                if (result.error) {
                    message.error(`创建话题失败: ${result.error}`);
                } else {
                    message.success("创建话题成功");
                    dispatch(
                        fetchTopicsByRoleId({
                            themeId: theme_id,
                            roleId: role_id,
                            page: currentPage,
                            pageSize: pageSize,
                        })
                    );
                }
            } catch (error) {
                message.error("未知错误");
            } finally {
                setModalVisible(false);
            }
        } else if (modalMode === "edit" && currentTopic?.topic_id) {
            try {
                const result = await updateTopic(
                    theme_id,
                    role_id,
                    currentTopic.topic_id,
                    values
                ); // 修改角色
                if (result.error) {
                    message.error(`更新角色失败: ${result.error}`);
                } else {
                    message.success("更新角色成功");
                    dispatch(
                        fetchTopicsByRoleId({
                            themeId: theme_id,
                            roleId: role_id,
                            page: currentPage,
                            pageSize: pageSize,
                        })
                    ); // 刷新角色列表
                }
            } catch (error) {
                console.error("Error updating role:", error);
                message.error(
                    `发生错误: ${
                        error instanceof Error ? error.message : String(error)
                    }`
                );
            } finally {
                setModalVisible(false);
            }
        }
    };

    const handleBatchTopicFormSubmit = async (values: {
        topics: string;
        label: string;
    }) => {
        if (!theme_id || !role_id) {
            return;
        }
        try {
            const result = await createBatchTopics(theme_id, role_id, values);
            if (result.error) {
                message.error(`批量创建话题失败: ${result.error}`);
            } else {
                message.success("批量创建话题成功");
                dispatch(
                    fetchTopicsByRoleId({
                        themeId: theme_id,
                        roleId: role_id,
                        page: currentPage,
                        pageSize: pageSize,
                    })
                );
                setBatchModalVisible(false);
            }
        } catch (error) {
            message.error("未知错误");
        }
    };

    const handleGenerateCorpus = async () => {
        dispatch(cleanTask());
        setGenerateModalVisible(true);
        if (!theme_id || !role_id) {
            console.error("Invalid theme_id or role_id");
            return;
        }
        try {
            // 触发数据获取
            const response = await fetchAllSlimTopicInfoByRole(
                theme_id,
                role_id
            );

            if ("data" in response && response.data) {
                const modifiedData = response.data.map((topic: slimTopic) => ({
                    ...topic,
                    key: topic.topic_id,
                }));
                setSlimTopics(modifiedData);
                setGenerateModalVisible(true); // 成功获取数据后打开导出Modal
            } else if ("error" in response && response.error) {
                console.error(`Error fetching topics: ${response.error}`);
                message.error(`获取话题数据失败: ${response.error}`);
            }
        } catch (error) {
            console.error(`Error: ${error}`);
            message.error(
                `获取话题数据时发生错误: ${
                    error instanceof Error ? error.message : String(error)
                }`
            );
        }
    };
    const handleGenerate = async (values: {
        topics: string[];
        temperature: number;
        sys_prompt: string;
        number_of_rounds:number;
        forward_prompt: string;
        model_id: string;
    }) => {
        if (values.topics.length === 0) {
            message.warning("请选择要生成的话题，并点击穿梭按钮");
            return;
        }
        const res = await createGenerateTask(values);
        dispatch(createTask(res.data));
    };

    return (
        <Card
            size="small"
            style={{ height: "90vh", overflowY: "auto" }}
            title={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <span className="custom-card-title">
                        角色[{title}]の话题列表
                    </span>
                    <div>
                        <Space>
                            <Tooltip title="生成语料">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<OpenAIOutlined />}
                                    onClick={handleGenerateCorpus}
                                    size="small"
                                />
                            </Tooltip>
                            {/* <Tooltip title="创建话题">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    onClick={handleAddTopic}
                                    size="small"
                                />
                            </Tooltip> */}
                            <Tooltip title="批量创建话题">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    onClick={() => setBatchModalVisible(true)}
                                    size="small"
                                />
                            </Tooltip>
                        </Space>
                    </div>
                </div>
            }
        >
            <TopicsOfRoleTable
                theme_id={theme_id}
                role_id={role_id}
                onEdit={handleEditTopic}
                onDelete={handleDeleteTopic}
            ></TopicsOfRoleTable>
            <Modal
                visible={modalVisible}
                title={modalMode === "create" ? "创建话题" : "编辑话题"}
                onCancel={() => setModalVisible(false)}
                onOk={() => form.submit()}
                okText="保存"
                cancelText="取消"
            >
                <Form
                    form={form}
                    layout="horizontal"
                    onFinish={handleTopicFormSubmit} // 表单提交时调用的函数
                >
                    <Form.Item
                        label="话题名称"
                        name="name"
                        rules={[{ required: true, message: "请输入话题名称" }]}
                    >
                        <Input placeholder="输入话题名称" />
                    </Form.Item>
                    <Form.Item
                        label="话题prompt"
                        name="prompt"
                        rules={[
                            { required: true, message: "请输入话题prompt" },
                        ]}
                    >
                        <Input placeholder="输入话题prompt" />
                    </Form.Item>
                    <Form.Item
                        label="话题标签"
                        name="label"
                        rules={[{ required: true, message: "请输入话题标签" }]}
                    >
                        <Input placeholder="输入话题标签" />
                    </Form.Item>
                </Form>
            </Modal>
            {/* 批量新增话题modal */}
            <Modal
                visible={batchModalVisible}
                title={"批量新增话题"}
                onCancel={() => setBatchModalVisible(false)}
                onOk={() => batchForm.submit()}
                okText="保存"
                cancelText="取消"
            >
                <Form
                    form={batchForm}
                    layout="horizontal"
                    onFinish={handleBatchTopicFormSubmit} // 表单提交时调用的函数
                >
                    <Form.Item
                        label="话题列表"
                        name="topics"
                        rules={[{ required: true, message: "请输入话题列表" }]}
                    >
                        <Input.TextArea placeholder="输入话题列表，每行一个话题" />
                    </Form.Item>
                    <Form.Item
                        label="话题标签"
                        name="label"
                        rules={[{ required: true, message: "请输入话题标签" }]}
                    >
                        <Input placeholder="输入话题标签" />
                    </Form.Item>
                </Form>
            </Modal>
            <GenerateCorpusModal
                themeId={theme_id}
                roleId={role_id}
                visible={generationModalVisible}
                onCancel={handleGenerateModal}
                onOk={handleGenerate}
                topics={slimTopics || []}
                setTopics={setSlimTopics}
            />
        </Card>
    );
};

export default TopicListOfRole;
