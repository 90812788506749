import { Card, Divider } from "antd";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import ChatBot from "../components/chatbot";
import ModelSelector from "../components/selects/model-selector";
import TemperatureRadio from "../components/selects/temperature-radio";

const PromptTestPage: React.FC = () => {
    const [selectedValue, setSelectedValue] =
        useState<string>("CLAUDE-3.5-SONNET"); // 模型选择
    const [temperature, setTemperature] = useState<number>(0.7); // 温度
    const [systemPrompt, setSystemPrompt] = useState<string>(""); // 系统提示

    // 处理模型选择
    const handleSelect = (value: any) => {
        setSelectedValue(value);
    };

    // 处理温度值变化
    const handleTemperatureChange = (value: number) => {
        setTemperature(value);
    };

    // 处理系统提示变化
    const handleSystemPromptChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setSystemPrompt(event.target.value);
    };

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Card style={{ flex: 1, marginRight: "2px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <div>选择模型</div>
                        <ModelSelector onSelect={handleSelect} />
                    </div>
                    <div>
                        <div>Temperature</div>
                        <TemperatureRadio onChange={handleTemperatureChange} />
                    </div>
                </div>
                <Divider></Divider>
                <div style={{ height: "70vh" }}>
                    <label htmlFor="system-prompt">
                        <strong>System Prompt</strong> (试试输入markdown)
                    </label>
                    <textarea
                        id="system-prompt"
                        value={systemPrompt}
                        onChange={handleSystemPromptChange}
                        style={{ width: "100%", height: "50%" }}
                    />
                    <div
                        style={{
                            marginTop: "10px",
                            height: "50%",
                            overflowY: "auto",
                        }}
                    >
                        <ReactMarkdown>{systemPrompt}</ReactMarkdown>
                    </div>
                </div>
            </Card>
            <Card style={{ flex: 1, marginLeft: "2px" }}>
                <ChatBot
                    source={selectedValue}
                    temperature={temperature}
                    systemPrompt={systemPrompt}
                />
            </Card>
        </div>
    );
};

export default PromptTestPage;
