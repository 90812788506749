import { Card } from "antd";

const PromptPage: React.FC = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Card>
                <div style={{ flexShrink: 0, width: "100%", height: "100%" }}>
                    调试工具
                </div>
                <iframe
                    src="https://corpus.dev.kuaiman.com/prompt_test_new"
                    width="100%"
                    height="100%"
                    style={{
                        border: "none",
                        flexGrow: 1,
                        width: "100%",
                        height: "85vh",
                    }}
                />
            </Card>
        </div>
    );
};

export default PromptPage;
