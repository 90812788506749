import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EditorContentState {
    editorContent: string;
    drawerContent: string[];
    isDrawerShow: boolean;
    isAIEditorShow: boolean; // 新增 showAIEditor 状态
    isHistoryEditorShow: boolean; // 新增 showHistoryEditor 状态
}

const initialState: EditorContentState = {
    editorContent: "",
    drawerContent: [],
    isDrawerShow: false,
    isAIEditorShow: false, // 初始化 showAIEditor 状态
    isHistoryEditorShow: false, // 初始化 showHistoryEditor 状态
};

const editorContentSlice = createSlice({
    name: "editorContent",
    initialState,
    reducers: {
        setEditorContent: (state, action: PayloadAction<string>) => {
            state.editorContent = action.payload;
        },
        clearEditorContent: (state) => {
            state.editorContent = "";
        },
        setDrawerShow: (state) => {
            state.isDrawerShow = true;
        },
        setDrawerHide: (state) => {
            state.isDrawerShow = false;
        },
        setDrawerContent: (state, action: PayloadAction<string[]>) => {
            state.drawerContent = action.payload;
        },
        showAIEditor: (state) => {
            // 新增 showAIEditor reducer
            state.isAIEditorShow = true;
        },
        hideAIEditor: (state) => {
            // 新增 hideAIEditor reducer
            state.isAIEditorShow = false;
        },
        showHistoryEditor: (state) => {
            // 新增 showHistoryEditor reducer
            state.isHistoryEditorShow = true;
        },
        hideHistoryEditor: (state) => {
            // 新增 hideHistoryEditor reducer
            state.isHistoryEditorShow = false;
        },
        cleanDrawerContent: (state) => {
            // 新增 cleanDrawerContent reducer
            state.drawerContent = [];
        },
    },
});

export const {
    setEditorContent,
    clearEditorContent,
    setDrawerHide,
    setDrawerShow,
    setDrawerContent,
    showAIEditor, // 导出 showAIEditor action
    hideAIEditor, // 导出 hideAIEditor action
    showHistoryEditor, // 导出 showHistoryEditor action
    hideHistoryEditor, // 导出 hideHistoryEditor action
    cleanDrawerContent, // 导出 cleanDrawerContent action
} = editorContentSlice.actions;

export default editorContentSlice.reducer;
