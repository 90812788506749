import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchEduReport2  } from "../thunks";

interface EduReport2State {
    data: any[];
    loading: boolean;
    error: string | null;
}

const initialState: EduReport2State = {
    data: [],
    loading: false,
    error: null,
};

export const EduReport2Slice = createSlice({
    name: "eduReport2",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEduReport2.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                fetchEduReport2.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loading = false;
                    state.data = action.payload;
                    state.error = null;
                }
            )
            .addCase(fetchEduReport2.rejected, (state, action) => {
                state.loading = false;
                console.error("Fetch failed:", action.error);
                state.error = action.error.message || "Something went wrong";
            });
    },
});

export type EduReport2SliceType = ReturnType<
    typeof EduReport2Slice.reducer
>;
export default EduReport2Slice.reducer;
