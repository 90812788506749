import { PlusOutlined } from "@ant-design/icons"; // 导入加号图标
import {
    Button,
    Card,
    Form,
    Input,
    message,
    Modal,
    Select,
    Tooltip,
} from "antd";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import RolesOfThemeTable from "../components/roles-of-theme-table";
import { useAppDispatch } from "../redux/store";
import { fetchRolesSummaryByThemeId, fetchRolesTree } from "../redux/thunks";
import {
    createRole,
    deleteRole,
    fetchRoleAccess,
    fetchRoleInfo,
    updateRole,
} from "../services/api"; // 导入角色的 API 调用函数

const RoleListOfTheme: React.FC = () => {
    const { theme_id } = useParams<{ theme_id: string }>();
    const dispatch = useAppDispatch();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<"create" | "edit">("create");
    const [currentRole, setCurrentRole] = useState<{
        role_id?: string;
        role_name: string;
    } | null>(null); // 用于存储当前选中的角色
    const [form] = Form.useForm(); // 创建一个Form实例

    const location = useLocation();
    const title = location.state?.title;

    const handleAddRole = () => {
        setModalMode("create");
        setCurrentRole(null);
        form.resetFields(); // 重置表单字段
        setModalVisible(true);
    };

    const handleEditRole = async (record: {
        role_id: string;
        role_name: string;
    }) => {
        form.resetFields();
        setModalMode("edit");
        setCurrentRole(record);
        if (!theme_id) {
            return;
        }
        const res = await fetchRoleAccess(theme_id, record.role_id);
        if (res.error) {
            return message.error(res.error);
        }
        const roleInfo = await fetchRoleInfo(theme_id, record.role_id); // 获取角色信息
        if (roleInfo.error) {
            message.error(`获取角色信息失败: ${roleInfo.error}`);
            return;
        }
        const extraInfo = roleInfo.data.extra_info;
        if (!extraInfo) {
            form.setFieldsValue({ id: record.role_id, name: record.role_name }); // 设置表单字段的值
            setModalVisible(true);
            return;
        }
        form.setFieldsValue({
            id: record.role_id,
            name: record.role_name,
            ...extraInfo,
        }); // 设置表单字段的值
        setModalVisible(true);
    };

    const handleDeleteRole = async (record: {
        theme_id: string;
        role_id: string;
        role_name: string;
    }) => {
        if (!record) {
            return;
        }
        if (!theme_id) {
            return;
        }

        const res = await fetchRoleAccess(theme_id, record.role_id);
        if (res.error) {
            return message.error(res.error);
        }

        Modal.confirm({
            title: "确认删除",
            content: `你确定要删除 "${record.role_name}" 吗？删除的话该角色下绑定的所有话题都删光光了`,
            okText: "确认",
            okType: "danger",
            cancelText: "取消",
            async onOk() {
                try {
                    const result = await deleteRole(
                        record.theme_id,
                        record.role_id
                    ); // 调用删除角色的 API
                    if (result.error) {
                        message.error(`删除角色失败: ${result.error}`);
                    } else {
                        message.success("删除角色成功");
                        dispatch(fetchRolesSummaryByThemeId(theme_id)); // 重新获取主题下的角色
                        dispatch(fetchRolesTree());
                    }
                } catch (error) {
                    message.error(
                        `发生错误: ${
                            error instanceof Error
                                ? error.message
                                : String(error)
                        }`
                    );
                }
            },
        });
    };

    const handleRoleFormSubmit = async (values: {
        name: string;
        mbti: string;
        pre_dialogue: string;
        system_prompt: string;
        opening_statement: string;
        role_english_name: string;
        single_side_prompt: string;
        gender: string;
    }) => {
        if (!theme_id) {
            return;
        }
        if (modalMode === "create") {
            try {
                const result = await createRole(theme_id, values);
                if (result.error) {
                    message.error(`创建角色失败: ${result.error}`);
                } else {
                    message.success("创建角色成功");
                    dispatch(fetchRolesSummaryByThemeId(theme_id));
                }
            } catch (error) {
                message.error("未知错误");
            } finally {
                setModalVisible(false);
                dispatch(fetchRolesTree());
            }
        } else if (modalMode === "edit" && currentRole?.role_id) {
            try {
                const extra_info = {
                    mbti: values.mbti,
                    pre_dialogue: values.pre_dialogue,
                    system_prompt: values.system_prompt,
                    opening_statement: values.opening_statement,
                    role_english_name: values.role_english_name,
                    single_side_prompt: values.single_side_prompt,
                    gender: values.gender,
                };

                // 构造最终需要传递的数据
                const dataToSend = {
                    ...values, // 包含其他非 extra_info 的字段
                    extra_info, // 聚合后的 extra_info
                };
                const result = await updateRole(
                    theme_id,
                    currentRole.role_id,
                    dataToSend
                ); // 修改角色
                if (result.error) {
                    console.log(result.error);
                    message.error({
                        content: (
                            <>
                                更新角色失败:
                                <br />
                                <div style={{ textAlign: "left" }}>
                                    {result.error
                                        .split("\n")
                                        .map((line, index) => (
                                            <div key={index}>{line}</div>
                                        ))}
                                </div>
                            </>
                        ),
                        duration: 10, // 设置消息持续时间为10秒
                    });
                } else {
                    message.success("更新角色成功");
                    dispatch(fetchRolesSummaryByThemeId(theme_id)); // 刷新角色列表
                }
            } catch (error) {
                console.error("Error updating role:", error);
                message.error(
                    `发生错误: ${
                        error instanceof Error ? error.message : String(error)
                    }`
                );
            } finally {
                setModalVisible(false);
                dispatch(fetchRolesTree());
            }
        }
    };

    return (
        <Card
            size="small"
            style={{ height: "90vh" }}
            title={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <span className="custom-card-title">
                        主题[{title}]の角色列表
                    </span>
                    <div>
                        <Tooltip title="创建角色">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />} // 使用加号图标
                                onClick={handleAddRole}
                                size="small"
                            />
                        </Tooltip>
                    </div>
                </div>
            }
        >
            <div style={{ height: "79vh", overflowY: "auto" }}>
                <RolesOfThemeTable
                    theme_id={theme_id || "-1"}
                    onEdit={handleEditRole} // 传递编辑角色函数给表格
                    onDelete={handleDeleteRole} // 传递删除角色函数给表格
                ></RolesOfThemeTable>
            </div>
            {/* 角色创建/编辑 Modal */}
            <Modal
                visible={modalVisible}
                title={modalMode === "create" ? "创建角色" : "编辑角色"}
                onCancel={() => setModalVisible(false)}
                onOk={() => form.submit()}
                okText="保存"
                cancelText="取消"
            >
                <Form
                    form={form}
                    layout="horizontal"
                    labelCol={{ span: 6 }} // 控制标签宽度
                    wrapperCol={{ span: 18 }} // 控制输入框宽度
                    onFinish={handleRoleFormSubmit} // 表单提交时调用的函数
                >
                    <Form.Item
                        label="角色名称"
                        name="name"
                        rules={[{ required: true, message: "请输入角色名称" }]}
                    >
                        <Input placeholder="输入角色名称" />
                    </Form.Item>
                    {modalMode === "edit" && (
                        <>
                            <Form.Item
                                label="性别"
                                name="gender"
                                initialValue=""
                            >
                                <Select placeholder="选择性别">
                                    <Select.Option value="male">
                                        男
                                    </Select.Option>
                                    <Select.Option value="female">
                                        女
                                    </Select.Option>
                                    <Select.Option value="">无</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="MBTI"
                                name="mbti"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入MBTI信息",
                                    },
                                ]}
                            >
                                <Input placeholder="输入MBTI信息" />
                            </Form.Item>
                            <Form.Item
                                label="前置对话"
                                name="pre_dialogue"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入前置对话",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const roleName =
                                                getFieldValue("name");

                                            if (value) {
                                                // 获取第一次和第二次冒号的索引
                                                const firstColonIndex =
                                                    value.indexOf("：");
                                                const secondColonIndex =
                                                    value.indexOf(
                                                        "：",
                                                        firstColonIndex + 1
                                                    );

                                                // // 验证第二个冒号前的部分是否以 roleName 结尾
                                                // console.log(
                                                //     11,
                                                //     value,
                                                //     roleName,
                                                //     secondColonIndex,
                                                //     value.substring(
                                                //         0,
                                                //         secondColonIndex
                                                //     )
                                                // );

                                                if (
                                                    secondColonIndex !== -1 &&
                                                    !value
                                                        .substring(
                                                            0,
                                                            secondColonIndex
                                                        )
                                                        .endsWith(roleName)
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            `前置对话的第二个冒号之前的部分必须以 "${roleName}" 结尾`
                                                        )
                                                    );
                                                }
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.TextArea placeholder="输入前置对话" />
                            </Form.Item>
                            <Form.Item
                                label="System Prompt"
                                name="system_prompt"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入System Prompt",
                                    },
                                ]}
                            >
                                <Input placeholder="输入System Prompt" />
                            </Form.Item>
                            <Form.Item
                                label="开场白"
                                name="opening_statement"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入开场白",
                                    },
                                ]}
                            >
                                <Input placeholder="输入开场白" />
                            </Form.Item>
                            <Form.Item
                                label="角色英文名"
                                name="role_english_name"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入角色英文名",
                                    },
                                ]}
                            >
                                <Input placeholder="输入角色英文名" />
                            </Form.Item>
                            <Form.Item
                                label="单边Prompt"
                                name="single_side_prompt"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入单边Prompt",
                                    },
                                ]}
                            >
                                <Input placeholder="输入单边Prompt" />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>
        </Card>
    );
};

export default RoleListOfTheme;
