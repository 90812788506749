import { Layout, Typography } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import FrameHeader from '../components/frame-header';

const { Header, Footer, Sider, Content } = Layout
const { Text } = Typography;


const FramePage: React.FC = () => {
    return (
        <Layout style={{ minHeight: '90vh' }}>
            <FrameHeader />
            <Layout>
                {/* <Sider>
                    <SideBar />
                </Sider> */}
                <Layout>
                    <Content style={{ margin: '8px 8px 0', overflow: 'initial' }}>
                        <Outlet></Outlet>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default FramePage