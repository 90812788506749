import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAllAISources } from "../../services/api";

const { Option } = Select;

interface ModelSelectorProps {
    onSelect: (model: string) => void;
}

interface Model {
    id: string;
    name: string;
}
const ModelSelector: React.FC<ModelSelectorProps> = ({ onSelect }) => {
    const [models, setModels] = useState<Model[]>([]);
    const [loading, setLoading] = useState(true);

    const handleChange = (value: string) => {
        onSelect(value);
    };

    useEffect(() => {
        const fetchModels = async () => {
            try {
                const response = await fetchAllAISources();
                const modelData = response.data.map((source: Model) => ({
                    id: source.id,
                    name: source.name,
                }));
                setModels(modelData);
            } catch (error) {
                console.error("Failed to fetch models:", error);
            } finally {
                setLoading(false); // 请求完成，停止加载
            }
        };

        fetchModels();
    }, []);

    return (
        <div>
            <Select
                placeholder="选择模型"
                style={{ width: 180 }}
                loading={loading}
                onChange={handleChange}
                defaultValue="GLAUDE-3.5-SONNET"
            >
                {models.map((model) => (
                    <Option key={model.id} value={model.id}>
                        {model.name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default ModelSelector;
