import { Button, Card, Checkbox, message, Space, Table } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { RowSelectMethod } from "antd/es/table/interface";
import { Key, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelSelector from "../components/selects/label-selector";
import RoleSelector from "../components/selects/role-selector";
import { AppDispatch, RootState } from "../redux/store";
import { fetchTopicsForExport } from "../redux/thunks";
import { exportDataByTopics, getLabelTypes } from "../services/api";

const ExportTopicPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const data = useSelector(
        (state: RootState) => state.topicTableForExport.data as any[]
    );
    const loading = useSelector(
        (state: RootState) => state.topicTableForExport.loading
    );
    const [themeId, setThemeId] = useState<string>("");
    const [roleId, setRoleId] = useState<string>("");
    const [labelTypes, setLabelTypes] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1); // 当前页码
    const [pageSize, setPageSize] = useState(10); // 每页显示的条数
    const [filteredData, setFilteredData] = useState(data);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
    const [groupbyRole, setGroupbyRole] = useState<boolean>(false);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        const fetchLabelTypes = async () => {
            const res = await getLabelTypes();
            const labelTypes = res.data.reduce(
                (acc: string[], item: { label_type: string }) => {
                    if (!acc.includes(item.label_type)) {
                        acc.push(item.label_type);
                    }
                    return acc;
                },
                []
            );
            setLabelTypes(labelTypes);
        };
        fetchLabelTypes();
    }, []);

    const handleFilter = (
        filters: Record<string, (string | number | boolean)[] | null>
    ) => {
        let filtered = [...data];
        Object.keys(filters).forEach((filterKey) => {
            const selectedValues = filters[filterKey];
            if (selectedValues) {
                filtered = filtered.filter((item) =>
                    selectedValues.some((value) =>
                        item[filterKey]?.includes(value)
                    )
                );
            }
        });
        setFilteredData(filtered);
    };

    const baseColumns = [
        {
            title: "主题名",
            dataIndex: "theme_name",
            key: "themeName",
        },
        {
            title: "角色名",
            dataIndex: "role_name",
            key: "roleName",
        },
        {
            title: "话题编号",
            dataIndex: "topic_id",
            key: "key",
        },
        {
            title: "话题名",
            dataIndex: "topic_name",
            key: "topicName",
        },
    ];

    const dynamicLabelColumns = labelTypes.map((labelType) => {
        const filterValues = [
            ...new Set(data.map((item) => item[labelType]).filter(Boolean)),
        ];
        return {
            title: labelType,
            dataIndex: labelType,
            key: labelType,
            filters: filterValues.map((item) => ({
                text: item,
                value: item,
            })),
            onFilter: (value: any, record: any) => {
                // 确保返回布尔值
                return record[labelType]?.includes(String(value));
            },
        };
    });

    const columns = [...baseColumns, ...dynamicLabelColumns];

    const onSelectChange = (
        newSelectedRowKeys: Key[],
        selectedRows: any[],
        info: { type: RowSelectMethod }
    ) => {
        console.log(newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handleLabelSelectorChange = (values: string[]) => {
        setSelectedLabels(values);
    };

    const handleRoleSelectorChange = (
        values: (string | number | null)[],
        selectedOption: DefaultOptionType[]
    ) => {
        if (values && values.length > 0) {
            const pair = values[1] as string;
            const [themeId, roleId] = pair.split(",");
            setThemeId(themeId);
            setRoleId(roleId);
        }
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
        handleFilter(filters); // 调用 handleFilter 函数进行筛选
    };

    const handleQueryClick = () => {
        if (!themeId && !roleId && !selectedLabels) {
            return;
        }
        dispatch(
            fetchTopicsForExport({
                themeId,
                roleId,
                labels: selectedLabels,
            })
        );
    };

    const handleExportShownClick = async () => {
        try {
            const topicIds = filteredData.map((item) => item.topic_id);
            await exportDataByTopics({
                topic_ids: topicIds,
                groupby_role: groupbyRole,
            });
            message.success("导出成功");
        } catch (error) {
            message.error("导出失败，请重试");
            console.error("Export error:", error);
        }
    };

    const handleExportSelectedClick = async () => {
        try {
            const selectedTopics = data.filter((item) =>
                selectedRowKeys.includes(item.topic_id)
            );
            const res = await exportDataByTopics({
                topic_ids: selectedTopics.map((item) => item.topic_id),
                groupby_role: true,
            });
            if (res.error) {
                throw new Error(res.error);
            }
            message.success("导出成功");
        } catch (error) {
            message.error("导出失败，请重试");
            console.error("Export error:", error);
        }
    };

    const handleCheckBoxOnChange = (e: any) => {
        setGroupbyRole(e.target.checked);
    };

    return (
        <div>
            <Card style={{ height: "28vh" }}>
                <Space direction="vertical">
                    <RoleSelector onChange={handleRoleSelectorChange} />
                    <LabelSelector
                        value={selectedLabels}
                        onChange={handleLabelSelectorChange}
                    />
                    <Checkbox
                        value={groupbyRole}
                        onChange={handleCheckBoxOnChange}
                    >
                        导出时是否按用户粒度切分
                    </Checkbox>
                    <div>
                        <Space>
                            <Button type="primary" onClick={handleQueryClick}>
                                查询
                            </Button>
                            <Button onClick={handleExportSelectedClick}>
                                选中话题导出
                            </Button>
                            <Button onClick={handleExportShownClick}>
                                筛出话题导出
                            </Button>
                        </Space>
                    </div>
                </Space>
            </Card>
            <div style={{ height: "2vh" }}></div>
            <Card style={{ height: "60vh" }}>
                <Table
                    rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange,
                    }}
                    dataSource={filteredData}
                    columns={columns}
                    loading={loading}
                    rowKey="topic_id"
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: filteredData.length,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20", "50"],
                    }}
                    onChange={handleTableChange}
                    size="small"
                />
            </Card>
        </div>
    );
};

export default ExportTopicPage;
