import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface GenerateTaskState{
    taskId: string|null;
    status: 'idle' |'in_progress'| 'success' | 'failure';
    progress: number;
    errorMessage: string | null;
}



const initialState: GenerateTaskState = {taskId:null,status:'idle',progress:0,errorMessage:null};

const generateTaskStatesSlice = createSlice({
    name: 'generateTaskState',
    initialState,
    reducers: {
      createTask: (state, action) => {
        state.taskId = action.payload.task_id;
        state.status = 'in_progress';
        state.progress = 0;
        state.errorMessage = null;
      },
      cleanTask:(state)=>{
        state={taskId:null,status:'idle',progress:0,errorMessage:null}
      },
      peekTask: (
        state,
        action: PayloadAction<{
          status: 'in_progress' | 'success' | 'failure';
          progress?: number;
          errorMessage?: string;
        }>
      ) => {
        state.status = action.payload.status;
        if (action.payload.status === 'in_progress') {
          state.progress = action.payload.progress ?? state.progress;
        } else if (action.payload.status === 'success') {
          state.progress = 100;
        } else if (action.payload.status === 'failure') {
          state.errorMessage = action.payload.errorMessage ?? 'An unknown error occurred';
        }
      },
    },
  });
  
  export const { createTask, peekTask,cleanTask } = generateTaskStatesSlice.actions;

export default generateTaskStatesSlice.reducer;


