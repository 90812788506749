
const getColumns = (year: number) => {
    const columns = [
        {
            title: `${year}(招生计划)`,
            children: [
                { title: "序号", dataIndex: "index", key: "index", render: (text: any, record: any, index: number) => index + 1 },
                { title: "院校代码", dataIndex: "college_code", key: "college_code" },
                { title: "院校", dataIndex: "college_name", key: "college_name" },
                { title: "地区/城市", dataIndex: "college_location", key: "college_location" },
                { 
                    title: "层次", 
                    dataIndex: "college_level", 
                    key: "college_level",
                    render: (levels: any) => Array.isArray(levels) ? levels.join('\n') : levels
                },
                { title: "类型", dataIndex: "college_categories", key: "college_categories" },
                { title: "性质", dataIndex: "nature_type", key: "nature_type" },
                { title: "专业代码", dataIndex: "profession_enroll_code", key: "profession_enroll_code" },
                { title: "专业", dataIndex: "profession_full_name", key: "profession_full_name" },
                { title: "专业评估", dataIndex: "profession_evaluation_level", key: "profession_evaluation_level" },
                { title: "计划", dataIndex: "plan_num", key: "plan_num" },
                { title: "学费", dataIndex: "tuition", key: "tuition" },
                { title: "学制", dataIndex: "duration", key: "duration" },
                { title: "选科", dataIndex: "subject_requirement", key: "subject_requirement" },
            ],
        },
        {
            title: `${year}(历年)`,
            children: [
                { title: "计划人数", dataIndex: "plan_num", key: "plan_num" },
                { title: "录取人数", dataIndex: "enter_num_current", key: "enter_num_current" },
                { title: "最低分", dataIndex: "min_score_current", key: "min_score_current" },
                { title: "最低位次", dataIndex: "min_rank_current", key: "min_rank_current" },
            ],
        },
        {
            title: `${year - 1}(历年)`,
            children: [
                { title: "录取人数", dataIndex: "enter_num_last_1_year", key: "enter_num_last_1_year" },
                { title: "最低分", dataIndex: "min_score_last_1_year", key: "min_score_last_1_year" },
                { title: "最低位次", dataIndex: "min_rank_last_1_year", key: "min_rank_last_1_year" },
            ],
        },
        {
            title: `${year - 2}(历年)`,
            children: [
                { title: "录取人数", dataIndex: "enter_num_last_2_year", key: "enter_num_last_2_year" },
                { title: "最低分", dataIndex: "min_score_last_2_year", key: "min_score_last_2_year" },
                { title: "最低位次", dataIndex: "min_rank_last_2_year", key: "min_rank_last_2_year" },
            ],
        },
    ];

    return columns;
};


const getColumns2 = (year: number) => {
    const columns = [
        {
            title: `${year}(历年)`,
            children: [
                { title: "序号", dataIndex: "index", key: "index", render: (text: any, record: any, index: number) => index + 1 },
                { title: "院校代码", dataIndex: "college_code", key: "college_code" },
                { title: "院校", dataIndex: "college_name", key: "college_name" },
                { title: "地区/城市", dataIndex: "college_location", key: "college_location" },
                { title: "层次", dataIndex: "edu_level", key: "edu_level" },
                { title: "类型", dataIndex: "college_type", key: "college_type" },
                { title: "性质", dataIndex: "nature_type", key: "nature_type" },
                { title: "专业代码", dataIndex: "profession_enroll_code", key: "profession_enroll_code" },
                { title: "专业", dataIndex: "profession_name", key: "profession_name" },
                { title: "录取人数", dataIndex: "enter_num_current", key: "enter_num_current"},
                { title: "最低分", dataIndex: "min_score_current", key: "min_score_current"},
                { title: "最低位次", dataIndex: "min_rank_current", key: "min_rank_current"},
            ],
        },
        {
            title: `${year - 1}(历年)`,
            children: [
                { title: "录取人数", dataIndex: "enter_num_last_1_year", key: "enter_num_last_1_year"},
                { title: "最低分", dataIndex: "min_score_last_1_year", key: "min_score_last_1_year"},
                { title: "最低位次", dataIndex: "min_rank_last_1_year", key: "min_rank_last_1_year"},
            ],
        },
        {
            title: `${year - 2}(历年)`,
            children: [
                { title: "录取人数", dataIndex: "enter_num_last_2_year", key: "enter_num_last_2_year"},
                { title: "最低分", dataIndex: "min_score_last_2_year", key: "min_score_last_2_year"},
                { title: "最低位次", dataIndex: "min_rank_last_2_year", key: "min_rank_last_2_year"},
            ],
        },
    ];

    return columns;
};

export default getColumns;
export { getColumns, getColumns2 };
