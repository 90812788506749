import {
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    PlusOutlined,
} from "@ant-design/icons"; // 导入加号图标
import {
    Button,
    Card,
    Input,
    Menu,
    message,
    Modal,
    Space,
    Tooltip,
    Tree,
} from "antd";
import { EventDataNode } from "rc-tree/lib/interface"; // 使用 rc-tree 作为类型来源

import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchRolesTree } from "../redux/thunks";
import { createTheme, deleteTheme, updateTheme } from "../services/api";
import ExportModal from "./modals/export-modal";
import ThemeFormModal from "./theme-form-modal";
import "./theme-role-tree.css";
export interface TreeNode {
    title: string;
    key: string;
    children?: TreeNode[];
    icon?: React.ReactNode;
    type: "root" | "theme" | "role";
    has_access: boolean;
}

interface ThemeRoleTreeProps {
    onSelectNode: (node: TreeNode) => void;
}

const ThemeRoleTree: React.FC<ThemeRoleTreeProps> = ({ onSelectNode }) => {
    const dispatch = useAppDispatch();
    const treeData = useSelector(
        (state: RootState) => state.roleTree.data as TreeNode[]
    );
    const loading = useSelector((state: RootState) => state.roleTree.loading);
    const [searchValue, setSearchValue] = useState<string>("");
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

    //主题CRUD
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<"create" | "edit">("create");
    const [currentTheme, setCurrentTheme] = useState<{ name: string } | null>(
        null
    );
    const [exportModalVisiable, setExportModalVisiable] = useState(false);

    const [contextMenuVisiable, setContextMenuVisiable] = useState(false);
    const [contextMenuInfo, setContextMenuInfo] = useState<{
        x: number;
        y: number;
        node: TreeNode | null;
    }>({ x: 0, y: 0, node: null });

    useEffect(() => {
        dispatch(fetchRolesTree());
        const handleClick = () => setContextMenuVisiable(false);
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
    }, [dispatch]);

    useEffect(() => {
        if (treeData && treeData.length > 0) {
            const firstLevelKeys = treeData.map((node) => node.key);
            setExpandedKeys(firstLevelKeys);
        }
    }, [treeData]);

    const onExpand = (expandedKeys: React.Key[]) => {
        setExpandedKeys(expandedKeys);
    };

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchValue(value);

        if (value) {
            // 收集所有匹配的节点的 key，包括父节点和子节点
            const expandedKeys = filteredData
                .map((node) => {
                    const childKeys = node.children
                        ? node.children
                              .filter((child) =>
                                  child.title
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                              )
                              .map((child) => child.key)
                        : [];

                    // 如果父节点或其任何子节点匹配搜索条件，则展开父节点
                    if (
                        node.title
                            .toLowerCase()
                            .includes(value.toLowerCase()) ||
                        childKeys.length > 0
                    ) {
                        return [node.key, ...childKeys];
                    }

                    return null;
                })
                .filter((key) => key !== null)
                .flat();

            setExpandedKeys(expandedKeys as React.Key[]);
        } else {
            // 如果搜索框为空，则只展开第一层节点
            const firstLevelKeys = treeData.map((node) => node.key);
            setExpandedKeys(firstLevelKeys);
        }
    };

    const onSelect = (selectedKeys: React.Key[], info: any) => {
        const selectedNode = info.node as TreeNode;
        if (!selectedNode.has_access) return; // 禁用交互，防止选中
        onSelectNode(selectedNode); // 将选中的节点传递给父组件
    };

    const titleRender = (node: TreeNode) => {
        return (
            <span
                style={{
                    color: node.has_access ? "inherit" : "gray", // 如果无访问权限，设为灰色
                    cursor: node.has_access ? "pointer" : "not-allowed", // 没有权限时禁用指针
                }}
            >
                {node.title}
            </span>
        );
    };

    const filteredData = useMemo(() => {
        const filterTree = (nodes: TreeNode[], search: string): TreeNode[] => {
            if (!Array.isArray(nodes)) {
                return [];
            }
            return nodes
                .map((node) => {
                    const children = node.children
                        ? filterTree(node.children, search)
                        : [];
                    if (
                        node.title
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                        children.length
                    ) {
                        return {
                            ...node,
                            children,
                        };
                    }
                    return null;
                })
                .filter((node) => node !== null) as TreeNode[];
        };
        return filterTree(treeData, searchValue);
    }, [treeData, searchValue]);

    const handleAddTheme = () => {
        setModalMode("create");
        setCurrentTheme(null);
        setModalVisible(true);
    };

    const handleExport = () => {
        setExportModalVisiable(true);
    };

    const handleEditTheme = (theme: { name: string }) => {
        setModalMode("edit");
        setCurrentTheme(theme);
        setModalVisible(true);
    };

    const handleDeleteTheme = (node: TreeNode | null) => {
        if (!node) {
            return;
        }

        Modal.confirm({
            title: "确认删除",
            content: `你确定要删除 "${node.title}" 吗？`,
            okText: "确认",
            okType: "danger",
            cancelText: "取消",
            async onOk() {
                const themeId = contextMenuInfo.node?.key;
                if (!themeId) {
                    message.error("未选中主题");
                    return;
                }
                try {
                    const result = await deleteTheme(themeId);
                    if (result.error) {
                        message.error(`删除主题失败: ${result.error}`);
                    } else {
                        message.success("删除主题成功");
                    }
                } catch (error) {
                    message.error(
                        `发生错误: ${
                            error instanceof Error
                                ? error.message
                                : String(error)
                        }`
                    );
                } finally {
                    setModalVisible(false);
                    dispatch(fetchRolesTree());
                }
            },
        });
    };

    const handleThemeFormSubmit = async (values: { name: string }) => {
        if (modalMode === "create") {
            try {
                // 调用创建主题的API
                const result = await createTheme(values);
                if (result.error) {
                    message.error(`创建主题失败: ${result.error}`);
                } else {
                    message.success("创建主题成功");
                    dispatch(fetchRolesTree());
                }
            } catch (error) {
                message.error("未知错误");
            } finally {
                setModalVisible(false);
            }
        } else if (modalMode === "edit") {
            const themeId = contextMenuInfo.node?.key;
            if (!themeId) {
                message.error("未选中主题");
                return;
            }

            try {
                const result = await updateTheme(themeId, values);
                if (result.error) {
                    message.error(`更新主题失败: ${result.error}`);
                } else {
                    message.success("更新主题成功");
                    dispatch(fetchRolesTree());
                }
            } catch (error) {
                console.error("Error updating theme:", error);
                message.error(
                    `发生错误: ${
                        error instanceof Error ? error.message : String(error)
                    }`
                );
            } finally {
                setModalVisible(false);
            }
        }
    };

    const onRightClick = (info: {
        event: React.MouseEvent<Element>;
        node: EventDataNode<TreeNode>;
    }) => {
        const { event, node } = info;
        event.preventDefault();

        if (node?.type === "root" || node?.type === "role") {
            setContextMenuVisiable(false); // 如果类型为 root，隐藏菜单
            return;
        }

        const pageX = event.clientX;
        const pageY = event.clientY;
        setContextMenuInfo({
            x: pageX,
            y: pageY,
            node: node,
        });
        setContextMenuVisiable(true);
    };

    const handleThemeFormCancel = () => {
        setModalVisible(false);
    };

    const menu = () => (
        <Card
            bodyStyle={{ padding: 1 }}
            style={{
                backgroundColor: "#e8e4ec",
                position: "fixed",
                left: `${contextMenuInfo.x}px`,
                top: `${contextMenuInfo.y}px`,
                display: contextMenuVisiable ? "block" : "none",
                zIndex: 1000,
                width: "10vw",
            }}
        >
            <Menu
                style={{
                    border: "none",
                }}
            >
                <Menu.Item
                    key="edit"
                    icon={<EditOutlined />}
                    onClick={() =>
                        handleEditTheme({
                            name: contextMenuInfo.node?.title || "",
                        })
                    }
                    className="custom-menu-item"
                >
                    修改
                </Menu.Item>
                <Menu.Item
                    key="delete"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteTheme(contextMenuInfo.node)}
                    className="custom-menu-item"
                >
                    删除
                </Menu.Item>
            </Menu>
        </Card>
    );

    return (
        <Card
            size="small"
            style={{ height: "90vh", overflow: "hidden" }}
            title={
                <div
                    style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <span className="custom-card-title">主题</span>
                    <Space>
                        <Tooltip title="导出语料">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<ExportOutlined />}
                                onClick={handleExport}
                                size="small"
                            />
                        </Tooltip>
                        <Tooltip title="创建主题">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />} // 使用加号图标
                                onClick={handleAddTheme}
                                size="small"
                            />
                        </Tooltip>
                    </Space>
                </div>
            }
        >
            <Input.Search
                placeholder="Search"
                value={searchValue}
                onChange={onSearch}
                enterButton={false}
                size="small"
            />

            <div style={{ margin: "10px 0" }}></div>
            <ThemeFormModal
                visible={modalVisible}
                mode={modalMode}
                onSubmit={handleThemeFormSubmit}
                onCancel={handleThemeFormCancel}
                initialValues={currentTheme || undefined}
            />
            <Card size="small" style={{ height: "75vh", overflowY: "scroll" }}>
                {!loading && (
                    <Tree
                        showIcon
                        draggable
                        showLine={true}
                        treeData={filteredData}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        onSelect={onSelect} // 添加 onSelect 事件
                        onRightClick={onRightClick}
                        titleRender={titleRender} // 自定义节点标题
                    />
                )}
            </Card>
            {menu()}
            <ExportModal
                visible={exportModalVisiable}
                onClose={() => setExportModalVisiable(false)}
            ></ExportModal>
        </Card>
    );
};

export default ThemeRoleTree;
