import { Select } from 'antd';
import { useState } from 'react';

const { Option } = Select;

interface ProvinceSelectorProps {
  value: string; // 选中的省份
  onChange: (value: string) => void; // 处理选择变化的函数
}

const provinces = [
  "北京", "天津", "上海", "重庆", "河北", "山西", "辽宁", "吉林", "黑龙江", 
  "江苏", "浙江", "安徽", "福建", "江西", "山东", "河南", "湖北", "湖南", 
  "广东", "海南", "四川", "贵州", "云南", "陕西", "甘肃", "青海", "内蒙古", 
  "广西", "西藏", "宁夏", "新疆", "香港", "澳门", "台湾"
];

const ProvinceSelector: React.FC<ProvinceSelectorProps> = ({ value = "北京", onChange }) => {
  return (
    <div>
      <span>省份选择：</span>
      <Select
        placeholder="选择省份"
        size="small"
        style={{ width: 120, marginRight: 8 }}
        onChange={onChange}
        value={value}
      >
        {provinces.map(province => (
          <Option key={province} value={province}>
            {province}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default ProvinceSelector;
