import React from "react";
import { useParams } from "react-router-dom";

import CodeEditor from "../components/code-editor";
import CodeEditorMenuBar from "../components/code-editor-menu-bar";
import CodeEditorTopicIndex from "../components/code-editor-topic-index";
import "./corpus-editor.css";

const CorpusEditor: React.FC = () => {
    const { themeId, roleId, topicId } = useParams();

    if (!themeId || !roleId || !topicId) {
        return (
            <div>
                <p>主题|角色|话题 其中有一个传入的是空值，无法渲染.</p>
            </div>
        );
    }

    return (
        <div style={{ display: "flex", height: "75vh" }}>
            <div style={{ width: "20%" }}>
                <CodeEditorTopicIndex roleId={roleId} themeId={themeId} />
            </div>
            <div style={{ width: "80%" }}>
                <div className="container">
                    <CodeEditorMenuBar
                        themeId={themeId}
                        roleId={roleId}
                        topicId={topicId}
                    ></CodeEditorMenuBar>
                    <div
                        className="editor"
                        style={{
                            overflow: "auto",
                        }}
                    >
                        <CodeEditor
                            themeId={themeId}
                            roleId={roleId}
                            topicId={topicId}
                        ></CodeEditor>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CorpusEditor;
