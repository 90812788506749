import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchTopicsForLabelByRoleId } from '../thunks';


interface FetchTopicsPayload {
    data: any[];
}

interface TopicTableForLabelStateType {
    data: any[];
    loading: boolean;
    error: string | null;
}


const initialState: TopicTableForLabelStateType = {
    data: [],
    loading: false,
    error: null,
};

export const TopicTableForlabelState = createSlice({
    name: 'topicTableForLabel',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTopicsForLabelByRoleId.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTopicsForLabelByRoleId.fulfilled, (state, action: PayloadAction<FetchTopicsPayload>) => {
                state.loading = false;
                console.log(action.payload.data)
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(fetchTopicsForLabelByRoleId.rejected, (state, action) => {
                state.loading = false;
                console.error('Fetch failed:', action.error);
                state.error = action.error.message || 'Something went wrong';
            })
    }
})

export type TopicTableForLabelSliceType = ReturnType<typeof TopicTableForlabelState.reducer>;

export default TopicTableForlabelState.reducer