import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchRoleLabelIssueRreport } from "../thunks";

interface RoleLabelIssueReportState {
    data: any[];
    loading: boolean;
    error: string | null;
}

const initialState: RoleLabelIssueReportState = {
    data: [],
    loading: false,
    error: null,
};

export const RoleLabelIssueReportSlice = createSlice({
    name: "roleLabelIssueReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoleLabelIssueRreport.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                fetchRoleLabelIssueRreport.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loading = false;
                    state.data = action.payload;
                    state.error = null;
                }
            )
            .addCase(fetchRoleLabelIssueRreport.rejected, (state, action) => {
                state.loading = false;
                console.error("Fetch failed:", action.error);
                state.error = action.error.message || "Something went wrong";
            });
    },
});

export type RoleLabelIssueReportSliceType = ReturnType<
    typeof RoleLabelIssueReportSlice.reducer
>;
export default RoleLabelIssueReportSlice.reducer;
