import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { getLabelsDataForSelect } from '../../services/api';

interface LabelSelectorProps {
  value: string[]; // 选中的标签数组
  onChange: (value: string[]) => void; // 处理选择变化的函数
}

interface GroupedOptions {
    label: JSX.Element;
    title: string;
    options: { label: JSX.Element; value: string }[];
  }
  

const LabelSelector: React.FC<LabelSelectorProps> = ({ value, onChange }) => {
  // 定义状态存储标签数据
  const [labels, setLabels] = useState<GroupedOptions[]>([]);

  
  // 加载标签数据
  useEffect(() => {
    const fetchData = async () => {
      const res = await getLabelsDataForSelect();

      const groupedData = res.data.reduce((acc: Record<string, { label: string; value: string }[]>, item: { label_id: string; label_name: string; label_type: string }) => {
        // 如果分组不存在，则创建新的分组
        if (!acc[item.label_type]) {
          acc[item.label_type] = [];
        }
        // 向分组中添加 label 和 value
        acc[item.label_type].push({
          label: item.label_name,
          value: item.label_id,
        });
        return acc;
      }, {});

      
      // 将 groupedData 转换为符合 Select 组件的 options 结构
      const mappedLabels = Object.keys(groupedData).map(labelType => ({
        label: <span>{labelType}</span>, // 分组名
        title: labelType,
        options: groupedData[labelType].map((option:{ label: string; value: string }) => ({
          label: <span>{option.label}</span>, // 显示的标签名
          value: option.value,               // 标签值
        })),
      }));

      setLabels(mappedLabels);
    };

    fetchData();
  }, []);

  return (
    <div>
              <span>标签选择：</span>
      <Select
        mode="multiple"
        placeholder="请选择标签"
        onChange={onChange} 
        style={{ width: '300px' }}
        options={labels}
        value={value}
      >
      </Select>
    </div>
  );
};

export default LabelSelector;
