// highlightSlice.js
import { createSlice } from '@reduxjs/toolkit';

// 定义初始状态
const initialState = {
    isHighlightEnabled: false,
};

// 创建 slice
const highlightSlice = createSlice({
    name: 'highlight',
    initialState,
    reducers: {
        toggleHighlight: (state) => {
            state.isHighlightEnabled = !state.isHighlightEnabled;
        },
        enableHighlight: (state) => {
            state.isHighlightEnabled = true;
        },
        disableHighlight: (state) => {
            state.isHighlightEnabled = false;
        }
    }
});

// 导出 action 和 reducer
export const { toggleHighlight, enableHighlight, disableHighlight } = highlightSlice.actions;
export default highlightSlice.reducer;
