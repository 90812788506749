import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchTopicsForExport } from '../thunks';


interface FetchTopicsPayload {
    data: any[];
}

interface TopicTableForExportStateType {
    data: any[];
    loading: boolean;
    error: string | null;
}


const initialState: TopicTableForExportStateType = {
    data: [],
    loading: false,
    error: null,
};

export const TopicTableForExportState = createSlice({
    name: 'topicTableForLabel',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTopicsForExport.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTopicsForExport.fulfilled, (state, action: PayloadAction<FetchTopicsPayload>) => {
                state.loading = false;
                console.log(action.payload.data)
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(fetchTopicsForExport.rejected, (state, action) => {
                state.loading = false;
                console.error('Fetch failed:', action.error);
                state.error = action.error.message || 'Something went wrong';
            })
    }
})

export type TopicTableForExportSliceType = ReturnType<typeof TopicTableForExportState.reducer>;

export default TopicTableForExportState.reducer