//write a page for export

import { Button, Card, message, Spin, Table, TableColumnType } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchAllRoles } from "../redux/thunks";
import { exportRoles } from "../services/api";

const ExportRolePage = () => {
    const dispatch = useAppDispatch();
    const [themeNames, setThemeNames] = useState<string[]>([]);
    const [roleNames, setRoleNames] = useState<string[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]); // 用于存储选中的行的key

    const { data: data, loading } = useSelector(
        (state: RootState) => state.roleTable
    );

    useEffect(() => {
        if (data) {
            // Extract unique theme names and role names for filtering
            const uniqueThemeNames = [
                ...new Set(data.map((item) => item.theme_name).filter(Boolean)),
            ];
            const uniqueRoleNames = [
                ...new Set(data.map((item) => item.role_name).filter(Boolean)),
            ];

            setThemeNames(uniqueThemeNames);
            setRoleNames(uniqueRoleNames);
        }
    }, [data]);

    const columns: TableColumnType[] = [
        {
            title: "序号",
            dataIndex: "index",
            key: "index",
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: "主题名",
            dataIndex: "theme_name",
            key: "theme_name",
            filters: themeNames.map((name) => ({ text: name, value: name })),
            onFilter: (value: any, record: any) => record.theme_name === value,
        },
        {
            title: "角色编号",
            dataIndex: "role_id",
            key: "role_id",
        },
        {
            title: "角色名",
            dataIndex: "role_name",
            key: "role_name",
            filters: roleNames.map((name) => ({ text: name, value: name })),
            onFilter: (value: any, record: any) => record.role_name === value,
        },
        {
            title: "英文名",
            dataIndex: "alias",
            key: "alias",
        },
        {
            title: "前置对话",
            dataIndex: "extra_info.pre_dialogue",
            key: "pre_dialogue",
            render: (text, record) => record.extra_info?.pre_dialogue,
        },
        {
            title: "开场白",
            dataIndex: "extra_info.opening_statement",
            key: "opening_statement",
            render: (text, record) => record.extra_info?.opening_statement,
        },
        {
            title: "system_prompt",
            dataIndex: "extra_info.system_prompt",
            key: "system_prompt",
            render: (text, record) => record.extra_info?.system_prompt,
        },
        {
            title: "gender",
            dataIndex: "extra_info.gender",
            key: "gender",
            render: (text, record) => record.extra_info?.gender,
        },
        {
            title: "mbti",
            dataIndex: "extra_info.mbti",
            key: "mbti",
            render: (text, record) => record.extra_info?.mbti,
        },
        {
            title: "单边prompt",
            dataIndex: "extra_info.single_side_prompt",
            key: "single_side_prompt",
            render: (text, record) => record.extra_info?.single_side_prompt,
        },
        {
            title: "创建时间",
            dataIndex: "created_time",
            key: "created_time",
        },
        {
            title: "修改时间",
            dataIndex: "updated_time",
            key: "updated_time",
        },
    ];

    useEffect(() => {
        dispatch(fetchAllRoles());
    }, [dispatch]);

    const onSelectChange = (newSelectedRowKeys: any[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleExportClick = async () => {
        try {
            const selectedRoles = data.filter((item: any) =>
                selectedRowKeys.includes(item.role_id)
            );
            const selectedRoleIds = selectedRoles.map(
                (item: any) => item.role_id
            );
            console.log("导出选中的角色:", selectedRoleIds);
            const res = await exportRoles({ role_ids: selectedRoleIds });
            if (res.error) {
                throw new Error(res.error);
            }
            // 在此处添加导出逻辑，比如生成文件或发送到服务器
        } catch (error) {
            console.error("导出角色时发生错误:", error);
            message.error(`导出角色时发生错误: ${error}`);
        }
    };
    return (
        <div>
            <Card>
                <Button
                    onClick={handleExportClick}
                    disabled={selectedRowKeys.length === 0}
                >
                    导出选中角色
                </Button>
            </Card>
            <Card>
                <div>
                    {loading ? (
                        <Spin />
                    ) : (
                        <Table
                            columns={columns}
                            dataSource={data}
                            size="small"
                            rowKey="role_id"
                            rowSelection={rowSelection}
                            pagination={{
                                pageSizeOptions: [
                                    "10",
                                    "20",
                                    "50",
                                    "100",
                                    "1000",
                                ], // 每页条数选项
                            }}
                        />
                    )}
                </div>
            </Card>
        </div>
    );
};

export default ExportRolePage;
