import type { TableColumnsType, TableProps } from "antd";
import { Button, Modal, Spin, Table, Tag, Typography } from "antd";
import { ColumnFilterItem } from "antd/es/table/interface";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CorpusEditor from "../pages/corpus-editor";
import { AppDispatch, RootState } from "../redux/store";
import { fetchTopicsByRoleId } from "../redux/thunks";
import {
    fetchTopicTableFilterLabel,
    fetchTopicTableFilterProcessStatus,
    fetchTopicTableFilterTopicName,
} from "../services/api";
import "./topics-of-role-table.css";
const { Title } = Typography;

const TopicsOfRoleTable: React.FC<TopicsOfRoleTableProps> = ({
    theme_id,
    role_id,
    onEdit,
    onDelete,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const data = useSelector(
        (state: RootState) => state.topicTable.data as RowType[]
    );
    const loading = useSelector((state: RootState) => state.topicTable.loading);
    const currentPage = useSelector(
        (state: RootState) => state.topicTable.currentPage
    );
    const pageSize = useSelector(
        (state: RootState) => state.topicTable.pageSize
    );
    const total = useSelector((state: RootState) => state.topicTable.total);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate(); // 使用 useNavigate 钩子来跳转

    const [topicNameFilter, setTopicNameFilter] = useState<ColumnFilterItem[]>(
        []
    );

    const [lastFilters, setLastFilters] = useState({
        label: [] as string[],
        processing_status: [] as string[],
        topic_name: [] as string[],
    });

    const [processStatusFilter, setProcessStatusFilter] = useState<
        ColumnFilterItem[]
    >([]);
    const [labelFilter, setLabelFilter] = useState<ColumnFilterItem[]>([]);

    useEffect(() => {
        // 定义一个异步函数来执行数据获取
        const fetchFilters = async () => {
            try {
                // 并行获取所有的筛选数据
                const [
                    topicNameResponse,
                    labelResponse,
                    processStatusResponse,
                ] = await Promise.all([
                    fetchTopicTableFilterTopicName(theme_id, role_id),
                    fetchTopicTableFilterLabel(theme_id, role_id),
                    fetchTopicTableFilterProcessStatus(theme_id, role_id),
                ]);

                setTopicNameFilter(topicNameResponse.data);
                setLabelFilter(labelResponse.data);
                setProcessStatusFilter(processStatusResponse.data);
            } catch (error) {
                console.error("Failed to fetch filter data", error);
            }
        };

        fetchFilters();
    }, [theme_id, role_id]);
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        dispatch(
            fetchTopicsByRoleId({
                themeId: theme_id,
                roleId: role_id,
                page: currentPage,
                pageSize: pageSize,
            })
        );
    }, [theme_id, role_id]);

    const columns: TableColumnsType<RowType> = [
        {
            title: "序号",
            dataIndex: "index",
            key: "index",
            render: (text, record, index) => index + 1,
            onCell: () => {
                return {
                    style: {
                        backgroundColor: "#f7f8fa",
                    },
                };
            },
            width: "5%",
            className: "embossed-column",
        },
        {
            title: "话题编号",
            dataIndex: "topic_id",
            key: "topic_id",
            width: "10%",
        },
        {
            title: "话题名称",
            dataIndex: "topic_name",
            key: "topic_name",
            width: "20%",
            filters: topicNameFilter,
            onFilter: (value, record) => {
                if (Array.isArray(value)) {
                    return value.some((val) => record.topic_name.includes(val));
                }
                return record.topic_name.includes(value as string);
            },
        },
        {
            title: "话题提示词",
            dataIndex: "prompt",
            key: "prompt",
            width: "15%",
        },
        {
            title: "生产标签",
            dataIndex: "label",
            key: "label",
            width: "15%",
            className: "no-wrap",
            filters: labelFilter, // 动态设置筛选选项
            onFilter: (value, record) => {
                if (Array.isArray(value)) {
                    return value.some((val) => record.label.includes(val));
                }
                return record.label.includes(value as string);
            },
            filterMultiple: true, // 允许多选过滤
        },
        {
            title: "话题状态",
            dataIndex: "processing_status",
            key: "processing_status",
            width: "15%",
            className: "no-wrap",
            filters: processStatusFilter, // 动态设置筛选选项
            onFilter: (value, record) => {
                if (Array.isArray(value)) {
                    return value.some((val) => record.processing_status == val);
                }
                return record.processing_status == value;
            },
            render: (status: StatusKey) => {
                const { tag, color } = statusEnum[status] || {};
                return tag ? <Tag color={color}>{tag}</Tag> : null;
            },
        },
        {
            title: "功能选项",
            key: "action",
            render: (_, record) => {
                let actionButton;
                const handleEdit = (topicId: string) => {
                    if (!theme_id || !role_id) {
                        console.error("theme_id 或 role_id 为空，无法执行跳转");
                        return;
                    }
                    // 跳转到编辑器页面，带上 topic_id
                    navigate(
                        `/editor/themes/${theme_id}/roles/${role_id}/topics/${topicId}`
                    );
                };
                switch (record.status) {
                    case -1:
                        actionButton = <Button type="link">生成语料</Button>;
                        break;
                    case 0:
                    case 1:
                    case 2:
                        actionButton = (
                            <Button
                                type="link"
                                onClick={() => handleEdit(record.id)}
                            >
                                开始润色
                            </Button>
                        );
                        break;
                    default:
                        actionButton = <Button type="link">未知操作</Button>;
                        break;
                }

                return (
                    <span>
                        <Button type="link" onClick={() => onEdit(record)}>
                            编辑
                        </Button>
                        <Button type="link" onClick={() => onDelete(record)}>
                            删除
                        </Button>
                        {actionButton}
                    </span>
                );
            },
            width: "25%",
            className: "no-wrap",
        },
    ];

    const handleTableChange: TableProps<RowType>["onChange"] = (
        pagination,
        filters
    ) => {
        // 获取当前的筛选条件
        const labelFilter = (filters.label as string[]) || [];
        const processingStatusFilter =
            (filters.processing_status as string[]) || [];
        const topicNameFilter = (filters.topic_name as string[]) || [];

        // 检测筛选器是否真正发生了变化
        const isFilterChanged =
            JSON.stringify(labelFilter) !== JSON.stringify(lastFilters.label) ||
            JSON.stringify(processingStatusFilter) !==
                JSON.stringify(lastFilters.processing_status) ||
            JSON.stringify(topicNameFilter) !==
                JSON.stringify(lastFilters.topic_name);

        // 如果筛选条件发生变化，更新 lastFilters，并重置页码为 1
        const newPage = isFilterChanged ? 1 : pagination.current || 1;

        // 如果筛选条件发生变化，更新 lastFilters 状态，以便下次比较
        if (isFilterChanged) {
            setLastFilters({
                label: labelFilter,
                processing_status: processingStatusFilter,
                topic_name: topicNameFilter,
            });
        }

        // 更新 Redux 中的分页信息并重新获取数据
        dispatch(
            fetchTopicsByRoleId({
                themeId: theme_id,
                roleId: role_id,
                page: newPage, // 如果筛选条件改变，则重置为第一页，否则保持当前页
                pageSize: pagination.pageSize || 10,
                label: labelFilter.join(","),
                processingStatus: processingStatusFilter.join(","),
                names: topicNameFilter.join(","),
            })
        );
    };

    return (
        <div style={{ padding: "16px" }}>
            {
                <Spin spinning={loading}>
                    <div>
                        <Table
                            columns={columns}
                            dataSource={data.map((item) => ({
                                ...item,
                                key: item.id,
                            }))}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: total,
                                showSizeChanger: true,
                                showTotal: (total) => `Total ${total} items`,
                            }}
                            onChange={handleTableChange}
                            size="small"
                            rowKey="id"
                        />
                        <Modal
                            title={<Title level={4}>Corpus Editor</Title>}
                            open={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            width={800} // 你可以调整模态框的宽度
                        >
                            <CorpusEditor />
                        </Modal>
                    </div>
                </Spin>
            }
        </div>
    );
};

export const statusEnum = {
    "0": {
        tag: "待生成语料",
        color: "red",
    },
    "1": {
        tag: "待润色",
        color: "purple",
    },
    "2": {
        tag: "草稿",
        color: "gold",
    },
    "3": {
        tag: "润色完成",
        color: "cyan",
    },
} as const;

export type StatusKey = keyof typeof statusEnum;

export interface RowType {
    key: string;
    index: number;
    id: string;
    name: string;
    topic_id: string;
    topic_name: string;
    theme_id: string;
    role_id: string;
    prompt: string;
    label: string;
    status: number;
    processing_status: number;
}

interface TopicsOfRoleTableProps {
    theme_id: string;
    role_id: string;
    onEdit: (record: RowType) => void;
    onDelete: (record: RowType) => void;
}

export default TopicsOfRoleTable;
