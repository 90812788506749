import React, { useState } from "react";
import { Radio } from "antd";

interface TemperatureRadioProps {
    onChange: (value: number) => void;
}

const TemperatureRadio: React.FC<TemperatureRadioProps> = ({ onChange }) => {
    const [value, setValue] = useState(0.7);

    const handleChange = (e: any) => {
        const newValue = e.target.value;
        setValue(newValue);
        onChange(newValue);
    };

    return (
        <Radio.Group onChange={handleChange} value={value}>
            <Radio value={0.1}>0.1</Radio>
            <Radio value={0.3}>0.3</Radio>
            <Radio value={0.5}>0.5</Radio>
            <Radio value={0.7}>0.7</Radio>
            <Radio value={1.0}>1.0</Radio>
        </Radio.Group>
    );
};

export default TemperatureRadio;
