import { createSlice } from '@reduxjs/toolkit';
import { fetchFormalKeywordById } from '../thunks';


interface FormalKeywordsState {
    data: string[] | null;
    loading: boolean;
    error: string | null;
}

const initialState: FormalKeywordsState = {
    data: null,
    loading: false,
    error: null,
};

export const FormalKeywordsSlice = createSlice({
    name: 'formalKeywords',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFormalKeywordById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFormalKeywordById.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.map((item: { name: string }) => item.name);
            })
            .addCase(fetchFormalKeywordById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
})

export type FormalKeywordsSliceType = ReturnType<typeof FormalKeywordsSlice.reducer>;
export default FormalKeywordsSlice.reducer