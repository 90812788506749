import Chat, { Bubble, MessageProps, useMessages } from "@chatui/core";
import "@chatui/core/dist/index.css";
import { Button, message } from "antd";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { streamingChat } from "../services/api";
import "./chatbot.css";

interface ChatBotProps {
    source: string;
    temperature: number;
    systemPrompt: string;
}

const ChatBot: React.FC<ChatBotProps> = ({
    source,
    temperature,
    systemPrompt,
}) => {
    const { messages, appendMsg, updateMsg, setTyping } = useMessages([]);
    const [chatHistory, setChatHistory] = useState<string[]>([]);

    const handleStreamResponse = async (
        userMessage: string,
        chatHistory: string[]
    ) => {
        const updatedHistory = [...chatHistory, userMessage];
        setChatHistory(updatedHistory);

        try {
            const reader = await streamingChat({
                chat_history: updatedHistory,
                source: source,
                sys_prompt: systemPrompt,
                temperature: temperature ?? 0.7,
            });

            if (!reader) {
                throw new Error("No response body returned from server");
            }

            const textDecoder = new TextDecoder();
            let partialText = "";

            const streamReader = reader.getReader();

            const newMessageId = uuidv4();
            appendMsg({
                _id: newMessageId,
                type: "text",
                content: { text: "" },
                position: "left",
            });

            while (true) {
                const { done, value } = await streamReader.read();
                if (done) break;
                const chunk = textDecoder.decode(value, { stream: true });
                partialText += chunk;

                if (newMessageId) {
                    updateMsg(newMessageId, {
                        type: "text",
                        content: { text: partialText },
                        position: "left",
                    });
                }
            }
        } catch (error) {
            setTyping(false);
            console.error("Error fetching GPT response:", error);
            message.error("Error fetching GPT response");
        }
    };

    function handleSend(type: string, val: string) {
        if (type === "text" && val.trim()) {
            appendMsg({
                _id: uuidv4(),
                type: "text",
                content: { text: val },
                position: "right",
            });

            setTyping(true);

            handleStreamResponse(val, chatHistory);
        }
    }

    function handleExport() {
        const chatContent = messages
            .map(
                (msg) =>
                    `${msg.position === "right" ? "User" : "Bot"}: ${
                        msg.content.text
                    }`
            )
            .join("\n");
        const blob = new Blob([chatContent], {
            type: "text/plain;charset=utf-8",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "chat_history.txt";
        a.click();
        URL.revokeObjectURL(url);
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            const input = event.currentTarget.value;
            if (input.trim()) {
                handleSend("text", input);
                event.currentTarget.value = "";
            }
        }
    }

    // 渲染消息内容
    function renderMessageContent(msg: MessageProps) {
        const { content } = msg;
        return <Bubble content={content?.text || ""} />;
    }

    return (
        <div className="chat-container">
            <Chat
                messages={messages}
                renderMessageContent={renderMessageContent}
                onSend={handleSend}
            />
            <Button
                type="primary"
                onClick={handleExport}
                style={{
                    float: "right",
                    fontSize: "12px",
                    width: "15%",
                }}
            >
                导出全部对话
            </Button>
        </div>
    );
};

export default ChatBot;
