import type { TableColumnsType, TableProps } from "antd";
import { Button, message, Progress, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../redux/store";
import { fetchRolesSummaryByThemeId } from "../redux/thunks";
import { fetchRoleAccess } from "../services/api";
import "./roles-of-theme-table.css";

interface RoleRowType {
    theme_id: string;
    role_id: string;
    role_name: string;
    topic_count: string;
    process: string;
}

interface RolesOfThemeTableProps {
    theme_id: string;
    onEdit: (record: RoleRowType) => void;
    onDelete: (record: RoleRowType) => void;
}

const RolesOfThemeTable: React.FC<RolesOfThemeTableProps> = ({
    theme_id,
    onEdit,
    onDelete,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const data = useSelector(
        (state: RootState) => state.roleSummaryTable.data as RoleRowType[]
    );
    const loading = useSelector(
        (state: RootState) => state.roleSummaryTable.loading
    );
    const [pagination, setPagination] = useState<{
        current: number;
        pageSize: number;
    }>({
        current: 1, // 当前页码
        pageSize: 10, // 每页显示多少条数据
    });
    useEffect(() => {
        if (theme_id) {
            dispatch(fetchRolesSummaryByThemeId(theme_id));
        }
    }, [dispatch, theme_id]);

    const onChange: TableProps<RoleRowType>["onChange"] = (
        pagination,
        filters,
        sorter,
        extra
    ) => {
        setPagination({
            current: pagination.current || 1, // 设置默认值为 1
            pageSize: pagination.pageSize || 10, // 设置默认值为 10
        });
    };

    const columns: TableColumnsType<RoleRowType> = [
        {
            title: "序号",
            dataIndex: "index",
            key: "index",
            render: (text, record, index) => {
                // 获取当前页码和每页大小
                const { current = 1, pageSize = 10 } = pagination; // 使用分页对象获取当前页码和页面大小
                return (current - 1) * pageSize + index + 1; // 调整序号
            },
            onCell: () => {
                return {
                    style: {
                        backgroundColor: "#f7f8fa",
                    },
                };
            },
            width: "5%",
            className: "embossed-column",
        },
        {
            title: "角色id",
            dataIndex: "role_id",
            key: "role_id",
            width: "10%",
        },
        {
            title: "角色",
            dataIndex: "role_name",
            key: "name",
            width: "20%",
        },
        {
            title: "进度",
            dataIndex: "progress",
            key: "progress",
            width: "15%",
            render: (text) => {
                const value = parseFloat(text);
                return (
                    <Progress
                        percent={
                            isNaN(value) ? 0 : Number(value.toFixed(2)) * 100
                        }
                        size="small"
                    />
                );
            },
        },
        {
            title: "话题数",
            dataIndex: "topic_count",
            key: "topic_count",
            // render: (tags: string[]) => (
            //     <>
            //         {tags.map(tag => (
            //             <span key={tag} style={{ marginRight: 8 }}>
            //                 {tag}
            //             </span>
            //         ))}
            //     </>
            // ),
            width: "15%",
        },
        {
            title: "功能选项",
            key: "action",
            render: (_, record) => (
                <span>
                    <Button type="link" onClick={() => onEdit(record)}>
                        编辑
                    </Button>
                    <Button type="link" onClick={() => onDelete(record)}>
                        删除
                    </Button>
                    <Button
                        type="link"
                        onClick={() =>
                            handleTopicListClick(
                                record.theme_id,
                                record.role_id
                            )
                        }
                    >
                        话题列表
                    </Button>
                </span>
            ),
            width: "25%",
        },
    ];

    const handleTopicListClick = async (theme_id: string, role_id: string) => {
        const res = await fetchRoleAccess(theme_id, role_id);
        if (res.error) {
            return message.error(res.error);
        }
        navigate(`/resource/topic_list_of_role/${theme_id}/${role_id}`);
    };

    return (
        <div style={{ padding: "16px" }}>
            {
                <Spin spinning={loading}>
                    <div>
                        <Table
                            columns={columns}
                            dataSource={data}
                            rowKey="role_id" // Specify the key here
                            onChange={onChange}
                            size="small"
                            pagination={pagination}
                        />
                    </div>
                </Spin>
            }
        </div>
    );
};

export default RolesOfThemeTable;
