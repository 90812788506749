import React from "react";
import { useDispatch } from "react-redux";

interface CheckWindowProps extends React.HTMLAttributes<HTMLDivElement> {
    drawerContent: string[];
}

const CheckWindow: React.FC<CheckWindowProps> = ({
    drawerContent,
    ...props
}) => {
    const dispatch = useDispatch();

    return (
        <div
            {...props}
            style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#f9f9f9",
                borderLeft: "1px solid #ccc",
                ...props.style,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                }}
            >
                <span style={{ fontWeight: "bold" }}>检查窗口</span>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                }}
            >
                {/* <button
                    onClick={() => dispatch(setDrawerHide())}
                    style={{
                        backgroundColor: "#ff4d4f",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        padding: "5px 10px",
                        cursor: "pointer",
                    }}
                >
                    关闭
                </button> */}
            </div>
            <div
                style={{
                    maxHeight: "calc(75vh - 50px)",
                    overflowY: "auto",
                }}
            >
                {drawerContent.map((content: string, index: number) => (
                    <div
                        key={index}
                        style={{
                            padding: "10px",
                            borderBottom: "1px solid #ccc",
                        }}
                    >
                        {content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckWindow;
