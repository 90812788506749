import { CheckOutlined, DownOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    cleanDrawerContent,
    hideAIEditor,
    hideHistoryEditor,
    setDrawerContent,
    setDrawerShow,
    setEditorContent,
    showAIEditor,
    showHistoryEditor,
} from "../redux/reducers/editor-content-reducer";
import {
    disableHighlight,
    enableHighlight,
} from "../redux/reducers/highlight-reducer";
import { RootState, useAppDispatch } from "../redux/store";
import { fetchTopicInfoByTopicId, fetchTopicsByRoleId } from "../redux/thunks";
import {
    checkTopicHumanEditingContent,
    publishTopicHumanEditingContent,
    saveTopicHumanEditingContent,
} from "../services/api";

interface CodeEditorMenuBarProps {
    themeId: string;
    roleId: string;
    topicId: string;
}

const CodeEditorMenuBar: React.FC<CodeEditorMenuBarProps> = ({
    themeId,
    roleId,
    topicId,
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    //TODO 注意性能优化

    const handleBackClick = (themeId: string, roleId: string) => {
        navigate(`/resource/topic_list_of_role/${themeId}/${roleId}`);
    };

    const { editorContent, isAIEditorShow, isHistoryEditorShow } = useSelector(
        (state: RootState) => state.editorContent
    );
    const { data, loading, error } = useSelector(
        (state: RootState) => state.topicInfo
    );

    const { pageSize, currentPage } = useSelector(
        (state: RootState) => state.topicTable
    );

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isPublishEnabled, setIsPublishEnabled] = useState(false);
    const [isCheckSelected, setIsCheckSelected] = useState(false);
    const [isChinesePunctuation, setIsChinesePunctuation] = useState(true);
    const [topicName, setTopicName] = useState("");

    useEffect(() => {
        if (data && data.name) {
            setTopicName(data.name);
        }
    }, [data]);

    const onSave = async () => {
        try {
            const res = await saveTopicHumanEditingContent(
                themeId,
                roleId,
                topicId,
                {
                    human_editing_content: editorContent,
                }
            );
            if (res.error) {
                message.error(`保存失败: ${res.error}`);
                return false;
            }
            dispatch(fetchTopicInfoByTopicId({ themeId, roleId, topicId }));
            message.success("保存成功");
            return true;
        } catch (error: any) {
            message.error(`保存失败: ${error.message}`);
            return false;
        }
    };
    const handleButtonVisiable = () => {
        const changed = editorContent !== data?.human_editing_content;
        setIsSaveEnabled(changed);
        setIsPublishEnabled(changed);
    };
    useEffect(() => {
        handleButtonVisiable();
    }, [editorContent, data]);

    const showErrorDrawer = (problems: string) => {
        const problemList = problems.split("\n");
        dispatch(setDrawerContent(problemList));
        dispatch(setDrawerShow());
    };

    const onCheck = async () => {
        const res = await checkTopicHumanEditingContent(
            themeId,
            roleId,
            topicId,
            { human_editing_content: editorContent } // 你需要将实际的内容传递到这里
        );
        if ("data" in res && res.data) {
            if (res.data.is_valid) {
                message.success("检查通过");
                dispatch(cleanDrawerContent());
                return true;
            } else {
                showErrorDrawer(res.data.problems);
                return false;
            }
        } else if ("error" in res && res.error) {
            message.error(`检查过程中发生错误：${res.error}`);
            return false;
        }
        return false;
    };

    const onPublish = async () => {
        try {
            if (isSaveEnabled) {
                message.error("请先保存");
                return;
            }

            const passCheck = await onCheck();
            if (!passCheck) {
                message.error("校验失败");
                return;
            }
            const res = await publishTopicHumanEditingContent(
                themeId,
                roleId,
                topicId,
                {
                    human_editing_content: editorContent,
                }
            ); // 调用发布 API
            if (res.error) {
                message.error(`提交失败: ${res.error}`);
                return;
            }
            message.success("提交成功");
            dispatch(
                fetchTopicsByRoleId({
                    themeId,
                    roleId,
                    page: currentPage,
                    pageSize: pageSize,
                })
            );
        } catch (error) {
            message.error("提交失败");
        }
    };

    // 学究用词检查点击处理函数
    const handleCheckMenuClick = ({ key }: { key: string }) => {
        if (key === "check6") {
            if (isCheckSelected) {
                dispatch(disableHighlight()); // 禁用高亮
            } else {
                dispatch(enableHighlight()); // 启用高亮
            }
            setIsCheckSelected(!isCheckSelected); // 切换选中状态
        }
    };
    const handleAIShowToggle = (isAIEditorShow: boolean) => {
        if (isAIEditorShow) {
            dispatch(hideAIEditor());
        } else {
            dispatch(showAIEditor());
        }
    };
    const handleHistoryShowToggle = (isHistoryEditorShow: boolean) => {
        if (isHistoryEditorShow) {
            dispatch(hideHistoryEditor());
        } else {
            dispatch(showHistoryEditor());
        }
    };
    const handlePunctuationToggle = () => {
        const cnCharacter = "：（）？！。，“”‘’《》【】、；—～·";
        const enCharacter = ":()?!.,\"\"''<>[]\\;-~·";

        const n = isChinesePunctuation ? 0 : 1;

        let newContent = editorContent;

        if (n % 2 === 0) {
            // 从英文转为中文
            newContent = newContent.replace(/\.\.\./g, "…"); // 特殊处理省略号
            newContent = newContent
                .split("")
                .map((char) => {
                    const idx = enCharacter.indexOf(char);
                    return idx >= 0 ? cnCharacter[idx] : char;
                })
                .join("");
        } else {
            // 从中文转为英文
            newContent = newContent.replace(/…/g, "...");
            newContent = newContent
                .split("")
                .map((char) => {
                    const idx = cnCharacter.indexOf(char);
                    return idx >= 0 ? enCharacter[idx] : char;
                })
                .join("");
        }

        dispatch(setEditorContent(newContent));
        message.success(
            `标点符号已转换为${isChinesePunctuation ? "中文" : "英文"}`
        );
        setIsChinesePunctuation(!isChinesePunctuation); // 切换标点符号的模式
    };

    // 使用 items 数组创建检查项菜单
    const checkMenuItems = [
        {
            key: "check6",
            label: <>{isCheckSelected && <CheckOutlined />} 学究用词检查</>,
        },
    ];

    const checkMenu = (
        <Menu items={checkMenuItems} onClick={handleCheckMenuClick} /> // 使用 items 和 onClick 处理
    );

    // 使用 items 数组创建常用项菜单
    const utilMenuItems = [
        {
            key: "util1",
            label: "标点符号转换",
            onClick: handlePunctuationToggle,
        },
    ];

    const utilMenu = <Menu items={utilMenuItems} />;

    return (
        <>
            <div
                className="titleBar"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "3vh", // Ensure this matches the height of the menu-bar
                    borderBottom: "1px solid #ddd",
                    marginTop: "0px",
                }}
            >
                {topicName}
            </div>
            <div
                className="menu-bar"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(15, auto)",
                    gap: "1px",
                    padding: "2px",
                    borderBottom: "1px solid #ddd",
                }}
            >
                <Button
                    size="small"
                    type="text"
                    onClick={onSave}
                    // disabled={!isSaveEnabled}
                >
                    保存
                </Button>

                <Button size="small" type="text" onClick={onCheck}>
                    检查
                </Button>

                <Button
                    size="small"
                    type="text"
                    onClick={onPublish}
                    // disabled={!isPublishEnabled}
                >
                    提交
                </Button>

                <Dropdown overlay={checkMenu} trigger={["click"]}>
                    <Button size="small" type="text">
                        标记 <DownOutlined />
                    </Button>
                </Dropdown>

                <Dropdown overlay={utilMenu} trigger={["click"]}>
                    <Button size="small" type="text">
                        常用 <DownOutlined />
                    </Button>
                </Dropdown>
                <div style={{ gridColumn: "span 9" }}></div>
                <Button
                    size="small"
                    type="text"
                    onClick={() => handleBackClick(themeId, roleId)}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%", // 设置按钮宽度，使文字靠右
                    }}
                >
                    <span style={{ marginLeft: "auto" }}>
                        <LeftOutlined />
                        返回角色页
                    </span>
                </Button>
            </div>
            <div
                className="toolbar"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(15, auto)",
                    gap: "1px",
                    padding: "2px",
                    borderBottom: "1px solid #ddd",
                    marginTop: "5px",
                }}
            >
                <Button
                    size="small"
                    type="link"
                    onClick={handlePunctuationToggle}
                >
                    {isChinesePunctuation ? <div>英</div> : <div>中</div>}
                </Button>
                <Button
                    size="small"
                    type="link"
                    onClick={() => handleAIShowToggle(isAIEditorShow)}
                >
                    <div>AI</div>
                </Button>
                <Button
                    size="small"
                    type="link"
                    onClick={() => handleHistoryShowToggle(isHistoryEditorShow)}
                >
                    <div>Old</div>
                </Button>

                {/* Add more tool buttons here */}
            </div>
        </>
    );
};

export default CodeEditorMenuBar;
