import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import editorContentReducer from "./reducers/editor-content-reducer";
import formalKeywordsReducer from "./reducers/formal-keywords-reducer";
import generateTaskReducer from "./reducers/generate-task-reducer";
import highlightReducer from "./reducers/highlight-reducer";
import labelsReducer from "./reducers/labels-reducer";
import RoleLabelIssueReportReducer from "./reducers/role-label-issue-report-reducer";
import roleSummaryTableReducer from "./reducers/role-summary-table-reducer";
import roleTableReducer from "./reducers/role-table-reducer";
import RoleTreeReducer from "./reducers/role-tree-reducer";
import topicInfoReducer from "./reducers/topic-info-reducer";
import topicTableForExportReducer from "./reducers/topic-table-for-export-reducer";
import topicTableForLabelReducer from "./reducers/topic-table-for-label-reducer";
import topicTableReducer from "./reducers/topic-table-reducer";
import usersReducer from "./reducers/users-reducer";
import EduReportSliceReducer from "./reducers/edu-report-reducer";
import EduReport2SliceReducer from "./reducers/edu-report2-reducer";

// 创建并导出 store
export const store = configureStore({
    reducer: {
        roleTree: RoleTreeReducer,
        roleSummaryTable: roleSummaryTableReducer,
        roleTable: roleTableReducer,
        topicTable: topicTableReducer,
        topicInfo: topicInfoReducer,
        formalKeywords: formalKeywordsReducer,
        highlightTaggle: highlightReducer,
        editorContent: editorContentReducer,
        generateTaskState: generateTaskReducer,
        labelTable: labelsReducer,
        topicTableForLabel: topicTableForLabelReducer,
        topicTableForExport: topicTableForExportReducer,
        userTable: usersReducer,
        RoleLabelIssueReportSlice: RoleLabelIssueReportReducer,
        EduReportSlice: EduReportSliceReducer,
        EduReport2Slice: EduReport2SliceReducer,

    },
});

// 导出 RootState 类型
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
